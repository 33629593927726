import { Modal } from "bootstrap";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import SellerHeader from "../../Components/SellerHeader";
import SellerSidebar from "../../Components/SellerSidebar";
import {
  reviewimg,
  reviewimg1,
  reviewimg2,
  reviewimg3,
} from "../../Constant/Index";

function Sellerreview() {
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  return (
    <>
      <SellerHeader />
      {/* Seller profile starts here */}
      <section className="seller-dashboard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 ps-0">
              <SellerSidebar />
            </div>
            <div className="col-lg-8">
              <div className="edit-profile-card seller-reviews-tabs">
                <div className="edit-heading-wrapper">
                  <h2>Reviews & Feedback</h2>
                </div>

                <div className="reviews-all-stuff">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="edit-review-all">
                        <div className="review-flex">
                          <div className="review-img seller-review-img">
                            <figure>
                              <img
                                className="img-fluid"
                                alt="review-img-all"
                                src={reviewimg}
                              />
                            </figure>
                            <div className="review-text">
                              <h5>Amma Jhon</h5>
                              <p>CEO</p>
                              <div className="review-ul-star">
                                <ul>
                                  <li>
                                    <Link>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="reviews-">
                        <div className="reviews-para">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in volup...
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="all-seller-date-btn">
                        <div className="all-seller-date">
                          <p>January 29,2022 at 07:47 AM</p>
                        </div>
                        <div className="all-seller-btn">
                          <button
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            Send Feedback
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="reviews-all-stuff">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="edit-review-all">
                        <div className="review-flex">
                          <div className="review-img seller-review-img">
                            <figure>
                              <img
                                className="img-fluid"
                                alt="review-img-all"
                                src={reviewimg1}
                              />
                            </figure>
                            <div className="review-text">
                              <h5>Amma Jhon</h5>
                              <p>CEO</p>
                              <div className="review-ul-star">
                                <ul>
                                  <li>
                                    <Link>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="reviews-">
                        <div className="reviews-para">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in volup...
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="all-seller-date-btn">
                        <div className="all-seller-date">
                          <p>January 29,2022 at 07:47 AM</p>
                        </div>
                        <div className="all-seller-btn">
                          <button>Send Feedback</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="reviews-all-stuff">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="edit-review-all">
                        <div className="review-flex">
                          <div className="review-img seller-review-img">
                            <figure>
                              <img
                                className="img-fluid"
                                alt="review-img-all"
                                src={reviewimg2}
                              />
                            </figure>
                            <div className="review-text">
                              <h5>Amma Jhon</h5>
                              <p>CEO</p>
                              <div className="review-ul-star">
                                <ul>
                                  <li>
                                    <Link>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="reviews-">
                        <div className="reviews-para">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in volup...
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="all-seller-date-btn">
                        <div className="all-seller-date">
                          <p>January 29,2022 at 07:47 AM</p>
                        </div>
                        <div className="all-seller-btn">
                          <button>Send Feedback</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="reviews-all-stuff">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="edit-review-all">
                        <div className="review-flex">
                          <div className="review-img seller-review-img">
                            <figure>
                              <img
                                className="img-fluid"
                                alt="review-img-all"
                                src={reviewimg3}
                              />
                            </figure>
                            <div className="review-text">
                              <h5>Amma Jhon</h5>
                              <p>CEO</p>
                              <div className="review-ul-star">
                                <ul>
                                  <li>
                                    <Link>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link>
                                      <i
                                        class="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="reviews-">
                        <div className="reviews-para">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in volup...
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="all-seller-date-btn">
                        <div className="all-seller-date">
                          <p>January 29,2022 at 07:47 AM</p>
                        </div>
                        <div className="all-seller-btn">
                          <button>Send Feedback</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Seller profile ends here */}

      <Footer />

      {/* <!-- Modal --> */}

      <div className="review-modal">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg for-modal-in-middle">
            <div class="modal-content">
              <div class="modal-body">
                <div className="review-modal-all">
                  <div className="reviews-modal-para">
                    <p>Write a Review Reply Message for Amma Jhon</p>
                  </div>
                  <div className="reviews-modal-text">
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Reviews"
                    ></textarea>
                  </div>
                </div>
              </div>
       
                <div className="all-seller-btn modal-submit-btn">
                  <button data-bs-dismiss="modal">Submit</button>
                </div>
              
            </div>
          </div>
        </div>
      </div>
      {/* =========================== */}
      {/* <!-- Button trigger modal --> */}
	  
    </>
  );
}

export default Sellerreview;
