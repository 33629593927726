import React from 'react'
import { Link } from 'react-router-dom'
import { wishlist1, wishlist2, wishlist3 } from '../../Constant/Index';
import "../../assets/css/checkout.css";
import Header from '../../Components/Header';
import PageHeader from '../../Components/Pageheader';
import Footer from '../../Components/Footer';

function Checkout() {
  return (
    <>
    <Header />
    <PageHeader pagename="Check Out" />
    {/* <!-- Checkout Start Here --> */}
    <section className="check-out-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="all-checkout">
                <div className="billin-dv">
                  <h4>billing details </h4>
                  {/* <p>
                    Already Have an account? <Link to="/sign-in">Log in</Link>
                  </p> */}
                </div>
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-dv-style-select">
                        <label>Shipment Method</label>
                        <select
                          class="form-select form-select-lg mb-3"
                          aria-label=".form-select-lg example"
                        >
                          <option selected>Select Shipment method</option>
                          <option value="1">Select Shipment method</option>
                          <option value="2">Select Shipment method</option>
                          <option value="3">Select Shipment method</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-dv-input check-out-input">
                        <label>First Name</label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-dv-input check-out-input">
                        <label>Last Name</label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-dv-input check-out-input">
                        <label>Phone</label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-dv-input check-out-input">
                        <label>Email</label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-dv-input check-out-input">
                        <label>Address</label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-dv-input check-out-input">
                        <label>Suite, Apartment, etc </label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-dv-input check-out-input">
                        <label>City</label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-dv-input check-out-input">
                        <label>Country/Region</label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-dv-input check-out-input">
                        <label>State</label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-dv-input check-out-input">
                        <label>Zip Code</label>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="check-and-text">
                        <div class="form-group">
                          <input type="checkbox" id="check" />
                          <label for="check">Save Shipping Details</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-dv-input check-out-input">
                        <label>Payment Details</label>
                        <input type="text" placeholder="mm/yy       cvc"/>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="checkout-btn">
                        <Link to="/profile" className="all-button">
                        add card{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="col-md-5">
              <div className="summary-dv">
                <h4>product summary</h4>
              </div>

              <div className="product-detail-all check-out-detail">
                <div className="product-head">
                  <h5>PRODUCT DETAILS</h5>
                </div>
                <div className="for-over-flow">
                  <div className="pro-first-dv">
                    <div className="pro-img">
                      <img className="img-fluid" src={wishlist1} />
                      <div className="pro-text">
                        <p>Forum Cookie</p>
                        <p className="for-font-change">(2x)</p>
                      </div>
                    </div>

                    <div className="pro-price">
                      <p>$539.00</p>
                    </div>
                  </div>
                  <div className="pro-first-dv">
                    <div className="pro-img">
                      <img className="img-fluid" src={wishlist2} />
                      <div className="pro-text">
                        <p>Forum Cookie</p>
                        <p className="for-font-change">(2x)</p>
                      </div>
                    </div>

                    <div className="pro-price">
                      <p>$539.00</p>
                    </div>
                  </div>

                  <div className="pro-first-dv">
                    <div className="pro-img">
                      <img className="img-fluid" src={wishlist3} />
                      <div className="pro-text">
                        <p>Forum Cookie</p>
                        <p className="for-font-change">(2x)</p>
                      </div>
                    </div>

                    <div className="pro-price">
                      <p>$539.00</p>
                    </div>
                  </div>
                  <div className="pro-first-dv">
                    <div className="pro-img">
                      <img className="img-fluid" src={wishlist1} />
                      <div className="pro-text">
                        <p>Forum Cookie</p>
                        <p className="for-font-change">(2x)</p>
                      </div>
                    </div>

                    <div className="pro-price">
                      <p>$539.00</p>
                    </div>
                  </div>
                  <div className="pro-first-dv">
                    <div className="pro-img">
                      <img className="img-fluid" src={wishlist2} />
                      <div className="pro-text">
                        <p>Forum Cookie</p>
                        <p className="for-font-change">(2x)</p>
                      </div>
                    </div>

                    <div className="pro-price">
                      <p>$539.00</p>
                    </div>
                  </div>
                  <div className="pro-first-dv">
                    <div className="pro-img">
                      <img className="img-fluid" src={wishlist3} />
                      <div className="pro-text">
                        <p>Forum Cookie</p>
                        <p className="for-font-change">(2x)</p>
                      </div>
                    </div>

                    <div className="pro-price">
                      <p>$539.00</p>
                    </div>
                  </div>
                  <div className="pro-first-dv">
                    <div className="pro-img">
                      <img className="img-fluid" src={wishlist1} />
                      <div className="pro-text">
                        <p>Forum Cookie</p>
                        <p className="for-font-change">(2x)</p>
                      </div>
                    </div>

                    <div className="pro-price">
                      <p>$539.00</p>
                    </div>
                  </div>
                  <div className="pro-first-dv">
                    <div className="pro-img">
                      <img className="img-fluid" src={wishlist2} />
                      <div className="pro-text">
                        <p>Forum Cookie</p>
                        <p className="for-font-change">(2x)</p>
                      </div>
                    </div>

                    <div className="pro-price">
                      <p>$539.00</p>
                    </div>
                  </div>
                </div>

                <div className="pament-details">
                  <h5>PAYMENT DETAILS</h5>
                </div>

                <div className="all-total">
                  <div className="total">
                    <p className="for-total-css">Total :</p>
                    <p className="for-total-green">$152.00</p>
                  </div>
                  <div className="total">
                    <p className="for-total-css">Shipping Charges : </p>
                    <p className="for-total-green">$152.00</p>
                  </div>
                  <div className="total">
                    <p className="for-total-css">Taxes Applied : </p>
                    <p className="for-total-green">$52.00</p>
                  </div>
                </div>
              </div>
              <div className="ordr-total">
                <p className="for-order-total-css">Order Total : </p>
                <p className="for-total-white">$3025.00</p>
              </div>
            </div>
          </div>
          {/* Second Row */}
          <div className="row check-box-row">
            <div className="col-md-6">
              <div className="check-and">
                <div class="form-group">
                  <input type="checkbox" id="bottom-check" />
                  <label for="bottom-check">
                    Proident, sunt in culpa qui officia deserunt mollit anim id
                    est laborum.
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="checkout-btns">
                <Link className="all-button">
                CONFIRM PAYMENT 
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =============================================== */}
      <Footer />
      
    </>
  )
}

export default Checkout
