import React from 'react'
import { Link } from 'react-router-dom'

function Careercompo() {
  return (
    <>
      
      {/* Open Job Section */}
      <section className="open-job-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="open-job-all">
                <div className="open-dv">
                  <div className="open-first-dv">
                    <h3>Front end Designer</h3>
                    <p>San Francisco, USA</p>
                  </div>
                  <div className="full-time-btn">
                    <Link>Full Time</Link>
                  </div>
                </div>
                <div className="open-all-job-para">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,{" "}
                  </p>
                  <Link to="/career-detail">View More</Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="open-job-all">
                <div className="open-dv">
                  <div className="open-first-dv">
                    <h3>Front end Designer</h3>
                    <p>San Francisco, USA</p>
                  </div>
                  <div className="full-time-btn">
                    <Link>Full Time</Link>
                  </div>
                </div>
                <div className="open-all-job-para">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,{" "}
                  </p>
                  <Link to="/career-detail">View More</Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="open-job-all">
                <div className="open-dv">
                  <div className="open-first-dv">
                    <h3>Front end Designer</h3>
                    <p>San Francisco, USA</p>
                  </div>
                  <div className="full-time-btn">
                    <Link>Full Time</Link>
                  </div>
                </div>
                <div className="open-all-job-para">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,{" "}
                  </p>
                  <Link to="/career-detail">View More</Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="open-job-all">
                <div className="open-dv">
                  <div className="open-first-dv">
                    <h3>Front end Designer</h3>
                    <p>San Francisco, USA</p>
                  </div>
                  <div className="full-time-btn">
                    <Link>Full Time</Link>
                  </div>
                </div>
                <div className="open-all-job-para">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,{" "}
                  </p>
                  <Link to="/career-detail">View More</Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="open-job-all">
                <div className="open-dv">
                  <div className="open-first-dv">
                    <h3>Front end Designer</h3>
                    <p>San Francisco, USA</p>
                  </div>
                  <div className="full-time-btn">
                    <Link>Full Time</Link>
                  </div>
                </div>
                <div className="open-all-job-para">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,{" "}
                  </p>
                  <Link>View More</Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="open-job-all">
                <div className="open-dv">
                  <div className="open-first-dv">
                    <h3>Front end Designer</h3>
                    <p>San Francisco, USA</p>
                  </div>
                  <div className="full-time-btn">
                    <Link>Full Time</Link>
                  </div>
                </div>
                <div className="open-all-job-para">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,{" "}
                  </p>
                  <Link to="/career-detail">View More</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="caree-view-more-btn">
            <Link>view more</Link>
          </div>
        </div>
      </section>
	  {/* ========================= */}
      
    </>
  )
}

export default Careercompo
