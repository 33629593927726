import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import { blog1, blog2, blog3, blog4, blog5, blog6 } from "../../Constant/Index";

function Blog() {
  return (
    <>
      <Header />
      <PageHeader pagename="Blogs" />
      {/* Blog Section */}
      <section className="blog-sec">
        <div className="container">
          <div className="blogs-head">
            <h5>Read Our Most recent Blogs</h5>
            <p>
              {" "}
              Nisl vel pretium lectus quam id leo. Odio ut enim blandit volutpat
              maecenas volutpat blandit. Nunc vel risus commodo viverra
              maecenas. Lacus luctus accumsan tortor posuere ac ut consequat.{" "}
            </p>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="blog-compo-all-dv">
                <div className="blog-compo-img">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="blog-main-img"
                      src={blog6}
                    />
                  </figure>
                </div>
                <div className="blog-compo-text-date">
                  <h5>Category : Lorem Ipsum is simply</h5>
                  <h5>
                    <span>30'Oct 2022</span>
                  </h5>
                </div>
                <div className="only-blog-text">
                  <h4>Lorem Ipsum is simply </h4>
                  <p>
                    sum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore
                  </p>
                </div>
                <div className="blog-main-btn">
                  <Link to="/blog-detail">read more</Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="blog-compo-all-dv">
                <div className="blog-compo-img">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="blog-main-img"
                      src={blog5}
                    />
                  </figure>
                </div>
                <div className="blog-compo-text-date">
                  <h5>Category : Lorem Ipsum is simply</h5>
                  <h5>
                    <span>30'Oct 2022</span>
                  </h5>
                </div>
                <div className="only-blog-text">
                  <h4>Lorem Ipsum is simply </h4>
                  <p>
                    sum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore
                  </p>
                </div>
                <div className="blog-main-btn">
                  <Link to="/blog-detail">read more</Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="blog-compo-all-dv">
                <div className="blog-compo-img">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="blog-main-img"
                      src={blog4}
                    />
                  </figure>
                </div>
                <div className="blog-compo-text-date">
                  <h5>Category : Lorem Ipsum is simply</h5>
                  <h5>
                    <span>30'Oct 2022</span>
                  </h5>
                </div>
                <div className="only-blog-text">
                  <h4>Lorem Ipsum is simply </h4>
                  <p>
                    sum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore
                  </p>
                </div>
                <div className="blog-main-btn">
                  <Link to="/blog-detail">read more</Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="blog-compo-all-dv">
                <div className="blog-compo-img">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="blog-main-img"
                      src={blog1}
                    />
                  </figure>
                </div>
                <div className="blog-compo-text-date">
                  <h5>Category : Lorem Ipsum is simply</h5>
                  <h5>
                    <span>30'Oct 2022</span>
                  </h5>
                </div>
                <div className="only-blog-text">
                  <h4>Lorem Ipsum is simply </h4>
                  <p>
                    sum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore
                  </p>
                </div>
                <div className="blog-main-btn">
                  <Link to="/blog-detail">read more</Link>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="blog-compo-all-dv">
                <div className="blog-compo-img">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="blog-main-img"
                      src={blog2}
                    />
                  </figure>
                </div>
                <div className="blog-compo-text-date">
                  <h5>Category : Lorem Ipsum is simply</h5>
                  <h5>
                    <span>30'Oct 2022</span>
                  </h5>
                </div>
                <div className="only-blog-text">
                  <h4>Lorem Ipsum is simply </h4>
                  <p>
                    sum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore
                  </p>
                </div>
                <div className="blog-main-btn">
                  <Link to="/blog-detail">read more</Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="blog-compo-all-dv">
                <div className="blog-compo-img">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="blog-main-img"
                      src={blog3}
                    />
                  </figure>
                </div>
                <div className="blog-compo-text-date">
                  <h5>Category : Lorem Ipsum is simply</h5>
                  <h5>
                    <span>30'Oct 2022</span>
                  </h5>
                </div>
                <div className="only-blog-text">
                  <h4>Lorem Ipsum is simply </h4>
                  <p>
                    sum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore
                  </p>
                </div>
                <div className="blog-main-btn">
                  <Link to="/blog-detail">read more</Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="blog-compo-all-dv">
                <div className="blog-compo-img">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="blog-main-img"
                      src={blog6}
                    />
                  </figure>
                </div>
                <div className="blog-compo-text-date">
                  <h5>Category : Lorem Ipsum is simply</h5>
                  <h5>
                    <span>30'Oct 2022</span>
                  </h5>
                </div>
                <div className="only-blog-text">
                  <h4>Lorem Ipsum is simply </h4>
                  <p>
                    sum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore
                  </p>
                </div>
                <div className="blog-main-btn">
                  <Link to="/blog-detail">read more</Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="blog-compo-all-dv">
                <div className="blog-compo-img">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="blog-main-img"
                      src={blog5}
                    />
                  </figure>
                </div>
                <div className="blog-compo-text-date">
                  <h5>Category : Lorem Ipsum is simply</h5>
                  <h5>
                    <span>30'Oct 2022</span>
                  </h5>
                </div>
                <div className="only-blog-text">
                  <h4>Lorem Ipsum is simply </h4>
                  <p>
                    sum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore
                  </p>
                </div>
                <div className="blog-main-btn">
                  <Link to="/blog-detail">read more</Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="blog-compo-all-dv">
                <div className="blog-compo-img">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="blog-main-img"
                      src={blog4}
                    />
                  </figure>
                </div>
                <div className="blog-compo-text-date">
                  <h5>Category : Lorem Ipsum is simply</h5>
                  <h5>
                    <span>30'Oct 2022</span>
                  </h5>
                </div>
                <div className="only-blog-text">
                  <h4>Lorem Ipsum is simply </h4>
                  <p>
                    sum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore
                  </p>
                </div>
                <div className="blog-main-btn">
                  <Link to="/blog-detail">read more</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======================== */}
      <Footer />
    </>
  );
}

export default Blog;
