import React from 'react'
import { Link } from 'react-router-dom'
import Blogsmain from '../../Components/Blogsmain'
import Footer from '../../Components/Footer'
import Header from '../../Components/Header'
import Newsletter from '../../Components/Newsletter'
import Sliderforproduct from '../../Components/Sliderforproduct'
import { newfashionbanner, organic1, organic2, organic3, product1, purse, purse2, sellerstore1, sellerstore10, sellerstore11, sellerstore12, sellerstore2, sellerstore3, sellerstore4, sellerstore5, sellerstore6, sellerstore7, sellerstore8, sellerstore9, shoe, slider1, slider4 } from '../../Constant/Index'

function Newfashioncollection() {
  return (
    <>
    <Header />
			{/* Product Section */}
			<section className="fashion-product-sec-banner">
				<div className="container">
					{/* <div className="all-banenr-back">
						<div className="row"> */}
							{/* <div className="col-md-6">
                <div className="banenr-text">
                  <h5>New Customizable Products Collection</h5>
                  <h2>Smart People by smartly</h2>
                  <div className="discover-btn">
                    <Link>Discover Now</Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="banner-image">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="banner-img"
                      src={customize1}
                    />
                  </figure>
                </div>
              </div> */}
							<div className="col-md-12">
					
							{/* </div>
						</div> */}
					</div>
          <Sliderforproduct />
				</div>
			</section>

			{/* ============================ */}

			{/* Product All Seciton */}
			<section className="product-sec">
				<div className="container">
					<div className="row sec-first-row">
						<div className="col-md-3">
							<div className="product-filter">
								<p>Filter :</p>
								<p>
									<span>Clean All</span>
								</p>
							</div>
						</div>
						<div className="col-md-9">
							<div className="sort-flex">
								<div className="sort-all">
									<p>Sort By :</p>
									<span>
										<div class="dropdown ">
											<button
												class="btn btn-secondary dropdown-toggle"
												type="button"
												id="dropdownMenuButton2"
												data-bs-toggle="dropdown"
												aria-expanded="false"
											>
												Default Sorting
											</button>
											<ul
												class="dropdown-menu"
												aria-labelledby="dropdownMenuButton2"
											>
												<li>
													<a class="dropdown-item" href="#">
														Default Sorting
													</a>
												</li>
												<li>
													<a class="dropdown-item" href="#">
														Default Sorting
													</a>
												</li>
												<li>
													<a class="dropdown-item" href="#">
														Default Sorting
													</a>
												</li>
											</ul>
										</div>
									</span>
								</div>
								<div className="sort-all">
									<span>
										<div class="dropdown ">
											<button
												class="btn btn-secondary dropdown-toggle"
												type="button"
												id="dropdownMenuButton2"
												data-bs-toggle="dropdown"
												aria-expanded="false"
											>
												Show 12
											</button>
											<ul
												class="dropdown-menu"
												aria-labelledby="dropdownMenuButton2"
											>
												<li>
													<a class="dropdown-item" href="#">
														Show 12
													</a>
												</li>
												<li>
													<a class="dropdown-item" href="#">
														Show 12
													</a>
												</li>
												<li>
													<a class="dropdown-item" href="#">
														Show 12
													</a>
												</li>
											</ul>
										</div>
									</span>
									<span className="two-grid-icons">
										<Link>
											<i class="fa fa-th" aria-hidden="true"></i>
										</Link>
										<Link>
										<i class="fa fa-th-list" aria-hidden="true"></i>
										</Link>
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-3">
							<div className="all-product-accordian">
								<div class="accordion" id="accordionExample">
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingOne">
											<button
												class="accordion-button"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseOne"
												aria-expanded="true"
												aria-controls="collapseOne"
											>
												All Categories
											</button>
										</h2>
										<div
											id="collapseOne"
											class="accordion-collapse collapse show"
											aria-labelledby="headingOne"
											data-bs-parent="#accordionExample"
										>
											<div class="accordion-body">
												<div className="inner-accordian">
													{/* ============== */}

													<div class="accordion-item">
														<h2 class="accordion-header" id="flush-headingOne">
															<button
																class="accordion-button collapsed"
																type="button"
																data-bs-toggle="collapse"
																data-bs-target="#flush-collapseOne"
																aria-expanded="false"
																aria-controls="flush-collapseOne"
															>
																Accessories
															</button>
														</h2>
														<div
															id="flush-collapseOne"
															class="accordion-collapse collapse"
															aria-labelledby="flush-headingOne"
															data-bs-parent="#accordionFlushExample"
														>
															<div class="accordion-body">
																<ul className="accessories-body">
																	<li> Accessories</li>
																	<li> Accessories</li>
																	<li> Accessories</li>
																	<li> Accessories</li>
																	<li> Accessories</li>
																</ul>
															</div>
														</div>
													</div>
													<div class="accordion-item">
														<h2 class="accordion-header" id="flush-headingTwo">
															<button
																class="accordion-button collapsed"
																type="button"
																data-bs-toggle="collapse"
																data-bs-target="#flush-collapseTwo"
																aria-expanded="false"
																aria-controls="flush-collapseTwo"
															>
																Babies
															</button>
														</h2>
														<div
															id="flush-collapseTwo"
															class="accordion-collapse collapse"
															aria-labelledby="flush-headingTwo"
															data-bs-parent="#accordionFlushExample"
														>
															<div class="accordion-body">
																<ul className="accessories-body">
																	<li> Babies</li>
																	<li> Babies</li>
																	<li> Babies</li>
																	<li> Babies</li>
																	<li> Babies</li>
																</ul>
															</div>
														</div>
													</div>
													<div class="accordion-item">
														<h2
															class="accordion-header"
															id="flush-headingThree"
														>
															<button
																class="accordion-button collapsed"
																type="button"
																data-bs-toggle="collapse"
																data-bs-target="#flush-collapseThree"
																aria-expanded="false"
																aria-controls="flush-collapseThree"
															>
																Beauty
															</button>
														</h2>
														<div
															id="flush-collapseThree"
															class="accordion-collapse collapse"
															aria-labelledby="flush-headingThree"
															data-bs-parent="#accordionFlushExample"
														>
															<div class="accordion-body">
																<ul className="accessories-body">
																	<li> Beauty</li>
																	<li> Beauty</li>
																	<li> Beauty</li>
																	<li> Beauty</li>
																	<li> Beauty</li>
																</ul>
															</div>
														</div>
													</div>
													<div class="accordion-item">
														<h2 class="accordion-header" id="flush-headingfour">
															<button
																class="accordion-button collapsed"
																type="button"
																data-bs-toggle="collapse"
																data-bs-target="#flush-collapsefour"
																aria-expanded="false"
																aria-controls="flush-collapsefour"
															>
																Decoration
															</button>
														</h2>
														<div
															id="flush-collapsefour"
															class="accordion-collapse collapse"
															aria-labelledby="flush-headingfour"
															data-bs-parent="#accordionFlushExample"
														>
															<div class="accordion-body">
																<ul className="accessories-body">
																	<li>Decoration</li>
																	<li>Decoration</li>
																	<li>Decoration</li>
																	<li>Decoration</li>
																	<li>Decoration</li>
																</ul>
															</div>
														</div>
													</div>
													<div class="accordion-item">
														<h2 class="accordion-header" id="flush-headingfive">
															<button
																class="accordion-button collapsed"
																type="button"
																data-bs-toggle="collapse"
																data-bs-target="#flush-collapsefive"
																aria-expanded="false"
																aria-controls="flush-collapsefive"
															>
																Electronics
															</button>
														</h2>
														<div
															id="flush-collapsefive"
															class="accordion-collapse collapse"
															aria-labelledby="flush-headingfive"
															data-bs-parent="#accordionFlushExample"
														>
															<div class="accordion-body">
																{" "}
																<ul className="accessories-body">
																	<li>Electronics</li>
																	<li>Electronics</li>
																	<li>Electronics</li>
																	<li>Electronics</li>
																	<li>Electronics</li>
																</ul>
															</div>
														</div>
													</div>
													<div class="accordion-item">
														<h2 class="accordion-header" id="flush-headingsix">
															<button
																class="accordion-button collapsed"
																type="button"
																data-bs-toggle="collapse"
																data-bs-target="#flush-collapsesix"
																aria-expanded="false"
																aria-controls="flush-collapsesix"
															>
																Fashion
															</button>
														</h2>
														<div
															id="flush-collapsesix"
															class="accordion-collapse collapse"
															aria-labelledby="flush-headingsix"
															data-bs-parent="#accordionFlushExample"
														>
															<div class="accordion-body">
																<ul className="accessories-body">
																	<li>Fashion</li>
																	<li>Fashion</li>
																	<li>Fashion</li>
																	<li>Fashion</li>
																	<li>Fashion</li>
																</ul>
															</div>
														</div>
													</div>
													<div class="accordion-item">
														<h2
															class="accordion-header"
															id="flush-headingseventh"
														>
															<button
																class="accordion-button collapsed"
																type="button"
																data-bs-toggle="collapse"
																data-bs-target="#flush-collapseseventh"
																aria-expanded="false"
																aria-controls="flush-collapseseventh"
															>
																Food
															</button>
														</h2>
														<div
															id="flush-collapseseventh"
															class="accordion-collapse collapse"
															aria-labelledby="flush-headingseventh"
															data-bs-parent="#accordionFlushExample"
														>
															<div class="accordion-body">
																{" "}
																<ul className="accessories-body">
																	<li>Food</li>
																	<li>Furniture</li>
																	<li>Kitchen</li>
																	<li>Medical</li>
																	<li>Sports</li>
																</ul>
															</div>
														</div>
													</div>

													<div class="accordion-item">
														<h2
															class="accordion-header"
															id="flush-headingeight"
														>
															<button
																class="accordion-button collapsed"
																type="button"
																data-bs-toggle="collapse"
																data-bs-target="#flush-collapseeight"
																aria-expanded="false"
																aria-controls="flush-collapseeight"
															>
																Furniture
															</button>
														</h2>
														<div
															id="flush-collapseeight"
															class="accordion-collapse collapse"
															aria-labelledby="flush-headingeight"
															data-bs-parent="#accordionFlushExample"
														>
															<div class="accordion-body">
																<ul className="accessories-body">
																	<li>Furniture</li>
																	<li>Furniture</li>
																	<li>Furniture</li>
																	<li>Furniture</li>
																	<li>Furniture</li>
																</ul>
															</div>
														</div>
													</div>
													<div class="accordion-item">
														<h2
															class="accordion-header"
															id="flush-headingninth"
														>
															<button
																class="accordion-button collapsed"
																type="button"
																data-bs-toggle="collapse"
																data-bs-target="#flush-collapseninth"
																aria-expanded="false"
																aria-controls="flush-collapseninth"
															>
																Kitchen
															</button>
														</h2>
														<div
															id="flush-collapseninth"
															class="accordion-collapse collapse"
															aria-labelledby="flush-headingninth"
															data-bs-parent="#accordionFlushExample"
														>
															<div class="accordion-body">
																<ul className="accessories-body">
																	<li>Kitchen</li>
																	<li>Kitchen</li>
																	<li>Kitchen</li>
																	<li>Kitchen</li>
																	<li>Kitchen</li>
																</ul>
															</div>
														</div>
													</div>
													<div class="accordion-item">
														<h2
															class="accordion-header"
															id="flush-headingtenth"
														>
															<button
																class="accordion-button collapsed"
																type="button"
																data-bs-toggle="collapse"
																data-bs-target="#flush-collapsetenth"
																aria-expanded="false"
																aria-controls="flush-collapsetenth"
															>
																Medical
															</button>
														</h2>
														<div
															id="flush-collapsetenth"
															class="accordion-collapse collapse"
															aria-labelledby="flush-headingtenth"
															data-bs-parent="#accordionFlushExample"
														>
															<div class="accordion-body">
																<ul className="accessories-body">
																	<li>Medical</li>
																	<li>Medical</li>
																	<li>Medical</li>
																	<li>Medical</li>
																	<li>Medical</li>
																</ul>
															</div>
														</div>
													</div>
													<div class="accordion-item">
														<h2
															class="accordion-header"
															id="flush-headingeleven"
														>
															<button
																class="accordion-button collapsed"
																type="button"
																data-bs-toggle="collapse"
																data-bs-target="#flush-collapseeleven"
																aria-expanded="false"
																aria-controls="flush-collapseeleven"
															>
																Sports
															</button>
														</h2>
														<div
															id="flush-collapseeleven"
															class="accordion-collapse collapse"
															aria-labelledby="flush-headingeleven"
															data-bs-parent="#accordionFlushExample"
														>
															<div class="accordion-body">
																<ul className="accessories-body">
																	<li>Sports</li>
																	<li>Sports</li>
																	<li>Sports</li>
																	<li>Sports</li>
																	<li>Sports</li>
																</ul>
															</div>
														</div>
													</div>

													<div class="accordion-item">
														<h2
															class="accordion-header"
															id="flush-headingtweleve"
														>
															<button
																class="accordion-button collapsed"
																type="button"
																data-bs-toggle="collapse"
																data-bs-target="#flush-collapsetweleve"
																aria-expanded="false"
																aria-controls="flush-collapsetweleve"
															>
																Watches
															</button>
														</h2>
														<div
															id="flush-collapsetweleve"
															class="accordion-collapse collapse"
															aria-labelledby="flush-headingtweleve"
															data-bs-parent="#accordionFlushExample"
														>
															<div class="accordion-body">
																<ul className="accessories-body">
																	<li>Watches</li>
																	<li>Watches</li>
																	<li>Watches</li>
																	<li>Watches</li>
																	<li>Watches</li>
																</ul>
															</div>
														</div>
													</div>

													{/* ==================== */}
												</div>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingTwo">
											<button
												class="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseTwo"
												aria-expanded="false"
												aria-controls="collapseTwo"
											>
												Price
											</button>
										</h2>
										<div
											id="collapseTwo"
											class="accordion-collapse collapse"
											aria-labelledby="headingTwo"
											data-bs-parent="#accordionExample"
										>
											<div class="accordion-body">
												<div className="all-price-li">
													<ul>
														<li>$0.00 - $100.00</li>
														<li>$100.00 - $200.00</li>
														<li>$200.00 - $300.00</li>
														<li>$300.00 - $500.00</li>
														<li>$500.00+</li>
													</ul>
												</div>
												<div className="price-input">
													<div className="input-1">
														<input type="number" placeholder="$Min" />
													</div>
													<div className="input-1">
														<input type="number" placeholder="$Max" />
													</div>
													<div className="input-1-btn">
														<button>Go</button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingThree">
											<button
												class="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseThree"
												aria-expanded="false"
												aria-controls="collapseThree"
											>
												Weight
											</button>
										</h2>
										<div
											id="collapseThree"
											class="accordion-collapse collapse"
											aria-labelledby="headingThree"
											data-bs-parent="#accordionExample"
										>
											<div class="accordion-body">
												<div className="check-box-sizes">
													<div className="check-and">
														<div class="form-group">
															<input type="checkbox" id="html" />
															<label for="html">1kg</label>
														</div>
													</div>
													<div className="check-and">
														<div class="form-group">
															<input type="checkbox" id="Large" />
															<label for="Large">2kg</label>
														</div>
													</div>
													<div className="check-and">
														<div class="form-group">
															<input type="checkbox" id="Medium" />
															<label for="Medium">3kg</label>
														</div>
													</div>
													<div className="check-and">
														<div class="form-group">
															<input type="checkbox" id="Small" />
															<label for="Small">4kg</label>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="accordion-item">
                    <h2 class="accordion-header" id="headingfifth">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsefifth"
                        aria-expanded="false"
                        aria-controls="collapsefifth"
                      >
                        Colors
                      </button>
                    </h2>
                    <div
                      id="collapsefifth"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingfifth"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="check-box-sizes">
                          <div className="check-and">
                            <div class="form-group">
                              <input type="checkbox" id="Black" />
                              <label for="Black">Black</label>
                            </div>
                          </div>
                          <div className="check-and">
                            <div class="form-group">
                              <input type="checkbox" id="Blue" />
                              <label for="Blue">Large</label>
                            </div>
                          </div>
                          <div className="check-and">
                            <div class="form-group">
                              <input type="checkbox" id="Brown" />
                              <label for="Brown">Brown</label>
                            </div>
                          </div>
                          <div className="check-and">
                            <div class="form-group">
                              <input type="checkbox" id="Green" />
                              <label for="Green">Green</label>
                            </div>
                          </div>
                          <div className="check-and">
                            <div class="form-group">
                              <input type="checkbox" id="Grey" />
                              <label for="Grey">Grey</label>
                            </div>
                          </div>

                          <div className="check-and">
                            <div class="form-group">
                              <input
                                type="checkbox"
                                id="Orange
"
                              />
                              <label
                                for="Orange
"
                              >
                                Orange
                              </label>
                            </div>
                          </div>
                          <div className="check-and">
                            <div class="form-group">
                              <input type="checkbox" id="Yellow" />
                              <label for="Yellow">Yellow</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
								</div>
							</div>
						</div>
						<div className="col-md-9">
							<div className="row all-class-for-padding">
								<div className="col-md-4">
								<Link to="/design-product">
								<div className="slide-dv organic-food fashion-product-all">
										<figure>
											<img className="img-fluid" alt="slid-1" src={sellerstore1} />
										</figure>
										<div className="slide-text">
											<p>Lorem Ipsum is simply </p>
											<div className="slide-for-flex">
												<h5>$567.89</h5>
												<div className="slide-star">
													<ul className="slide-star-ul">
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="product-heading">
											<div className="pro-head">
												<h5>Fashion</h5>
											</div>
										</div>
									</div>
								</Link>
								</div>
								<div className="col-md-4">
									<Link to="/design-product">
									<div className="slide-dv organic-food fashion-product-all">
										<figure>
											<img className="img-fluid" alt="slid-1" src={sellerstore2} />
										</figure>
										<div className="slide-text">
											<p>Lorem Ipsum is simply </p>
											<div className="slide-for-flex">
												<h5>$567.89</h5>
												<div className="slide-star">
													<ul className="slide-star-ul">
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="product-heading">
											<div className="pro-head">
												<h5>Fashion</h5>
											</div>
										</div>
									</div>
									</Link>
								</div>
								<div className="col-md-4">
								<Link to="/design-product">
								<div className="slide-dv organic-food fashion-product-all">
										<figure>
											<img className="img-fluid" alt="slid-1" src={sellerstore3} />
										</figure>
										<div className="slide-text">
											<p>Lorem Ipsum is simply </p>
											<div className="slide-for-flex">
												<h5>$567.89</h5>
												<div className="slide-star">
													<ul className="slide-star-ul">
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="product-heading">
											<div className="pro-head">
												<h5>Fashion</h5>
											</div>
										</div>
									</div>
								</Link>
								</div>
								<div className="col-md-4">
									<Link to="/design-product">
									<div className="slide-dv organic-food fashion-product-all">
										<figure>
											<img className="img-fluid" alt="slid-1" src={sellerstore4} />
										</figure>
										<div className="slide-text">
											<p>Lorem Ipsum is simply </p>
											<div className="slide-for-flex">
												<h5>$567.89</h5>
												<div className="slide-star">
													<ul className="slide-star-ul">
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="product-heading">
											<div className="pro-head">
												<h5>Fashion</h5>
											</div>
										</div>
									</div>
									</Link>
								</div>
								<div className="col-md-4">
									<Link to="/design-product">
									<div className="slide-dv organic-food fashion-product-all">
										<figure>
											<img className="img-fluid" alt="slid-1" src={sellerstore5} />
										</figure>
										<div className="slide-text">
											<p>Lorem Ipsum is simply </p>
											<div className="slide-for-flex">
												<h5>$567.89</h5>
												<div className="slide-star">
													<ul className="slide-star-ul">
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="product-heading">
											<div className="pro-head">
												<h5>Fashion</h5>
											</div>
										</div>
									</div>
									</Link>
								</div>
								<div className="col-md-4">
								<Link to="/design-product">
								<div className="slide-dv organic-food fashion-product-all">
										<figure>
											<img className="img-fluid" alt="slid-1" src={sellerstore6} />
										</figure>
										<div className="slide-text">
											<p>Lorem Ipsum is simply </p>
											<div className="slide-for-flex">
												<h5>$567.89</h5>
												<div className="slide-star">
													<ul className="slide-star-ul">
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="product-heading">
											<div className="pro-head">
												<h5>Fashion</h5>
											</div>
										</div>
									</div>
								</Link>
								</div>

								<div className="col-md-4">
								<Link to="/design-product">
								<div className="slide-dv organic-food fashion-product-all">
										<figure>
											<img className="img-fluid" alt="slid-1" src={sellerstore7} />
										</figure>
										<div className="slide-text">
											<p>Lorem Ipsum is simply </p>
											<div className="slide-for-flex">
												<h5>$567.89</h5>
												<div className="slide-star">
													<ul className="slide-star-ul">
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="product-heading">
											<div className="pro-head">
												<h5>Fashion</h5>
											</div>
										</div>
									</div>
								</Link>
								</div>

								<div className="col-md-4">
							<Link to="/design-product">
							<div className="slide-dv organic-food fashion-product-all">
										<figure>
											<img className="img-fluid" alt="slid-1" src={sellerstore8} />
										</figure>
										<div className="slide-text">
											<p>Lorem Ipsum is simply </p>
											<div className="slide-for-flex">
												<h5>$567.89</h5>
												<div className="slide-star">
													<ul className="slide-star-ul">
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="product-heading">
											<div className="pro-head">
												<h5>Fashion</h5>
											</div>
										</div>
									</div>
							</Link>
								</div>
								<div className="col-md-4">
									<Link to="/design-product">
									<div className="slide-dv organic-food fashion-product-all">
										<figure>
											<img className="img-fluid" alt="slid-1" src={sellerstore9} />
										</figure>
										<div className="slide-text">
											<p>Lorem Ipsum is simply </p>
											<div className="slide-for-flex">
												<h5>$567.89</h5>
												<div className="slide-star">
													<ul className="slide-star-ul">
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="product-heading">
											<div className="pro-head">
												<h5>Fashion</h5>
											</div>
										</div>
									</div>
									</Link>
								</div>
								<div className="col-md-4">
								<Link to="/design-product">
								<div className="slide-dv organic-food fashion-product-all">
										<figure>
											<img className="img-fluid" alt="slid-1" src={sellerstore10} />
										</figure>
										<div className="slide-text">
											<p>Lorem Ipsum is simply </p>
											<div className="slide-for-flex">
												<h5>$567.89</h5>
												<div className="slide-star">
													<ul className="slide-star-ul">
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="product-heading">
											<div className="pro-head">
												<h5>Fashion</h5>
											</div>
										</div>
									</div>
								</Link>
								</div>
								<div className="col-md-4">
									<Link to="/design-product">
									<div className="slide-dv organic-food fashion-product-all">
										<figure>
											<img className="img-fluid" alt="slid-1" src={sellerstore11} />
										</figure>
										<div className="slide-text">
											<p>Lorem Ipsum is simply </p>
											<div className="slide-for-flex">
												<h5>$567.89</h5>
												<div className="slide-star">
													<ul className="slide-star-ul">
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="product-heading">
											<div className="pro-head">
												<h5>Fashion</h5>
											</div>
										</div>
									</div>
									</Link>
								</div>
								<div className="col-md-4">
								<Link to="/design-product">
								<div className="slide-dv organic-food fashion-product-all">
										<figure>
											<img className="img-fluid" alt="slid-1" src={sellerstore12} />
										</figure>
										<div className="slide-text">
											<p>Lorem Ipsum is simply </p>
											<div className="slide-for-flex">
												<h5>$567.89</h5>
												<div className="slide-star">
													<ul className="slide-star-ul">
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="product-heading">
											<div className="pro-head">
												<h5>Fashion</h5>
											</div>
										</div>
									</div>
								</Link>
								</div>
								<div className="col-md-4">
								<Link to="/design-product">
								<div className="slide-dv organic-food fashion-product-all">
										<figure>
											<img className="img-fluid" alt="slid-1" src={purse} />
										</figure>
										<div className="slide-text">
											<p>Lorem Ipsum is simply </p>
											<div className="slide-for-flex">
												<h5>$567.89</h5>
												<div className="slide-star">
													<ul className="slide-star-ul">
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="product-heading">
											<div className="pro-head">
												<h5>Fashion</h5>
											</div>
										</div>
									</div>
								</Link>
								</div>
								<div className="col-md-4">
								<Link to="/design-product">
								<div className="slide-dv organic-food fashion-product-all">
										<figure>
											<img className="img-fluid" alt="slid-1" src={purse2} />
										</figure>
										<div className="slide-text">
											<p>Lorem Ipsum is simply </p>
											<div className="slide-for-flex">
												<h5>$567.89</h5>
												<div className="slide-star">
													<ul className="slide-star-ul">
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="product-heading">
											<div className="pro-head">
												<h5>Fashion</h5>
											</div>
										</div>
									</div>
								</Link>
								</div>
								<div className="col-md-4">
									<Link to="/design-product">
									<div className="slide-dv organic-food fashion-product-all">
										<figure>
											<img className="img-fluid" alt="slid-1" src={shoe} />
										</figure>
										<div className="slide-text">
											<p>Lorem Ipsum is simply </p>
											<div className="slide-for-flex">
												<h5>$567.89</h5>
												<div className="slide-star">
													<ul className="slide-star-ul">
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
														<li>
															{" "}
															<i class="fa fa-star" aria-hidden="true" />
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="product-heading">
											<div className="pro-head">
												<h5>Fashion</h5>
											</div>
										</div>
									</div>
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="pagination-all">
						<nav aria-label="Page navigation example">
							<ul class="pagination">
								<li class="page-item">
									<a class="page-link" href="#" aria-label="Previous">
										<span aria-hidden="true">&laquo;</span>
									</a>
								</li>
								<li class="page-item">
									<a class="page-link" href="#">
										1
									</a>
								</li>
								<li class="page-item">
									<a class="page-link" href="#">
										2
									</a>
								</li>
								<li class="page-item">
									<a class="page-link" href="#">
										3
									</a>
								</li>
								<li class="page-item">
									<a class="page-link" href="#" aria-label="Next">
										<span aria-hidden="true">&raquo;</span>
									</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</section>
			{/* ============================== */}
			{/* Blog component */}
			<Blogsmain />
			{/* ============ */}

			{/* News Letter Componenet */}
			<Newsletter />
			{/* ================ */}
       <Footer/>
    </>
  )
}

export default Newfashioncollection
