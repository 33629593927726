import React from "react";
import Footer from "../../Components/Footer";
import SellerHeader from "../../Components/SellerHeader";
import SellerSidebar from "../../Components/SellerSidebar";
import SellerProfile from "./SellerProfile";

function Membershipplan() {
  return (
    <>
      <SellerHeader />
      {/* Seller payment details here */}
      <section className="seller-dashboard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 ps-0">
              <SellerSidebar />
            </div>
            <div className="col-lg-8">
              <div className="edit-profile-card">
                <div className="edit-heading-wrapper">
                  <h2>Membership Plan</h2>
                </div>
                <div className="row membership-plan">
                  <div className="col-md-6">
                    <div className="all-subscription-plan">
                      <div className="all-subscription-head">
                        <h3>My Subscribtion</h3>
                      </div>
                      <div className="all-amount-paid all-amout-outer-paid">
                        <span>
                          {" "}
                          <h5>My Membership </h5>
                        </span>
                        <span>
                          {" "}
                          <h5>Amount Paid</h5>
                        </span>
                      </div>

                      <div className="all-professional-plan">
                        <span className="for-color-and-text-captalize">
                          <p>professional plan</p>
                        </span>
                        <span>
                          <p>$750/Per Month</p>
                        </span>
                      </div>

                      <div className="all-professioanl-cancel">
                        <span className="cancel-btn">
                          {" "}
                          <p>
                            {" "}
                            <i class="fa fa-times" aria-hidden="true"></i>
                          </p>
                          <p>Cancel Membership</p>
                        </span>
                        <span>
                          {" "}
                          <p>Your Membership Will Expire In 28 Days</p>
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="upgrade-btn">
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          Upgrade
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="getting-dv">
                      <div className="getting-dv-head">
                        <h3>What you are getting</h3>
                      </div>

                      <div className="getting-ul">
                        <ul>
                          <li>
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span>
                              <p>
                                {" "}
                                Duis Aute Irure Dolor In Reprehenderit In
                                Voluptate Velit Esse
                              </p>
                            </span>
                          </li>
                          <li>
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span>
                              <p>
                                {" "}
                                Duis Aute Irure Dolor In Reprehenderit In
                                Voluptate Velit Esse
                              </p>
                            </span>
                          </li>
                          <li>
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span>
                              <p>
                                {" "}
                                Duis Aute Irure Dolor In Reprehenderit In
                                Voluptate Velit Esse
                              </p>
                            </span>
                          </li>
                          <li>
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span>
                              <p>
                                {" "}
                                Duis Aute Irure Dolor In Reprehenderit In
                                Voluptate Velit Esse
                              </p>
                            </span>
                          </li>
                          <li>
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span>
                              <p>
                                {" "}
                                Duis Aute Irure Dolor In Reprehenderit In
                                Voluptate Velit Esse
                              </p>
                            </span>
                          </li>
                          <li>
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span>
                              <p>
                                {" "}
                                Duis Aute Irure Dolor In Reprehenderit In
                                Voluptate Velit Esse
                              </p>
                            </span>
                          </li>
                          <li>
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span>
                              <p>
                                {" "}
                                Duis Aute Irure Dolor In Reprehenderit In
                                Voluptate Velit Esse
                              </p>
                            </span>
                          </li>
                          <li>
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span>
                              <p>
                                {" "}
                                Duis Aute Irure Dolor In Reprehenderit In
                                Voluptate Velit Esse
                              </p>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Seller payment details here */}

      {/* Membership Modal */}

      <div className="membership-modal">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
            <div className="responsive-close-icon">
                <i class="fa fa-times" aria-hidden="true" data-bs-dismiss="modal"></i>
              </div>
              <div class="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="membership-modal-head">
                      <h2>Choose Membership</h2>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="all-subscription-plan">
                      <div className="all-subscription-head">
                        <h3>My Subscribtion</h3>
                      </div>
                      <div className="all-amount-paid">
                        <span>
                          {" "}
                          <h5>My Membership </h5>
                        </span>
                        <span className="for-modal-amount">
                          {" "}
                          <h5>Amount Paid</h5>
                        </span>
                      </div>

                      <div className="all-pro-plan2">
                        <div className="row">
                          <div className="col-md-6 col-show-right-border">
                            <span className="for-color-and-text-captalize">
                              {/* <p>professional plan</p> */}
                              <div class="dropdown">
                                <button
                                  class="btn  dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  professional plan
                                </button>
                                <ul
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li>
                                    <a class="dropdown-item" href="#">
                                      basic plan
                                    </a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item" href="#">
                                      upcoming plan
                                    </a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item" href="#">
                                      professional plan
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </span>
                          </div>

                          <div className="col-md-6">
                            <span>
                              <div className="per-month-dv">
                                <p>$750/Per Month</p>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="all-professioanl-cancel">
                        <span className="cancel-btn for-member-modal-css">
                          {" "}
                          <p>
                            {" "}
                            <i class="fa fa-times" aria-hidden="true"></i>
                          </p>
                          <p>Cancel Membership</p>
                        </span>
                        <span className="for-member-modal-css">
                          {" "}
                          <p>Your Membership Will Expire In 28 Days</p>
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="upgrade-btn save-upgrade-btn">
                        <button data-bs-dismiss="modal">Save Upgrade</button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="getting-dv">
                      <div className="getting-dv-head">
                        <h3>What you are getting</h3>
                      </div>

                      <div className="getting-ul">
                        <ul>
                          <li>
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span>
                              <p>
                                {" "}
                                Duis Aute Irure Dolor In Reprehenderit In
                                Voluptate Velit Esse
                              </p>
                            </span>
                          </li>
                          <li>
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span>
                              <p>
                                {" "}
                                Duis Aute Irure Dolor In Reprehenderit In
                                Voluptate Velit Esse
                              </p>
                            </span>
                          </li>
                          <li>
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span>
                              <p>
                                {" "}
                                Duis Aute Irure Dolor In Reprehenderit In
                                Voluptate Velit Esse
                              </p>
                            </span>
                          </li>
                          <li>
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span>
                              <p>
                                {" "}
                                Duis Aute Irure Dolor In Reprehenderit In
                                Voluptate Velit Esse
                              </p>
                            </span>
                          </li>
                          <li>
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span>
                              <p>
                                {" "}
                                Duis Aute Irure Dolor In Reprehenderit In
                                Voluptate Velit Esse
                              </p>
                            </span>
                          </li>
                          <li>
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span>
                              <p>
                                {" "}
                                Duis Aute Irure Dolor In Reprehenderit In
                                Voluptate Velit Esse
                              </p>
                            </span>
                          </li>
                          <li>
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span>
                              <p>
                                {" "}
                                Duis Aute Irure Dolor In Reprehenderit In
                                Voluptate Velit Esse
                              </p>
                            </span>
                          </li>
                          <li>
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span>
                              <p>
                                {" "}
                                Duis Aute Irure Dolor In Reprehenderit In
                                Voluptate Velit Esse
                              </p>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============================== */}
      <Footer />
    </>
  );
}

export default Membershipplan;
