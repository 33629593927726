import React from "react";
import { Link } from "react-router-dom";
import { footerlogo } from "../Constant/Index";

function Footer() {
	return (
		<>
			{/* Footer Section */}
			<section className="footer-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<div className="footer-all widget1">
								<div className="footer-logo">
									<figure>
										<img
											className="img-fluid"
											alt="footer-logo-img"
											src={footerlogo}
										/>
									</figure>
								</div>
								<div className="footer-text">
									<p>
										Lorem Ipsum is simply dummy text of the printing and
										typesetting industry.Lorem Ipsum has been the industry's
										standard.
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-2">
							<div className="footer-all widget2">
								<div className="footer-head">
									<h4>Menu</h4>
								</div>
								<div className="footer-all">
									<ul>
										<li>
											<Link to="/">Home</Link>
										</li>
										<li>
											<Link to="/about-us">About Us</Link>
										</li>
										<li>
											<Link to="/contact-us">Contact Us</Link>
										</li>
										<li>
											<Link to="/career">Careers</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-md-2">
							<div className="footer-all widget2">
								<div className="footer-head">
									<h4>Category</h4>
								</div>
								<div className="footer-all">
									<ul>
										<li>
											<Link to="/product-fashion">Food shop</Link>
										</li>
										<li>
											<Link to="/new-fashion-collection">Fashion</Link>
										</li>
										<li>
											<Link to="/art-craft-product">Arts & Crafts</Link>
										</li>
										<li>
											<Link>Beauty & Personal Care</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-md-2">
							<div className="footer-all widget2">
								<div className="footer-head">
									<h4>Legal</h4>
								</div>
								<div className="footer-all">
									<ul>
										<li>
											<Link to="/faq">faq</Link>
										</li>
										<li>
											<Link to="/terms-condition">Terms & Condition</Link>
										</li>
										<li>
											<Link to="/privacy-policy">Privacy Policy</Link>
										</li>
										<li>
											<Link to="/delivery-information">Delivery Information</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="footer-all widget2">
								<div className="footer-head">
									<h4>Contact Info</h4>
								</div>
								<div className="footer-all">
									<div className="footer-address">
										<span>
											<i class="fa fa-map-marker" aria-hidden="true"></i>
										</span>
										<span>
											<p>Vack Sample acdb No.815, Kita, UK 80361</p>
										</span>
									</div>
									<div className="footer-address">
										<span>
											<i class="fa fa-phone" aria-hidden="true"></i>
										</span>
										<span>
											<p>
												<a href="tel:(+12) 345 678 9">(+12) 345 678 9</a>
											</p>
										</span>
									</div>
									<div className="footer-address">
										<span>
											<i class="fa fa-envelope" aria-hidden="true"></i>
										</span>
										<span>
											<p>
												<a href="mailto:support@domain.com">
													support@domain.com
												</a>
											</p>
										</span>
									</div>

									<div className="footer-social-link">
										<ul>
											<li>
												<Link>
													<i class="fa fa-facebook" aria-hidden="true"></i>
												</Link>
											</li>
											<li>
												<Link>
													<i class="fa fa-youtube-play" aria-hidden="true"></i>
												</Link>
											</li>
											<li>
												<Link>
													<i class="fa fa-linkedin" aria-hidden="true"></i>
												</Link>
											</li>
											<li>
												<Link>
													<i class="fa fa-twitter" aria-hidden="true"></i>
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="copy-right">
					<p>copyright © 2023 saharaeagle. all rights reserved.</p>
				</div>
			</section>
			{/* =========================== */}
		</>
	);
}

export default Footer;
