import React, { useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { myprofile } from "../../Constant/Index";

function Siderbar() {
  const [file, setFile] = useState();
	const inputRef = useRef(null);

	const handleUploadClick = () => {
		// 👇 We redirect the click event onto the hidden input element
		inputRef.current?.click();
	};

	const handleFileChange = (e) => {
		if (!e.target.files) {
			return;
		}

		setFile(e.target.files[0]);

		// 🚩 do the file upload here normally...
	};
  return (
    <>
      {/* Side bar section */}
      <div className="siderbar-tabs">
        <div className="sidebar-img">
          <img className="img-fluid" src={myprofile} />
          {/* 👇 Notice the `display: hidden` on the input */}
          <input
						type="file"
						ref={inputRef}
						onChange={handleFileChange}
						style={{ display: "none" }}
					/>
          		<div className="side-abr-icon" onClick={handleUploadClick}>
				    <p>Change Picture</p>
						{/* {file ? `${file.name}` : ""} */}
					</div>
        </div>
        <ul className="sidebar-ul">
          <li>
            <NavLink to="/my-profile">My Profile</NavLink>
          </li>
          <li>
            <NavLink to="/change-password">Change Password</NavLink>
          </li>
          <li>
            <NavLink to="/my-orders">My Orders</NavLink>
          </li>

          <li>
            <Link to="/sign-in">Logout</Link>
          </li>
        </ul>
      </div>
      {/* ================ */}
    </>
  );
}

export default Siderbar;
