import React from "react";
import { Link } from "react-router-dom";
import { secondlogo } from "../../Constant/Index";

function Enterotp() {
  return (
    <>
      {/* Enter Email Section */}
      <section className="login-sec sign-in-sec enter-otp-sec">
        <div className="container">
          <div className="row">
          <div className="login-page-logo">
                  <Link to="/">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="login-page-logo"
                        src={secondlogo}
                      />
                    </figure>
                  </Link>
                </div>
            <div className="col-md-6">
            <div className="div-for-all-movement">
            
                <div className="enter-email-movement enter-otp-res">
                  <div className="welcome-back-sec">
                    <h3>Welcome Back!</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce laoreet mauris sit amet venenatis cursus. Fusce quis
                      libero orci. Donec posuere lacus id felis hendrerit
                      dapibus. Mauris
                    </p>
                    <div className="login-signin-btn">
                      <Link to="/sign-in">Sign in</Link>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            <div className="col-md-6">
              <div className="login-form">
                <form>
                  <div className="row">
                    <div className="form-head">
                      <h3>Enter Your OTP</h3>
                      <p>Please Enter The Confirmation Code</p>
                    </div>
                    <div className="col-md-2">
                      <div className="form-login-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-login-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-login-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-login-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-login-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-login-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="enter-email-para">
                        <p>
                          Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing
                          Elit. Fusce Laoreet Mauris Sit Amet Venenatis Cursus.
                        </p>
                      </div>
                    </div>

                    <div className="login-signup-btn">
                      <Link to="/create-new-password">Send</Link>
                    </div>
                    <div className="send-again-btn">
                      <button>Send Again</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
           
          </div>
        </div>
      </section>
      {/* ================ */}
    </>
  );
}

export default Enterotp;
