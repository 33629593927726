import React from "react";
import { Link } from "react-router-dom";
import SellerHeader from "../../Components/SellerHeader";
import SellerSidebar from "../../Components/SellerSidebar";
import { visaimg } from "../../Constant/Index";
import Footer from "../../Components/Footer";

function Paymentdetail() {
  return (
    <>
      <SellerHeader />
      {/* Seller payment details here */}
      <section className="seller-dashboard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 ps-0">
              <SellerSidebar />
            </div>
            <div className="col-lg-8">
              <div className="edit-profile-card">
                <div className="edit-heading-wrapper">
                  <h2>Payment Details</h2>
                </div>
                <div className="seller-edit-with-border-dv">
                  <div className="visa-img">
                    <div className="my-all-address">
                      <div className="saved-address">
                        {/* <!-- Saved Address --> */}
                        <label className="container">
                          <input type="radio" defaultChecked name="radio" />
                          <span className="checkmark">
                            <div className="select-address">
                              <div className="seller-delete">
                                <span className="selection-delete">
                                  {" "}
                                  <i
                                    class="fa fa-trash-o"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </div>
                              <div className="selection">
                                <div className="seller-check">
                                  <span className="selection-check">
                                    {" "}
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </div>
                                <div className="seller-default">
                                  <button>Default</button>
                                </div>
                              </div>
                              <div className="radio-btn">
                                <span></span>
                              </div>
                              <div className="address-detail">
                                <div className="for-flex-the-things">
                                  <div className="visa-img">
                                    <figure>
                                      <img
                                        className="img-fluid"
                                        alt="visa-img"
                                        src={visaimg}
                                      />
                                    </figure>
                                  </div>
                                  <div className="visa-para">
                                    <h5>My Personal Card</h5>
                                    <p>****************1239</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                        </label>
                        {/* <!-- Saved Address --> */}
                      </div>
                    </div>
                    <div className="my-all-address">
                      <div className="saved-address">
                        {/* <!-- Saved Address --> */}
                        <label className="container">
                          <input type="radio" defaultChecked name="radio" />
                          <span className="checkmark">
                            <div className="select-address">
                              <div className="seller-delete">
                                <span className="selection-delete">
                                  {" "}
                                  <i
                                    class="fa fa-trash-o"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </div>
                              <div className="selection">
                                <div className="seller-check">
                                  <span className="selection-check">
                                    {" "}
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </div>
                                <div className="seller-default">
                                  <button>Default</button>
                                </div>
                              </div>
                              <div className="radio-btn">
                                <span></span>
                              </div>
                              <div className="address-detail">
                                <div className="for-flex-the-things">
                                  <div className="visa-img">
                                    <figure>
                                      <img
                                        className="img-fluid"
                                        alt="visa-img"
                                        src={visaimg}
                                      />
                                    </figure>
                                  </div>
                                  <div className="visa-para">
                                    <h5>My Personal Card</h5>
                                    <p>****************1239</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                        </label>
                        {/* <!-- Saved Address --> */}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="custom-radio">
                        <p>
                          <input
                            type="radio"
                            id="test1"
                            name="radio-group"
                            checked
                          />
                          <label for="test1">Add New Card</label>
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Payment Page Inputs */}
                  <div className="row">
                    <div className="payment-head">
                      <h3>payment page</h3>
                    </div>
                    <div className="col-md-6">
                      <div className="payment-input">
                        <input type="text" placeholder="Credit Card Number" />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="payment-input">
                        <input type="text" placeholder="MM/YY" />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="payment-input">
                        <input type="text" placeholder="CVV" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="payment-btn">
                        <button>Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Seller payment details here */}

     {/* Footer section */}
     <Footer />
    </>
  );
}

export default Paymentdetail;
