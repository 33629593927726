import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import {
  homecategories1,
  homecategories2,
  homecategories3,
  homecategories4,
} from "../Constant/Index";

function Topcategories() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <>
      <section className="top-categories-sec-all wow animate__animated animate__lightSpeedInRight">
        <div className="container">
          <div className="top-categories-head">
            <h4>top categories</h4>
            <h4>view all</h4>
          </div>
          {/* <div className="row">
            <div className="col-md-3">
              <div className="top-cat-img">
                <div className="categories-main-img">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="top-img"
                      src={homecategories1}
                    />
                  </figure>
                  <div className="text-for-movement-in-categories">
                    <div className="top-categories-sec">
                      <h5>food items</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="top-cat-img">
                <div className="categories-main-img">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="top-img"
                      src={homecategories2}
                    />
                  </figure>
                  <div className="text-for-movement-in-categories">
                    <div className="top-categories-sec">
                      <h5>fashion</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="top-cat-img">
                <div className="categories-main-img">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="top-img"
                      src={homecategories3}
                    />
                  </figure>
                  <div className="text-for-movement-in-categories">
                    <div className="top-categories-sec">
                      <h5>arts & crafts</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="top-cat-img">
                <div className="categories-main-img">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="top-img"
                      src={homecategories4}
                    />
                  </figure>
                  <div className="text-for-movement-in-categories">
                    <div className="top-categories-sec">
                      <h5>customizable products</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <Slider {...settings}>
            <div>
             <Link to="/product-fashion">
             <div className="top-cat-img">
                <div className="categories-main-img">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="top-img"
                      src={homecategories1}
                    />
                  </figure>
                  <div className="text-for-movement-in-categories">
                    <div className="top-categories-sec">
                      <h5>food items</h5>
                    </div>
                  </div>
                </div>
              </div>
             </Link>
            </div>
            <div className="top-cat-img">
            <Link to="/new-fashion-collection">
            <div className="categories-main-img">
                <figure>
                  <img
                    className="img-fluid"
                    alt="top-img"
                    src={homecategories2}
                  />
                </figure>
                <div className="text-for-movement-in-categories">
                  <div className="top-categories-sec">
                    <h5>fashion</h5>
                  </div>
                </div>
              </div>
            </Link>
            </div>
            <div className="top-cat-img">
           <Link to="/art-craft-product">
           <div className="categories-main-img">
                <figure>
                  <img
                    className="img-fluid"
                    alt="top-img"
                    src={homecategories3}
                  />
                </figure>
                <div className="text-for-movement-in-categories">
                  <div className="top-categories-sec">
                    <h5>arts & crafts</h5>
                  </div>
                </div>
              </div>
           </Link>
            </div>
            <div className="top-cat-img">
           <Link to="/customize-product">
           <div className="categories-main-img">
                <figure>
                  <img
                    className="img-fluid"
                    alt="top-img"
                    src={homecategories4}
                  />
                </figure>
                <div className="text-for-movement-in-categories">
                  <div className="top-categories-sec">
                    <h5>customizable products</h5>
                  </div>
                </div>
              </div>
           </Link>
            </div>
            <div className="top-cat-img">
            <Link>
            <div className="categories-main-img">
                <figure>
                  <img
                    className="img-fluid"
                    alt="top-img"
                    src={homecategories1}
                  />
                </figure>
                <div className="text-for-movement-in-categories">
                  <div className="top-categories-sec">
                    <h5>food items</h5>
                  </div>
                </div>
              </div>
            </Link>
            </div>
            <div className="top-cat-img">
              <Link>
              <div className="categories-main-img">
                <figure>
                  <img
                    className="img-fluid"
                    alt="top-img"
                    src={homecategories2}
                  />
                </figure>
                <div className="text-for-movement-in-categories">
                  <div className="top-categories-sec">
                    <h5>fashion</h5>
                  </div>
                </div>
              </div>
              </Link>
            </div>
            <div className="top-cat-img">
            <Link>
            <div className="categories-main-img">
                <figure>
                  <img
                    className="img-fluid"
                    alt="top-img"
                    src={homecategories3}
                  />
                </figure>
                <div className="text-for-movement-in-categories">
                  <div className="top-categories-sec">
                    <h5>arts & crafts</h5>
                  </div>
                </div>
              </div>
            </Link>
            </div>
            <div className="top-cat-img">
            <Link>
            <div className="categories-main-img">
                <figure>
                  <img
                    className="img-fluid"
                    alt="top-img"
                    src={homecategories4}
                  />
                </figure>
                <div className="text-for-movement-in-categories">
                  <div className="top-categories-sec">
                    <h5>customizable products</h5>
                  </div>
                </div>
              </div>
            </Link>
            </div>
          </Slider>
        </div>
      </section>
    </>
  );
}

export default Topcategories;
