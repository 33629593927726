import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Blogsmain from "../../Components/Blogsmain";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Newsletter from "../../Components/Newsletter";
import Slidecompo from "../../Components/Slidecompo";
import Topcategories from "../../Components/Topcategories";
import {
  art1,
  art2,
  art3,
  art4,
  art5,
  arts,
  customize,
  customize1,
  customize2,
  customize3,
  customize4,
  customize5,
  customize6,
  fashion,
  fashion1,
  fashion2,
  fashion3,
  fashion4,
  fashion5,
  fashion6,
  homebanner1,
  homebanner2,
  homebanner3,
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
  organic,
  organic1,
  organic2,
  organic3,
  slider1,
  slider4,
} from "../../Constant/Index";

function Home() {
  return (
    <>
      <Header />

      {/* Main Banner Section */}
      <section className="main-banner-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="banner-first-img firts-banner-img ">
                <figure>
                  <img
                    className="img-fluid"
                    alt="first-img"
                    src={homebanner1}
                  />
                </figure>

                <div className="first-banner-text wow animate__animated animate__fadeInDown">
                  <div className="all-text">
                    <h2>up to</h2>
                    <h2>
                      50% <span>discount</span>
                    </h2>
                    <h3>Organic & Fresh Food</h3>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been...
                    </p>
                    <div className="main-btn">
                      <Link to="/product-fashion">Shop Now</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="banner-first-img second-banner-img">
                <figure>
                  <img
                    className="img-fluid"
                    alt="first-img"
                    src={homebanner2}
                  />
                </figure>
                <div className="second-banner-text">
                  <div className="second-all-text wow animate__animated animate__fadeInRightBig">
                    <h4>Top pick</h4>
                    <h4>In popular Arts & Crafts</h4>
                  </div>
                </div>
              </div>
              <div className="banner-first-img third-banner-img">
                <figure>
                  <img
                    className="img-fluid"
                    alt="first-img"
                    src={homebanner3}
                  />
                </figure>
                <div className="second-banner-text third-banner-text">
                  <div className="second-all-text wow animate__animated animate__fadeInRightBig">
                    <h4>UP TO 50%</h4>
                    <h4>Discount on Fashion </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ================================ */}

      {/* Different Icons Seciton */}
      <section className="differnt-sec ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="all-icons-in-one-dv">
                <div className="icon-with-text">
                  <figure>
                    <img className="img-fluid" alt="i-dv" src={icon1} />
                  </figure>
                  <div className="my-text">
                    <h5>free delivery</h5>
                    <p>For all orders over $30</p>
                  </div>
                </div>

                <div className="icon-with-text">
                  <figure>
                    <img className="img-fluid" alt="i-dv" src={icon2} />
                  </figure>
                  <div className="my-text">
                    <h5>90 days return</h5>
                    <p>If goods have problems</p>
                  </div>
                </div>
                <div className="icon-with-text">
                  <figure>
                    <img className="img-fluid" alt="i-dv" src={icon4} />
                  </figure>
                  <div className="my-text">
                    <h5>100% Secure Payment</h5>
                    <p>Guarantee Secure Payments</p>
                  </div>
                </div>
                <div className="icon-with-text">
                  <figure>
                    <img className="img-fluid" alt="i-dv" src={icon5} />
                  </figure>
                  <div className="my-text">
                    <h5>24/7 Dedicated Support</h5>
                    <p>Anywhere & anytime</p>
                  </div>
                </div>
                <div className="icon-with-text">
                  <figure>
                    <img className="img-fluid" alt="i-dv" src={icon3} />
                  </figure>
                  <div className="my-text">
                    <h5>Daily Offers</h5>
                    <p>Discount up to 70 % Off</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======================== */}

      {/* Top categories */}
      <Topcategories />
      {/* =========== */}

      {/* Slider  */}
      <Slidecompo />
      {/* ========= */}

      {/* Organic And Fresh Food Section */}
      <section className="oraganic-food-sec">
        <div className="container">
          <div className="organic-all">
            <div className="row">
              <div className="col-md-4 for-back-color-and-raduis">
                <div className="background-head">
                  <h3>Organic & Fresh Food</h3>
                </div>
                <div className="organic-img">
                  <figure>
                    <img
                      className="img=fluid"
                      alt="organic-pic"
                      src={organic}
                    />
                  </figure>
                </div>
              </div>
              <div className="col-md-8">
                <div className="row all-class-for-padding">
                  <div className="col-md-4">
                    <Link to="/product-fashion">
                      <div className="slide-dv organic-food">
                        <figure>
                          <img
                            className="img-fluid"
                            alt="slid-1"
                            src={slider1}
                          />
                        </figure>
                        <div className="slide-text">
                          <p>Lorem Ipsum is simply </p>
                          <div className="slide-for-flex">
                            <h5>$567.89</h5>
                            <div className="slide-star">
                              <ul className="slide-star-ul">
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="product-heading">
                          <div className="pro-head">
                            <h5>Food</h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/product-fashion">
                      <div className="slide-dv organic-food">
                        <figure>
                          <img
                            className="img-fluid"
                            alt="slid-1"
                            src={organic1}
                          />
                        </figure>
                        <div className="slide-text">
                          <p>Lorem Ipsum is simply </p>
                          <div className="slide-for-flex">
                            <h5>$567.89</h5>
                            <div className="slide-star">
                              <ul className="slide-star-ul">
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="product-heading">
                          <div className="pro-head">
                            <h5>Food</h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/product-fashion">
                      <div className="slide-dv organic-food">
                        <figure>
                          <img
                            className="img-fluid"
                            alt="slid-1"
                            src={organic2}
                          />
                        </figure>
                        <div className="slide-text">
                          <p>Lorem Ipsum is simply </p>
                          <div className="slide-for-flex">
                            <h5>$567.89</h5>
                            <div className="slide-star">
                              <ul className="slide-star-ul">
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="product-heading">
                          <div className="pro-head">
                            <h5>Food</h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/product-fashion">
                      <div className="slide-dv organic-food">
                        <figure>
                          <img
                            className="img-fluid"
                            alt="slid-1"
                            src={slider4}
                          />
                        </figure>
                        <div className="slide-text">
                          <p>Lorem Ipsum is simply </p>
                          <div className="slide-for-flex">
                            <h5>$567.89</h5>
                            <div className="slide-star">
                              <ul className="slide-star-ul">
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="product-heading">
                          <div className="pro-head">
                            <h5>Food</h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/product-fashion">
                      <div className="slide-dv organic-food">
                        <figure>
                          <img
                            className="img-fluid"
                            alt="slid-1"
                            src={slider1}
                          />
                        </figure>
                        <div className="slide-text">
                          <p>Lorem Ipsum is simply </p>
                          <div className="slide-for-flex">
                            <h5>$567.89</h5>
                            <div className="slide-star">
                              <ul className="slide-star-ul">
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="product-heading">
                          <div className="pro-head">
                            <h5>Food</h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/product-fashion">
                      <div className="slide-dv organic-food">
                        <figure>
                          <img
                            className="img-fluid"
                            alt="slid-1"
                            src={organic3}
                          />
                        </figure>
                        <div className="slide-text">
                          <p>Lorem Ipsum is simply </p>
                          <div className="slide-for-flex">
                            <h5>$567.89</h5>
                            <div className="slide-star">
                              <ul className="slide-star-ul">
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="product-heading">
                          <div className="pro-head">
                            <h5>Food</h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============== */}

      {/* Fashion Asscerioes Section */}
      <section className="oraganic-food-sec fashion-accessories">
        <div className="container">
          <div className="organic-all">
            <div className="row">
              <div className="col-md-4 for-back-red">
                <div className="background-head">
                  <h3>Fashion & Accessories</h3>
                </div>
                <div className="organic-img">
                  <figure>
                    <img
                      className="img=fluid"
                      alt="organic-pic"
                      src={fashion}
                    />
                  </figure>
                </div>
              </div>
              <div className="col-md-8">
                <div className="row all-class-for-padding">
                  <div className="col-md-4">
                    <Link to="/new-fashion-collection">
                      <div className="slide-dv organic-food fashion-dv">
                        <figure>
                          <img
                            className="img-fluid"
                            alt="slid-1"
                            src={fashion1}
                          />
                        </figure>
                        <div className="slide-text">
                          <p>Lorem Ipsum is simply </p>
                          <div className="slide-for-flex">
                            <h5>$567.89</h5>
                            <div className="slide-star">
                              <ul className="slide-star-ul">
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="product-heading">
                          <div className="pro-head for-fashion-accessories">
                            <h5>Fashion & Accessories</h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/new-fashion-collection">
                      <div className="slide-dv organic-food fashion-dv">
                        <figure>
                          <img
                            className="img-fluid"
                            alt="slid-1"
                            src={fashion2}
                          />
                        </figure>
                        <div className="slide-text">
                          <p>Lorem Ipsum is simply </p>
                          <div className="slide-for-flex">
                            <h5>$567.89</h5>
                            <div className="slide-star">
                              <ul className="slide-star-ul">
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="product-heading">
                          <div className="pro-head for-fashion-accessories">
                            <h5>Fashion & Accessories</h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/new-fashion-collection">
                      <div className="slide-dv organic-food fashion-dv">
                        <figure>
                          <img
                            className="img-fluid"
                            alt="slid-1"
                            src={fashion3}
                          />
                        </figure>
                        <div className="slide-text">
                          <p>Lorem Ipsum is simply </p>
                          <div className="slide-for-flex">
                            <h5>$567.89</h5>
                            <div className="slide-star">
                              <ul className="slide-star-ul">
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="product-heading">
                          <div className="pro-head for-fashion-accessories">
                            <h5>Fashion & Accessories</h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/new-fashion-collection">
                      <div className="slide-dv organic-food fashion-dv">
                        <figure>
                          <img
                            className="img-fluid"
                            alt="slid-1"
                            src={fashion4}
                          />
                        </figure>
                        <div className="slide-text">
                          <p>Lorem Ipsum is simply </p>
                          <div className="slide-for-flex">
                            <h5>$567.89</h5>
                            <div className="slide-star">
                              <ul className="slide-star-ul">
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="product-heading">
                          <div className="pro-head for-fashion-accessories">
                            <h5>Fashion & Accessories</h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/new-fashion-collection">
                      <div className="slide-dv organic-food fashion-dv">
                        <figure>
                          <img
                            className="img-fluid"
                            alt="slid-1"
                            src={fashion5}
                          />
                        </figure>
                        <div className="slide-text">
                          <p>Lorem Ipsum is simply </p>
                          <div className="slide-for-flex">
                            <h5>$567.89</h5>
                            <div className="slide-star">
                              <ul className="slide-star-ul">
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="product-heading">
                          <div className="pro-head for-fashion-accessories">
                            <h5>Fashion & Accessories</h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/new-fashion-collection">
                      <div className="slide-dv organic-food fashion-dv">
                        <figure>
                          <img
                            className="img-fluid"
                            alt="slid-1"
                            src={fashion6}
                          />
                        </figure>
                        <div className="slide-text">
                          <p>Lorem Ipsum is simply </p>
                          <div className="slide-for-flex">
                            <h5>$567.89</h5>
                            <div className="slide-star">
                              <ul className="slide-star-ul">
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                                <li>
                                  {" "}
                                  <i class="fa fa-star" aria-hidden="true" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="product-heading">
                          <div className="pro-head for-fashion-accessories">
                            <h5>Fashion & Accessories</h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============== */}

      {/* Art and Craft */}
      <section className="oraganic-food-sec arts-and-craft ">
        <div className="container">
          <div className="organic-all">
            <div className="row">
              <div className="col-md-4 for-back-blue">
                <div className="background-head">
                  <h3>Arts & Crafts</h3>
                </div>
                <div className="organic-img air-craft-img">
                  <figure>
                    <img className="img=fluid" alt="organic-pic" src={arts} />
                  </figure>
                </div>
              </div>
              <div className="col-md-8">
                <div className="row all-class-for-padding">
                  <div className="col-md-4">
              <Link to="/art-craft-product">
              <div className="slide-dv organic-food air-craft-dv">
                      <figure>
                        <img className="img-fluid" alt="slid-1" src={art1} />
                      </figure>
                      <div className="slide-text">
                        <p>Lorem Ipsum is simply </p>
                        <div className="slide-for-flex">
                          <h5>$567.89</h5>
                          <div className="slide-star">
                            <ul className="slide-star-ul">
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="product-heading">
                        <div className="pro-head for-fashion-accessories">
                          <h5>Arts & Crafts</h5>
                        </div>
                      </div>
                    </div>
              </Link>
                  </div>
                  <div className="col-md-4">
                  <Link to="/art-craft-product">
                  <div className="slide-dv organic-food air-craft-dv">
                      <figure>
                        <img className="img-fluid" alt="slid-1" src={art2} />
                      </figure>
                      <div className="slide-text">
                        <p>Lorem Ipsum is simply </p>
                        <div className="slide-for-flex">
                          <h5>$567.89</h5>
                          <div className="slide-star">
                            <ul className="slide-star-ul">
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="product-heading">
                        <div className="pro-head for-fashion-accessories">
                          <h5>Arts & Crafts</h5>
                        </div>
                      </div>
                    </div>
                  </Link>
                  </div>
                  <div className="col-md-4">
                   <Link to="/art-craft-product">
                   <div className="slide-dv organic-food air-craft-dv">
                      <figure>
                        <img className="img-fluid" alt="slid-1" src={art3} />
                      </figure>
                      <div className="slide-text">
                        <p>Lorem Ipsum is simply </p>
                        <div className="slide-for-flex">
                          <h5>$567.89</h5>
                          <div className="slide-star">
                            <ul className="slide-star-ul">
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="product-heading">
                        <div className="pro-head for-fashion-accessories">
                          <h5>Arts & Crafts</h5>
                        </div>
                      </div>
                    </div>
                   </Link>
                  </div>
                  <div className="col-md-4">
                  <Link to="/art-craft-product">
                  <div className="slide-dv organic-food air-craft-dv">
                      <figure>
                        <img className="img-fluid" alt="slid-1" src={art4} />
                      </figure>
                      <div className="slide-text">
                        <p>Lorem Ipsum is simply </p>
                        <div className="slide-for-flex">
                          <h5>$567.89</h5>
                          <div className="slide-star">
                            <ul className="slide-star-ul">
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="product-heading">
                        <div className="pro-head for-fashion-accessories">
                          <h5>Arts & Crafts</h5>
                        </div>
                      </div>
                    </div>
                  </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/art-craft-product">
                    <div className="slide-dv organic-food air-craft-dv">
                      <figure>
                        <img className="img-fluid" alt="slid-1" src={art5} />
                      </figure>
                      <div className="slide-text">
                        <p>Lorem Ipsum is simply </p>
                        <div className="slide-for-flex">
                          <h5>$567.89</h5>
                          <div className="slide-star">
                            <ul className="slide-star-ul">
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="product-heading">
                        <div className="pro-head for-fashion-accessories">
                          <h5>Arts & Crafts</h5>
                        </div>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/art-craft-product">
                    <div className="slide-dv organic-food air-craft-dv">
                      <figure>
                        <img className="img-fluid" alt="slid-1" src={art1} />
                      </figure>
                      <div className="slide-text">
                        <p>Lorem Ipsum is simply </p>
                        <div className="slide-for-flex">
                          <h5>$567.89</h5>
                          <div className="slide-star">
                            <ul className="slide-star-ul">
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="product-heading">
                        <div className="pro-head for-fashion-accessories">
                          <h5>Arts & Crafts</h5>
                        </div>
                      </div>
                    </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============== */}

      {/* Art and Craft */}
      <section className="oraganic-food-sec customizable-home-sec ">
        <div className="container">
          <div className="organic-all">
            <div className="row">
              <div className="col-md-4 for-back-yellow">
                <div className="background-head">
                  <h3>Customizable Products</h3>
                </div>
                <div className="organic-img customizable-home-img">
                  <figure>
                    <img
                      className="img=fluid"
                      alt="organic-pic"
                      src={customize}
                    />
                  </figure>
                </div>
              </div>
              <div className="col-md-8">
                <div className="row all-class-for-padding">
                  <div className="col-md-4">
                <Link to="/customize-product">
                <div className="slide-dv organic-food customizable-dv">
                      <figure>
                        <img
                          className="img-fluid"
                          alt="slid-1"
                          src={customize1}
                        />
                      </figure>
                      <div className="slide-text">
                        <p>Lorem Ipsum is simply </p>
                        <div className="slide-for-flex">
                          <h5>$567.89</h5>
                          <div className="slide-star">
                            <ul className="slide-star-ul">
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="product-heading">
                        <div className="pro-head for-fashion-accessories">
                          <h5>Customizable Products</h5>
                        </div>
                      </div>
                    </div>
                </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/customize-product">
                    <div className="slide-dv organic-food customizable-dv">
                      <figure>
                        <img
                          className="img-fluid"
                          alt="slid-1"
                          src={customize2}
                        />
                      </figure>
                      <div className="slide-text">
                        <p>Lorem Ipsum is simply </p>
                        <div className="slide-for-flex">
                          <h5>$567.89</h5>
                          <div className="slide-star">
                            <ul className="slide-star-ul">
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="product-heading">
                        <div className="pro-head for-fashion-accessories">
                          <h5>Customizable Products</h5>
                        </div>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                   <Link to="/customize-product">
                   <div className="slide-dv organic-food customizable-dv">
                      <figure>
                        <img
                          className="img-fluid"
                          alt="slid-1"
                          src={customize3}
                        />
                      </figure>
                      <div className="slide-text">
                        <p>Lorem Ipsum is simply </p>
                        <div className="slide-for-flex">
                          <h5>$567.89</h5>
                          <div className="slide-star">
                            <ul className="slide-star-ul">
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="product-heading">
                        <div className="pro-head for-fashion-accessories">
                          <h5>Customizable Products</h5>
                        </div>
                      </div>
                    </div>
                   </Link>
                  </div>
                  <div className="col-md-4">
                    <Link to="/customize-product">
                    <div className="slide-dv organic-food customizable-dv">
                      <figure>
                        <img
                          className="img-fluid"
                          alt="slid-1"
                          src={customize4}
                        />
                      </figure>
                      <div className="slide-text">
                        <p>Lorem Ipsum is simply </p>
                        <div className="slide-for-flex">
                          <h5>$567.89</h5>
                          <div className="slide-star">
                            <ul className="slide-star-ul">
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="product-heading">
                        <div className="pro-head for-fashion-accessories">
                          <h5>Customizable Products</h5>
                        </div>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                   <Link to="/customize-product">
                   <div className="slide-dv organic-food customizable-dv">
                      <figure>
                        <img
                          className="img-fluid"
                          alt="slid-1"
                          src={customize5}
                        />
                      </figure>
                      <div className="slide-text">
                        <p>Lorem Ipsum is simply </p>
                        <div className="slide-for-flex">
                          <h5>$567.89</h5>
                          <div className="slide-star">
                            <ul className="slide-star-ul">
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="product-heading">
                        <div className="pro-head for-fashion-accessories">
                          <h5>Customizable Products</h5>
                        </div>
                      </div>
                    </div>
                   </Link>
                  </div>
                  <div className="col-md-4">
                   <Link to="/customize-product">
                   <div className="slide-dv organic-food customizable-dv">
                      <figure>
                        <img
                          className="img-fluid"
                          alt="slid-1"
                          src={customize6}
                        />
                      </figure>
                      <div className="slide-text">
                        <p>Lorem Ipsum is simply </p>
                        <div className="slide-for-flex">
                          <h5>$567.89</h5>
                          <div className="slide-star">
                            <ul className="slide-star-ul">
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                              <li>
                                {" "}
                                <i class="fa fa-star" aria-hidden="true" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="product-heading">
                        <div className="pro-head for-fashion-accessories">
                          <h5>Customizable Products</h5>
                        </div>
                      </div>
                    </div>
                   </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============== */}
      {/* Blog component */}
      <Blogsmain />
      {/* ============ */}

      {/* News Letter Componenet */}
      <Newsletter />
      {/* ================ */}

      {/* Footer */}
      <Footer />
      {/* =========== */}
    </>
  );
}

export default Home;
