import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/login.css";
import { secondlogo } from "../../Constant/Index";
function Login() {
  return (
    <>
      {/* Login Section */}
      <section className="login-sec sign-up-sec">
        <div className="container">
          <div className="row">
          <div className="login-page-logo">
                  <Link to="/">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="login-page-logo"
                        src={secondlogo}
                      />
                    </figure>
                  </Link>
                </div>
            <div className="col-md-6">
              <div className="div-for-all-movement">
              
                <div className="welcome-back-movement sign-up-all">
                  <div className="welcome-back-sec">
                    <h3>Welcome Back!</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce laoreet mauris sit amet venenatis cursus. Fusce quis
                      libero orci. Donec posuere lacus id felis hendrerit
                      dapibus. Mauris
                    </p>
                    <div className="login-signin-btn">
                      <Link to="/sign-in">Sign in</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="login-form">
                <form>
                  <div className="row">
                    <div className="form-head">
                      <h3>User Sign Up</h3>
                    </div>
                    <div className="col-md-6">
                      <div className="form-login-input">
                        <input type="text" placeholder="First Name" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-login-input">
                        <input type="text" placeholder="Last Name" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-login-input">
                        <input type="email" placeholder="Email" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-login-input">
                        <input type="text" placeholder="Phone" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-login-input">
                        <input type="password" placeholder="Password" />
                        <div className="password-icon">
                          <i class="fa fa-eye-slash" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-login-input">
                        <input type="password" placeholder="Confirm Password" />
                        <div className="password-icon">
                          <i class="fa fa-eye-slash" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                    <div className="login-signup-btn">
                      <Link>Sign Up</Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-12"></div>
          </div>
        </div>
      </section>
      {/* ================ */}
    </>
  );
}

export default Login;
