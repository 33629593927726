import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import Siderbar from "./Siderbar";

function Changepassword() {
  return (
    <>
      <Header />
      <PageHeader pagename="Password" />
      {/* Edit Profle Seciton */}
      <section className="edit-profile-sec my-profile-all">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <Siderbar />
            </div>
            <div className="col-md-10">
              <div className="all-edit-screen">
                <div className="row">
                  <div className="col-md-10">
                    <form>
                      <div className="row">
                        <label>Password</label>
                        <div className="col-md-6">
                          <div className="edit-profile-input">
                            <input
                              type="password"
                              placeholder="************"
                            />
                            <div className="icon-of-edit-profile">
                              <i class="fa fa-key" aria-hidden="true"></i>
                            </div>
                            <div className="icon-eye">
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-2">
                    <div className="profile-edit-btn">
                      <Link to="/new-password">Edit</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ */}
      <Footer />
    </>
  );
}

export default Changepassword;
