import React from "react";
import "../../assets/css/faq.css";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";

function Faq() {
  return (
    <>
      <Header />
      <PageHeader pagename="FAQ's" />
      {/* Faq section */}
      <section className="faq-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="faq-all">
                <div
                  class="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        <h5>
                          {" "}
                          Lorem Ipsum is simply dummy text of the printing?
                        </h5>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        <h5>
                          {" "}
                          Lorem Ipsum is simply dummy text of the printing?
                        </h5>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        <h5>
                          {" "}
                          Lorem Ipsum is simply dummy text of the printing?
                        </h5>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.{" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingfour">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapsefour"
                        aria-expanded="false"
                        aria-controls="flush-collapsefour"
                      >
                        <h5>
                          {" "}
                          Lorem Ipsum is simply dummy text of the printing?
                        </h5>
                      </button>
                    </h2>
                    <div
                      id="flush-collapsefour"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingfour"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.{" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingfive">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapsefive"
                        aria-expanded="false"
                        aria-controls="flush-collapsefive"
                      >
                        <h5>
                          {" "}
                          Lorem Ipsum is simply dummy text of the printing?
                        </h5>
                      </button>
                    </h2>
                    <div
                      id="flush-collapsefive"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingfive"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                         
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingsix">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapsesix"
                        aria-expanded="false"
                        aria-controls="flush-collapsesix"
                      >
                        <h5>
                          {" "}
                          Lorem Ipsum is simply dummy text of the printing?
                        </h5>
                      </button>
                    </h2>
                    <div
                      id="flush-collapsesix"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingsix"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======================== */}
      <Footer />
    </>
  );
}

export default Faq;
