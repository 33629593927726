import React, { useEffect } from "react";

const PageHeader = (props) => {
	useEffect(() => {
		let currentUrl = window.location.href.split("/");
	}, []);
	return (
		<>
			{/* page header starts here */}
			<section className="pageheader">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="content-wrapper">
								<h2>{props.pagename}</h2>
								<p>
									<b className="d-block">{props.boldtext}</b>
									{props.pagepara}
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* page header ends here */}
		</>
	);
};

export default PageHeader;
