import React from "react";
import { girls, newsletter1, newsletter2 } from "../Constant/Index";

function Newsletter() {
  return (
    <>
      {/* News Letter Section */}
      <section className="news-letter-sec wow animate__animated animate__bounceIn">
        <div className="container">
          <div className="Newsletter-all">
            <div className="row">
              <div className="col-md-3">
                <div className="newsletter-img newsletter-img-for-move">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="news-letter-img"
                      src={newsletter1}
                    />
                  </figure>
                </div>
              </div>

              <div className="col-md-6">
                <div className="news-all">
                  <div className="newsletter-dv">
                    <h3>Newsletter</h3>
                    <p>Subcribe to get information about products and coupons</p>
                  </div>
                  <div className="news-letter-input">
                    <input type="text" placeholder="Email Address"/>
                   <div className="newsletter-btn">
                    <button>Subscribe</button>

                   </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="newsletter-img">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="news-letter-img-"
                      src={newsletter2}
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Newsletter;
