import React from "react";
import Footer from "../../Components/Footer";
import SellerHeader from "../../Components/SellerHeader";
import SellerSidebar from "../../Components/SellerSidebar";
import "../../assets/css/seller-dashboard.css";
import Barchart from "../../Components/Barchart";

const SellerDashboard = () => {
  return (
    <>
      {/* Seller header starts here */}
      <SellerHeader />
      {/* Seller header ends here  */}
      {/* seller dashboard starts here */}
      <section className="seller-dashboard seller-dashboard-for-background">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 ps-0">
              <SellerSidebar />
            </div>
            <div className="col-lg-8">
              <div className="order-activity-wrapper">
                <div className="order-heading-wrapper">
                  <h2>Order Activity</h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
                <div className="order-stats-wrapper">
                  <div className="order-stat-wrapper">
                    <div className="order-icon-wrapper">
                      <span className="order-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="35.391"
                          height="32.41"
                          viewBox="0 0 35.391 32.41"
                        >
                          <g
                            id="Group_14864"
                            data-name="Group 14864"
                            transform="translate(-971.609 -403)"
                          >
                            <g id="Group_14806" data-name="Group 14806">
                              <path
                                id="shopping-cart-add"
                                d="M8.38,24.458a2.717,2.717,0,0,1,2,.819,2.765,2.765,0,0,1,0,3.928,2.717,2.717,0,0,1-2,.819A2.609,2.609,0,0,1,6.417,29.2a2.848,2.848,0,0,1,0-3.928A2.609,2.609,0,0,1,8.38,24.458Zm13.978,0a2.795,2.795,0,1,1-1.964.819A2.677,2.677,0,0,1,22.359,24.458ZM8.642,19.94a.29.29,0,0,0,.328.328H25.142v2.816H8.38a2.794,2.794,0,0,1-2.815-2.816,2.31,2.31,0,0,1,.393-1.31l1.834-3.47L2.816,4.881H0V2.132H4.583l1.31,2.75L7.2,7.7l3.142,6.613.2.393H20.3L24.159,7.7l1.572-2.816,2.422,1.375-5.369,9.755A2.635,2.635,0,0,1,20.3,17.454H9.886L8.642,19.745v.2Z"
                                transform="translate(971.609 405.386)"
                                fill="#485f27"
                              />
                            </g>
                            <g
                              id="Ellipse_1675"
                              data-name="Ellipse 1675"
                              transform="translate(992 403)"
                              fill="#fff"
                              stroke="#485f27"
                              stroke-width="1"
                            >
                              <circle cx="7.5" cy="7.5" r="7.5" stroke="none" />
                              <circle cx="7.5" cy="7.5" r="7" fill="none" />
                            </g>
                            <g
                              id="Group_14805"
                              data-name="Group 14805"
                              transform="translate(1.125 1.125)"
                            >
                              <line
                                id="Line_285"
                                data-name="Line 285"
                                x2="7.749"
                                transform="translate(994.5 409.375)"
                                fill="none"
                                stroke="#485f27"
                                stroke-linecap="round"
                                stroke-width="1"
                              />
                              <line
                                id="Line_286"
                                data-name="Line 286"
                                x2="7.749"
                                transform="translate(998.375 405.5) rotate(90)"
                                fill="none"
                                stroke="#485f27"
                                stroke-linecap="round"
                                stroke-width="1"
                              />
                            </g>
                          </g>
                        </svg>
                      </span>
                    </div>
                    <div className="order-content-wrapper">
                      <h6>New Order</h6>
                      <h2>1.121</h2>
                    </div>
                  </div>
                  <div className="order-stat-wrapper">
                    <div className="order-icon-wrapper">
                      <span className="order-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="38.397"
                          height="40.033"
                          viewBox="0 0 38.397 40.033"
                        >
                          <g
                            id="Group_14866"
                            data-name="Group 14866"
                            transform="translate(962.249 98.114)"
                          >
                            <path
                              id="Path_30097"
                              data-name="Path 30097"
                              d="M-943.561,125.275h-15.59v.373c0,1.578.007,3.156-.007,4.734a.4.4,0,0,0,.335.463,2.569,2.569,0,0,1,1.759,2.759,2.607,2.607,0,0,1-2.5,2.306,2.612,2.612,0,0,1-2.6-1.995,2.569,2.569,0,0,1,1.7-3.07.408.408,0,0,0,.333-.471c-.01-1.775-.006-3.551,0-5.326,0-.667.12-.787.778-.787h15.829v-4.634c0-.092,0-.184,0-.276.008-.4.188-.637.5-.614.363.028.477.266.475.6,0,.934,0,1.868,0,2.8,0,.693,0,1.387,0,2.123h15.817c.661,0,.789.124.789.777,0,1.762.006,3.525-.005,5.287,0,.285.05.433.359.534a2.572,2.572,0,0,1,1.718,2.859,2.63,2.63,0,0,1-2.567,2.194,2.621,2.621,0,0,1-2.557-2.107,2.551,2.551,0,0,1,1.651-2.925.52.52,0,0,0,.416-.609c-.02-1.512-.009-3.025-.009-4.537v-.453h-15.587c-.01.1-.028.2-.028.3,0,1.6,0,3.209-.006,4.813a.377.377,0,0,0,.317.437,2.589,2.589,0,0,1,1.781,2.822,2.625,2.625,0,0,1-2.594,2.254,2.627,2.627,0,0,1-2.56-2.2,2.6,2.6,0,0,1,1.761-2.873c.206-.07.335-.12.333-.386-.014-1.657-.007-3.314-.009-4.971C-943.54,125.429-943.55,125.378-943.561,125.275Z"
                              transform="translate(0 -193.994)"
                              fill="#485f27"
                            />
                            <g
                              id="Group_14865"
                              data-name="Group 14865"
                              transform="translate(-953.79 -98.114)"
                            >
                              <path
                                id="Path_30098"
                                data-name="Path 30098"
                                d="M-861.1-37.43h-20.3a.922.922,0,0,1-.593-.991q.015-7.816.006-15.631v-.268h8.053v.256q0,2.511,0,5.021a.769.769,0,0,0,1.174.741q.677-.332,1.347-.678a.321.321,0,0,1,.331,0c.446.232.89.469,1.35.672a1.078,1.078,0,0,0,.58.085.733.733,0,0,0,.585-.806q0-2.521,0-5.042v-.261h7.718c.112,0,.223,0,.335.007v16.3A.994.994,0,0,1-861.1-37.43Zm-13.98-8.832h-4.864a.78.78,0,0,0-.894.882q0,2.962,0,5.924a.782.782,0,0,0,.864.877q4.885,0,9.77,0a.79.79,0,0,0,.875-.889q0-2.951,0-5.9a.783.783,0,0,0-.887-.89Zm13.031,7.291h-.367c-.112,0-.224,0-.335,0-.279.01-.45.164-.445.4a.4.4,0,0,0,.44.379q.513.008,1.026,0a.4.4,0,0,0,.451-.456c0-.336,0-.672,0-1.008,0-.283-.15-.456-.381-.459s-.383.169-.389.449C-862.053-39.446-862.049-39.223-862.049-38.97Z"
                                transform="translate(881.993 58.945)"
                                fill="#485f27"
                              />
                              <path
                                id="Path_30099"
                                data-name="Path 30099"
                                d="M-869.408-98.114c-.307,1.22-.616,2.44-.917,3.661-.033.135-.085.179-.223.179q-3.69-.006-7.38,0c-.047,0-.094-.006-.177-.012.058-.081.1-.141.143-.2.809-1.081,1.612-2.166,2.433-3.238a2.453,2.453,0,0,1,.5-.388Z"
                                transform="translate(878.515 98.114)"
                                fill="#485f27"
                              />
                              <path
                                id="Path_30100"
                                data-name="Path 30100"
                                d="M-758.93-98.114a1.22,1.22,0,0,1,.586.482c.783,1.056,1.574,2.107,2.361,3.161.037.05.071.1.125.177-.078.007-.135.018-.193.018q-3.669,0-7.339,0c-.156,0-.212-.049-.249-.2-.3-1.215-.607-2.428-.912-3.641Z"
                                transform="translate(776.928 98.114)"
                                fill="#485f27"
                              />
                              <path
                                id="Path_30101"
                                data-name="Path 30101"
                                d="M-794.546-98.114q.335,1.318.67,2.637c.1.391.194.783.3,1.19h-3.608c.177-.712.348-1.408.524-2.1.145-.575.295-1.148.442-1.722Z"
                                transform="translate(806.127 98.114)"
                                fill="#485f27"
                              />
                              <path
                                id="Path_30102"
                                data-name="Path 30102"
                                d="M-794.334-48.8l-.539-.267c-.319-.159-.639-.314-.955-.479a.827.827,0,0,0-.819,0c-.427.223-.86.432-1.292.647-.061.03-.123.057-.207.1v-5.341h3.811Z"
                                transform="translate(806.983 58.783)"
                                fill="#485f27"
                              />
                              <path
                                id="Path_30103"
                                data-name="Path 30103"
                                d="M-853.634,29.591V35.7h-9.938V29.591Zm-3.058,3.044q0,.861,0,1.723a1.649,1.649,0,0,0,.011.272.38.38,0,0,0,.379.326.377.377,0,0,0,.37-.335,1.508,1.508,0,0,0,.011-.23q0-1.05,0-2.1c0-.49,0-.98,0-1.471a.429.429,0,0,0-.21-.427c-.27-.144-.558.053-.561.394C-856.7,31.4-856.691,32.019-856.692,32.635Zm-4.594,0h0c0,.63,0,1.26,0,1.891a.386.386,0,0,0,.38.434.4.4,0,0,0,.385-.434c0-.091,0-.182,0-.273v-3.34c0-.056,0-.112,0-.168a.394.394,0,0,0-.384-.394.377.377,0,0,0-.378.394C-861.29,31.37-861.286,32-861.286,32.631Zm-1.537.036c0,.616,0,1.232,0,1.849a.391.391,0,0,0,.376.44.381.381,0,0,0,.385-.428c0-1.254,0-2.507,0-3.761a.4.4,0,0,0-.113-.3.608.608,0,0,0-.4-.085.333.333,0,0,0-.247.369Q-862.824,31.711-862.823,32.667Zm4.6-.01c0,.609,0,1.219,0,1.828,0,.3.148.475.385.47s.377-.18.377-.482q0-1.828,0-3.655c0-.294-.152-.475-.388-.472s-.373.177-.374.462C-858.219,31.425-858.218,32.041-858.218,32.657Zm3.067-.013c0,.616,0,1.233,0,1.849,0,.285.142.458.373.462s.388-.173.388-.469q0-1.838,0-3.677c0-.285-.142-.458-.373-.463s-.387.174-.388.47Q-855.153,31.73-855.152,32.644Z"
                                transform="translate(865.514 -16.131)"
                                fill="#485f27"
                              />
                            </g>
                          </g>
                        </svg>
                      </span>
                    </div>
                    <div className="order-content-wrapper">
                      <h6>Order processed</h6>
                      <h2>312</h2>
                    </div>
                  </div>
                  <div className="order-stat-wrapper">
                    <div className="order-icon-wrapper">
                      <span className="order-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="34.971"
                          height="35.029"
                          viewBox="0 0 34.971 35.029"
                        >
                          <g
                            id="Group_14867"
                            data-name="Group 14867"
                            transform="translate(1030.084 -348)"
                          >
                            <path
                              id="Path_30104"
                              data-name="Path 30104"
                              d="M-996.069,457.879h-33.049a1.5,1.5,0,0,1-.966-1.614q.024-12.725.01-25.449v-.436h13.11v.417q0,4.088,0,8.175a1.253,1.253,0,0,0,1.911,1.206q1.1-.54,2.193-1.1a.523.523,0,0,1,.539,0c.726.378,1.45.764,2.2,1.094a1.757,1.757,0,0,0,.945.138,1.193,1.193,0,0,0,.953-1.312q0-4.1,0-8.209v-.425h12.565c.182,0,.364.008.545.012v26.545A1.618,1.618,0,0,1-996.069,457.879Zm-22.76-14.38h-7.919a1.271,1.271,0,0,0-1.455,1.435q0,4.822,0,9.645a1.273,1.273,0,0,0,1.406,1.427q7.953.006,15.906,0a1.286,1.286,0,0,0,1.424-1.448q0-4.805,0-9.61a1.275,1.275,0,0,0-1.444-1.45Zm21.216,11.871h-.6c-.182,0-.364,0-.546,0-.454.017-.733.267-.724.644a.643.643,0,0,0,.717.617q.835.013,1.671,0a.658.658,0,0,0,.735-.743c.007-.547.006-1.094,0-1.64,0-.46-.245-.742-.621-.747s-.624.275-.634.732C-997.62,454.6-997.614,454.959-997.614,455.371Z"
                              transform="translate(0 -74.85)"
                              fill="#485f27"
                            />
                            <path
                              id="Path_30105"
                              data-name="Path 30105"
                              d="M-1008.61,348c-.5,1.987-1,3.972-1.492,5.961-.054.219-.139.292-.363.292q-6.008-.009-12.016-.005c-.077,0-.154-.01-.289-.02.094-.131.162-.229.233-.325,1.317-1.76,2.624-3.527,3.961-5.272a3.993,3.993,0,0,1,.817-.632Z"
                              transform="translate(-6.647)"
                              fill="#485f27"
                            />
                            <path
                              id="Path_30106"
                              data-name="Path 30106"
                              d="M-800.006,348a1.985,1.985,0,0,1,.954.784c1.275,1.72,2.562,3.431,3.844,5.146.06.081.116.165.2.288-.128.012-.221.029-.313.029q-5.974,0-11.948.007c-.254,0-.345-.08-.405-.326-.485-1.979-.988-3.953-1.485-5.928Z"
                              transform="translate(-200.775)"
                              fill="#485f27"
                            />
                            <path
                              id="Path_30107"
                              data-name="Path 30107"
                              d="M-866.251,348q.546,2.146,1.091,4.293c.161.637.316,1.275.48,1.937h-5.875c.288-1.159.567-2.293.853-3.425.236-.936.479-1.87.72-2.8Z"
                              transform="translate(-144.977)"
                              fill="#485f27"
                            />
                            <path
                              id="Path_30108"
                              data-name="Path 30108"
                              d="M-866.148,439.405c-.315-.156-.6-.294-.878-.434-.519-.258-1.041-.511-1.555-.779a1.346,1.346,0,0,0-1.333,0c-.695.363-1.4.7-2.1,1.053-.1.049-.2.092-.336.155v-8.7h6.205Z"
                              transform="translate(-143.343 -75.16)"
                              fill="#485f27"
                            />
                            <path
                              id="Path_30109"
                              data-name="Path 30109"
                              d="M-979.25,588.231v9.951h-16.181v-9.951Zm-4.979,4.956q0,1.4,0,2.8a2.658,2.658,0,0,0,.019.443.618.618,0,0,0,.617.53.613.613,0,0,0,.6-.545,2.453,2.453,0,0,0,.018-.375q0-1.71,0-3.42c0-.8,0-1.6,0-2.394a.7.7,0,0,0-.341-.695c-.44-.235-.909.086-.914.641C-984.236,591.181-984.229,592.184-984.229,593.187Zm-7.48-.007h0c0,1.026,0,2.052,0,3.078a.628.628,0,0,0,.619.706.644.644,0,0,0,.626-.707c0-.148,0-.3,0-.445q0-2.719,0-5.438c0-.091,0-.183,0-.274a.641.641,0,0,0-.625-.641.614.614,0,0,0-.615.641C-991.716,591.128-991.709,592.154-991.709,593.18Zm-2.5.058c0,1,0,2.007,0,3.01a.636.636,0,0,0,.613.716.621.621,0,0,0,.626-.7c0-2.041.006-4.082-.006-6.122a.646.646,0,0,0-.183-.481.992.992,0,0,0-.648-.139.543.543,0,0,0-.4.6Q-994.213,591.682-994.212,593.239Zm7.5-.016c0,.992,0,1.984,0,2.976,0,.486.241.773.627.766s.614-.293.614-.784q0-2.976,0-5.951c0-.479-.247-.774-.632-.768s-.607.288-.609.752C-986.716,591.216-986.714,592.22-986.715,593.223Zm4.992-.021c0,1,0,2.007,0,3.01,0,.464.232.745.607.753s.631-.282.632-.763q0-2.993,0-5.986c0-.465-.232-.746-.608-.754s-.63.283-.631.765Q-981.724,591.714-981.722,593.2Z"
                              transform="translate(-31.492 -218.317)"
                              fill="#485f27"
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                    <div className="order-content-wrapper">
                      <h6>Ready to Ship</h6>
                      <h2>601</h2>
                    </div>
                  </div>
                  <div className="order-stat-wrapper">
                    <div className="order-icon-wrapper">
                      <span className="order-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="38.918"
                          height="32.007"
                          viewBox="0 0 38.918 32.007"
                        >
                          <g
                            id="Group_14868"
                            data-name="Group 14868"
                            transform="translate(1015.045 -869.618)"
                          >
                            <path
                              id="Path_30110"
                              data-name="Path 30110"
                              d="M-969.624,891.668H-989.96c-1.119,0-1.464-.38-1.387-1.51.1-1.4.184-2.8.278-4.194.014-.2.041-.408.067-.657h.46c1.814,0,3.627.01,5.441-.005a2.316,2.316,0,0,0,2.262-1.96,2.321,2.321,0,0,0-1.6-2.6,3.172,3.172,0,0,0-.891-.121c-1.621-.011-3.242-.005-4.864-.005h-.492l.124-1.9h.491c2.2,0,4.4.008,6.595,0a2.321,2.321,0,0,0,2.277-1.993,2.328,2.328,0,0,0-1.6-2.568,3.1,3.1,0,0,0-.891-.125c-2.02-.011-4.039-.006-6.059-.006h-.484c.065-.959.156-1.872.182-2.786.043-1.475.568-1.614,1.789-1.61,6.238.021,12.475.009,18.713.01,1.054,0,1.419.421,1.344,1.478-.212,2.987-.407,5.976-.612,8.963q-.35,5.1-.707,10.2C-969.561,890.716-969.59,891.168-969.624,891.668Z"
                              transform="translate(-21.079)"
                              fill="#485f27"
                            />
                            <path
                              id="Path_30111"
                              data-name="Path 30111"
                              d="M-766.151,970.131a1,1,0,0,1-.849.744,3.247,3.247,0,0,1-.452.016H-779.01l.842-12.237h.447c2.431,0,4.862.01,7.293-.007a1.282,1.282,0,0,1,1.241.7q1.392,2.4,2.829,4.784a1.944,1.944,0,0,1,.241,1.344c-.4,0-.8.027-1.19,0a2.226,2.226,0,0,0-2.585,2.674,2.083,2.083,0,0,0,2.027,1.985C-767.316,970.141-766.767,970.131-766.151,970.131Zm-6.45-5.6c1.058,0,2.115.007,3.173,0a.825.825,0,0,0,.75-1.352c-.658-1.094-1.33-2.179-1.988-3.273a.946.946,0,0,0-.879-.5c-1.387.006-2.775,0-4.162,0a.836.836,0,0,0-.937.944q-.008,1.607,0,3.215c0,.661.295.958.953.962C-774.661,964.541-773.631,964.536-772.6,964.536Z"
                              transform="translate(-210.037 -79.224)"
                              fill="#485f27"
                            />
                            <path
                              id="Path_30112"
                              data-name="Path 30112"
                              d="M-976.078,1084.788h17.316c.6,0,.768.1.772.437.008.677-.266,1.016-.827,1.017-1.1,0-2.2.009-3.3-.008a1.06,1.06,0,0,1-.559-.182,4.729,4.729,0,0,0-5.553,0,1.032,1.032,0,0,1-.558.179q-6.411.015-12.822,0a1.179,1.179,0,0,1-.636-.2,4.741,4.741,0,0,0-5.521.01,1.045,1.045,0,0,1-.557.188c-1.759.014-3.518.009-5.277.008-.5,0-.63-.107-.633-.489-.006-.787.113-.963.672-.963Q-984.818,1084.786-976.078,1084.788Z"
                              transform="translate(-18.521 -191.47)"
                              fill="#485f27"
                            />
                            <path
                              id="Path_30113"
                              data-name="Path 30113"
                              d="M-757.946,1103.146a3.107,3.107,0,0,1,3.075,3.149,3.123,3.123,0,0,1-3.136,3.137,3.149,3.149,0,0,1-3.062-3.178A3.124,3.124,0,0,1-757.946,1103.146Zm-1.414,3.137a1.413,1.413,0,0,0,1.382,1.406,1.423,1.423,0,0,0,1.409-1.384,1.4,1.4,0,0,0-1.408-1.416A1.388,1.388,0,0,0-759.36,1106.284Z"
                              transform="translate(-226.001 -207.807)"
                              fill="#485f27"
                            />
                            <path
                              id="Path_30114"
                              data-name="Path 30114"
                              d="M-932.38,1106.292a3.117,3.117,0,0,1-3.088,3.141,3.145,3.145,0,0,1-3.108-3.133,3.126,3.126,0,0,1,3.12-3.152A3.1,3.1,0,0,1-932.38,1106.292Zm-3.1-1.555a1.56,1.56,0,0,0-1.535,1.562,1.557,1.557,0,0,0,1.524,1.532,1.538,1.538,0,0,0,1.547-1.542A1.536,1.536,0,0,0-935.477,1104.738Z"
                              transform="translate(-68.047 -207.808)"
                              fill="#485f27"
                            />
                            <path
                              id="Path_30115"
                              data-name="Path 30115"
                              d="M-1010.312,985.795q-1.936,0-3.873,0c-.557,0-.871-.266-.86-.712.011-.425.326-.683.856-.684q3.893,0,7.787,0c.562,0,.862.262.852.721-.01.443-.294.673-.849.674Q-1008.355,985.8-1010.312,985.795Z"
                              transform="translate(0 -102.136)"
                              fill="#485f27"
                            />
                            <path
                              id="Path_30116"
                              data-name="Path 30116"
                              d="M-995.444,925.9q-1.936,0-3.873,0c-.555,0-.841-.229-.853-.669-.012-.458.291-.726.848-.726q3.893,0,7.786,0c.055,0,.11,0,.165,0a.7.7,0,0,1,.693.72.671.671,0,0,1-.729.671C-992.753,925.9-994.1,925.9-995.444,925.9Z"
                              transform="translate(-13.236 -48.835)"
                              fill="#485f27"
                            />
                            <path
                              id="Path_30117"
                              data-name="Path 30117"
                              d="M-679.174,1035.639v1.338c-.609,0-1.219.019-1.826-.018-.109-.007-.248-.23-.295-.377a1.613,1.613,0,0,1-.011-.53.386.386,0,0,1,.417-.411C-680.329,1035.633-679.77,1035.639-679.174,1035.639Z"
                              transform="translate(-296.965 -147.733)"
                              fill="#485f27"
                            />
                            <path
                              id="Path_30118"
                              data-name="Path 30118"
                              d="M-742.417,982.112v-1.805a20.225,20.225,0,0,0,2.13-.016,1.247,1.247,0,0,1,1.461.835,8.584,8.584,0,0,0,.579.987Z"
                              transform="translate(-242.6 -98.47)"
                              fill="#485f27"
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                    <div className="order-content-wrapper">
                      <h6>Order Shipped</h6>
                      <h2>311</h2>
                    </div>
                  </div>
                  <div className="order-stat-wrapper">
                    <div className="order-icon-wrapper">
                      <span className="order-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32.534"
                          height="32.533"
                          viewBox="0 0 32.534 32.533"
                        >
                          <g
                            id="Group_14869"
                            data-name="Group 14869"
                            transform="translate(565.324 350.072)"
                          >
                            <path
                              id="Path_30119"
                              data-name="Path 30119"
                              d="M-411.844-206.345a9.762,9.762,0,0,1,9.783,9.769,9.768,9.768,0,0,1-9.77,9.75,9.766,9.766,0,0,1-9.749-9.77A9.767,9.767,0,0,1-411.844-206.345Zm.04,14.1c.729,0,1.417,0,2.106,0a3.258,3.258,0,0,0,3.3-3.244,3.257,3.257,0,0,0-3.315-3.261c-1.141-.006-2.281,0-3.422,0h-.321l-.067-.106,1.036-.915-1.537-1.542c-.028.025-.079.066-.126.113q-1.356,1.351-2.711,2.7a1.077,1.077,0,0,0,0,1.668c.589.593,1.186,1.177,1.774,1.772.355.36.7.732,1,1.056l1.6-1.6-1.012-.973c1.355,0,2.672-.022,3.988.011a1.044,1.044,0,0,1,.936,1.1,1.088,1.088,0,0,1-1.013,1.041c-.214.014-.429,0-.644,0H-411.8Z"
                              transform="translate(-130.728 -130.714)"
                              fill="#485f27"
                            />
                            <path
                              id="Path_30120"
                              data-name="Path 30120"
                              d="M-543.535-262.821h-.328q-4.134,0-8.267,0a1.088,1.088,0,0,1-1.194-1.17q0-6.9,0-13.79c0-.056,0-.112.009-.194h5.413c0,.407,0,.82,0,1.233a9.781,9.781,0,0,0,.033,1.319,3.267,3.267,0,0,0,4.327,2.669c.633-.2,1.256-.44,1.89-.639a.937.937,0,0,1,.516,0c.821.242,1.62.58,2.455.749a3.211,3.211,0,0,0,3.786-3.087c.016-.743,0-1.486,0-2.25h5.407v4.505A11.992,11.992,0,0,0-543.535-262.821Zm-.05-3.269v-2.131h-6.461v2.131Zm-6.471-3.261h3.211v-2.127h-3.211Z"
                              transform="translate(-10.912 -65.562)"
                              fill="#485f27"
                            />
                            <path
                              id="Path_30121"
                              data-name="Path 30121"
                              d="M-469.491-349.9h8.675v.343q0,4.064,0,8.128a1.111,1.111,0,0,1-1.618,1.154c-.8-.269-1.6-.549-2.406-.8a1.094,1.094,0,0,0-.61,0c-.829.256-1.646.548-2.471.819a1.1,1.1,0,0,1-1.569-1.123q0-4.1,0-8.2Z"
                              transform="translate(-87.155 -0.158)"
                              fill="#485f27"
                            />
                            <path
                              id="Path_30122"
                              data-name="Path 30122"
                              d="M-565.319-345.756c0-1.115-.013-2.208.005-3.3a1.027,1.027,0,0,1,1-1.005c1.815-.016,3.631-.006,5.472-.006v4.312Z"
                              transform="translate(0 -0.002)"
                              fill="#485f27"
                            />
                            <path
                              id="Path_30123"
                              data-name="Path 30123"
                              d="M-349.465-350.069c1.84,0,3.654-.009,5.469.006a1.031,1.031,0,0,1,1.005,1c.02,1.093.006,2.186.006,3.3h-6.48Z"
                              transform="translate(-196.314)"
                              fill="#485f27"
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                    <div className="order-content-wrapper">
                      <h6>Order Complined</h6>
                      <h2>1.121</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="order-activity-wrapper order-activity-no-margin">
                <Barchart />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* seller dashboard ends here */}
      {/* Footer starts here */}
      <Footer />
      {/* Footer ends here */}
    </>
  );
};

export default SellerDashboard;
