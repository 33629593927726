import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import {
  organic1,
  productslider1,
  productslider2,
  productslider3,
  productslider4,
  productslider5,
  slider1,
  slider2,
  slider3,
  slider4,
  slider5,
} from "../Constant/Index";

function Productslider() {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 4,
				slidesToScroll: 3,
				infinite: true,
				dots: false
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			}
		  ]
  };
  return (
    <>
      {/* Product Slider */}
      <section className=" prdocut-slide-sec">
        <div className="container">
          <div className="slide-head">
            <h4>Related Products</h4>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Slider {...settings}>
                <div>
                  <div className="slide-dv organic-food for-space-in-slider">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="slid-1"
                        src={productslider4}
                      />
                    </figure>
                    <div className="slide-text">
                      <p>Lorem Ipsum is simply </p>
                      <div className="slide-for-flex">
                        <h5>$567.89</h5>
                        <div className="slide-star">
                          <ul className="slide-star-ul">
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="product-heading">
                      <div className="pro-head">
                        <h5>Food</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="slide-dv organic-food for-space-in-slider">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="slid-1"
                        src={productslider1}
                      />
                    </figure>
                    <div className="slide-text">
                      <p>Lorem Ipsum is simply </p>
                      <div className="slide-for-flex">
                        <h5>$567.89</h5>
                        <div className="slide-star">
                          <ul className="slide-star-ul">
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="product-heading">
                      <div className="pro-head">
                        <h5>Food</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="slide-dv organic-food for-space-in-slider">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="slid-1"
                        src={productslider2}
                      />
                    </figure>
                    <div className="slide-text">
                      <p>Lorem Ipsum is simply </p>
                      <div className="slide-for-flex">
                        <h5>$567.89</h5>
                        <div className="slide-star">
                          <ul className="slide-star-ul">
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="product-heading">
                      <div className="pro-head">
                        <h5>Food</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="slide-dv organic-food for-space-in-slider">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="slid-1"
                        src={productslider3}
                      />
                    </figure>
                    <div className="slide-text">
                      <p>Lorem Ipsum is simply </p>
                      <div className="slide-for-flex">
                        <h5>$567.89</h5>
                        <div className="slide-star">
                          <ul className="slide-star-ul">
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="product-heading">
                      <div className="pro-head">
                        <h5>Food</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="slide-dv organic-food for-space-in-slider">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="slid-1"
                        src={productslider5}
                      />
                    </figure>
                    <div className="slide-text">
                      <p>Lorem Ipsum is simply </p>
                      <div className="slide-for-flex">
                        <h5>$567.89</h5>
                        <div className="slide-star">
                          <ul className="slide-star-ul">
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="product-heading">
                      <div className="pro-head">
                        <h5>Food</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="slide-dv organic-food for-space-in-slider">
                    <figure>
                      <img className="img-fluid" alt="slid-1" src={organic1} />
                    </figure>
                    <div className="slide-text">
                      <p>Lorem Ipsum is simply </p>
                      <div className="slide-for-flex">
                        <h5>$567.89</h5>
                        <div className="slide-star">
                          <ul className="slide-star-ul">
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                            <li>
                              {" "}
                              <i class="fa fa-star" aria-hidden="true" />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="product-heading">
                      <div className="pro-head">
                        <h5>Food</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* =========================== */}
    </>
  );
}

export default Productslider;
