import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import {
  ordersmap,
  vegiorder1,
  vegiorder2,
  vegiorder3,
} from "../../Constant/Index";
import Siderbar from "./Siderbar";

function Myorders() {
  return (
    <>
      <Header />
      <PageHeader pagename="my orders" />
      {/* My Orders Section */}
      <section className="my-orders-sec my-profile-all">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <Siderbar />
            </div>
            <div className="col-md-10">
              <div className="all-table-order">
                <table>
                  <thead>
                    <th className="for-border-all-raduis">OrderNo</th>
                    <th>Order Date</th>
                    <th>Status</th>
                    <th>Payment Status</th>
                    <th>Total</th>
                    <th className="for-border-raduis-all-things">
                      Order Details
                    </th>
                  </thead>

                  <tbody>
                    <tr className="for-only-bor">
                      <td className="order-td-left">
                        {" "}
                        <div className="for-border-raduis">
                          Order #s0tq-vrp6
                        </div>
                      </td>
                      <td>04-12-22</td>
                      <td>Confirm</td>
                      <td className="for-different-color">
                        Paid{" "}
                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                      </td>
                      <td>$590.00</td>
                      <td className="end-td-borde-raduis">
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          Order Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td className="order-td-left">Order #s0tq-vrp6</td>
                      <td>04-12-22</td>
                      <td>Confirm</td>
                      <td className="for-different-color">
                        Paid{" "}
                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                      </td>
                      <td>$590.00</td>
                      <td className="end-td-borde-raduis">
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          Order Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td className="order-td-left">Order #s0tq-vrp6</td>
                      <td>04-12-22</td>
                      <td>Confirm</td>
                      <td className="for-different-color">
                        Paid{" "}
                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                      </td>
                      <td>$590.00</td>
                      <td className="end-td-borde-raduis">
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          Order Details
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td className="order-td-left">Order #s0tq-vrp6</td>
                      <td>04-12-22</td>
                      <td>Confirm</td>
                      <td className="for-different-color">
                        Paid{" "}
                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                      </td>
                      <td>$590.00</td>
                      <td className="end-td-borde-raduis">
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          Order Details
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="pagination-all">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <li class="page-item">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          1
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========================= */}

      <Footer />

      {/* Order Detail */}
      <div className="order-total-modal">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div className="responsive-close-icon">
                <i class="fa fa-times" aria-hidden="true" data-bs-dismiss="modal"></i>
              </div>
              <div class="modal-body">
                <div className="all-orders-detail-modal">
                  <div className="order-head">
                    <h2>Order #s0tq-vrp6</h2>
                    <p>Delivered on 18 Jan, 20:54</p>
                  </div>
                  <div className="all-order-img">
                    <div className="all-img-order">
                      <figure>
                        <img
                          className="img-fluid"
                          alt="order-img"
                          src={ordersmap}
                        />
                      </figure>
                    </div>
                    <div className="all-img-order-text">
                      <div className="order-text">
                        <h4>Order From</h4>
                        <p>Eat & Bite</p>
                      </div>
                      <div className="order-text order-move-up-down">
                        <h4>Delivery Address</h4>
                        <p className="for-para-width">
                          Lorem Ipsum is simply dummy text of 123ssd2 the
                          printing{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="all-img-flex">
                    <div className="img-one">
                      <figure>
                        <img
                          className="img-fluid"
                          alt="img-one"
                          src={vegiorder1}
                        />
                      </figure>
                      <div className="img-text-all">
                        <h4>Cabbage Soup</h4>
                        <h5>Qty: 2</h5>
                      </div>
                    </div>

                    <div className="order-price">
                      <h5>$158.07 </h5>
                    </div>
                  </div>
                  <div className="all-img-flex">
                    <div className="img-one">
                      <figure>
                        <img
                          className="img-fluid"
                          alt="img-one"
                          src={vegiorder2}
                        />
                      </figure>
                      <div className="img-text-all">
                        <h4>Cabbage Soup</h4>
                        <h5>Qty: 2</h5>
                      </div>
                    </div>

                    <div className="order-price">
                      <h5>$158.07 </h5>
                    </div>
                  </div>
                  <div className="all-img-flex">
                    <div className="img-one">
                      <figure>
                        <img
                          className="img-fluid"
                          alt="img-one"
                          src={vegiorder3}
                        />
                      </figure>
                      <div className="img-text-all">
                        <h4>Cabbage Soup</h4>
                        <h5>Qty: 2</h5>
                      </div>
                    </div>

                    <div className="order-price">
                      <h5>$158.07 </h5>
                    </div>
                  </div>

                  <div className="item-total">
                    <div className="order-total">
                      <h5>Item Total :</h5>
                      <h5>$50</h5>
                    </div>
                    <div className="order-total">
                      <h5>Delivery Charges </h5>
                      <h5>$0</h5>
                    </div>
                    <div className="order-total">
                      <h5>Promo Code Applied :</h5>
                      <h5>-$5</h5>
                    </div>
                  </div>

                  <div className="order-sum">
                    <h3>Order Total</h3>
                    <h4>$45</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============== */}
    </>
  );
}

export default Myorders;
