import React, { useState } from "react";
import Slider from "react-slick";
import {
  customizeproduct1,
  customizeproduct2,
  customizeproduct3,
  customizeproduct6,
  customizeproductmain,
  fabricslider1,
  fabricslider2,
  fabricslider3,
  fabricslider4,
  fabricslider5,
  fabricslider6,
  fabricslider7,
  fabricslider8,
} from "../Constant/Index";

function Fabricseller() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 9,
    slidesToScroll: 1,
    arrows: true,
  };
  return (
    <>
      {" "}
      <div className="fabricc-all">
        <div className="row">
          <div className="col-md-2">
            <div className="small-slider">
              <h4>Fabric</h4>
            </div>
          </div>
          <div className="col-md-10">
            <Slider {...settings}>
              <div className="slide-small-images fabric-images">
                <img className="img-fluid" src={fabricslider1} />
              </div>
              <div className="slide-small-images fabric-images">
                <img className="img-fluid" src={fabricslider2} />
              </div>
              <div className="slide-small-images fabric-images">
                <img className="img-fluid " src={fabricslider3} />
              </div>
              <div className="slide-small-images fabric-images">
                <img className="img-fluid" src={fabricslider4} />
              </div>
              <div className="slide-small-images fabric-images">
                <img className="img-fluid " src={fabricslider5} />
              </div>
              <div className="slide-small-images fabric-images">
                <img className="img-fluid" src={fabricslider6} />
              </div> 
              <div className="slide-small-images fabric-images">
                <img className="img-fluid" src={fabricslider7} />
              </div>
              <div className="slide-small-images fabric-images">
                <img className="img-fluid" src={fabricslider8} />
              </div>
              <div className="slide-small-images fabric-images">
                <img className="img-fluid" src={fabricslider1} />
              </div>
              <div className="slide-small-images fabric-images">
                <img className="img-fluid" src={fabricslider2} />
              </div>
              <div className="slide-small-images fabric-images">
                <img className="img-fluid " src={fabricslider3} />
              </div>
              <div className="slide-small-images fabric-images">
                <img className="img-fluid" src={fabricslider4} />
              </div>
              <div className="slide-small-images fabric-images">
                <img className="img-fluid " src={fabricslider5} />
              </div>
              <div className="slide-small-images fabric-images">
                <img className="img-fluid" src={fabricslider6} />
              </div>
              <div className="slide-small-images fabric-images">
                <img className="img-fluid" src={fabricslider7} />
              </div>
              <div className="slide-small-images fabric-images">
                <img className="img-fluid" src={fabricslider8} />
              </div>
            </Slider>
          </div>
        </div>
        {/* Fabirc Slider */}
      </div>
    </>
  );
}

export default Fabricseller;
