import React from "react";
import { Link } from "react-router-dom";
import { blog1, blog2, blog3 } from "../Constant/Index";

function Blogsmain() {
  return (
    <>
      {/* Blogs Components Section */}
      <section className="blog-main-sec wow animate__animated animate__flipInX">
        <div className="container">
          <div className="slide-head">
            <h4>Blogs</h4>
            <h4>
              <Link to="/blogs">view all</Link>
            </h4>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="blog-compo-all-dv">
                <div className="blog-compo-img">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="blog-main-img"
                      src={blog1}
                    />
                  </figure>
                </div>
                <div className="blog-compo-text-date">
                  <h5>Category : Lorem Ipsum is simply</h5>
                  <h5>
                    <span>30'Oct 2022</span>
                  </h5>
                </div>
                <div className="only-blog-text">
                  <h4>Lorem Ipsum is simply </h4>
                  <p>
                    sum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore
                  </p>
                </div>
                <div className="blog-main-btn">
                 <Link to="/blog-detail">read more</Link>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="blog-compo-all-dv">
                <div className="blog-compo-img">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="blog-main-img"
                      src={blog2}
                    />
                  </figure>
                </div>
                <div className="blog-compo-text-date">
                  <h5>Category : Lorem Ipsum is simply</h5>
                  <h5>
                    <span>30'Oct 2022</span>
                  </h5>
                </div>
                <div className="only-blog-text">
                  <h4>Lorem Ipsum is simply </h4>
                  <p>
                    sum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore
                  </p>
                </div>
                <div className="blog-main-btn">
                 <Link to="/blog-detail">read more</Link>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="blog-compo-all-dv">
                <div className="blog-compo-img">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="blog-main-img"
                      src={blog3}
                    />
                  </figure>
                </div>
                <div className="blog-compo-text-date">
                  <h5>Category : Lorem Ipsum is simply</h5>
                  <h5>
                    <span>30'Oct 2022</span>
                  </h5>
                </div>
                <div className="only-blog-text">
                  <h4>Lorem Ipsum is simply </h4>
                  <p>
                    sum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore
                  </p>
                </div>
                <div className="blog-main-btn">
                 <Link to="/blog-detail">read more</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blogsmain;
