import React from "react";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import {
  man1,
  man2,
  sellerstore1,
  sellerstore10,
  sellerstore11,
  sellerstore12,
  sellerstore2,
  sellerstore3,
  sellerstore4,
  sellerstore5,
  sellerstore6,
  sellerstore7,
  sellerstore8,
  sellerstore9,
  sellerstoreimg,
  slider1,
} from "../../Constant/Index";
import "../../assets/css/sellerstore.css";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";

function Sellerstore() {
  return (
    <>
      <Header />
      <PageHeader pagename="seller store" />

      {/* Seller Store Section */}
      <section className="seller-store-sec">
        <div className="container">
          <div className="seller-store-all">
            <div className="row">
              <div className="col-md-6">
                <div className="seller-dv">
                  <div className="seller-logo-img">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="seller-store-img"
                        src={sellerstoreimg}
                      />
                    </figure>
                  </div>
                  <div className="seller-names-and-icons">
                    <div className="small-heading">
                      <h5>Store Info</h5>
                      <div className="icon-number">
                        <i class="fa fa-phone" aria-hidden="true"></i>
                        <a href="tel:956225-665-55">956225-665-55</a>
                      </div>
                      <div className="icon-number">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                        <a href="mailto:johndoe@gmail.com">johndoe@gmail.com</a>
                      </div>
                      <div className="seller-stars">
                        <ul className="stars-for-seller">
                          <li>
                            <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i class="fa fa-star" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="seller-about">
                  <h3>About Seller</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========================= */}

      {/* Seller Store Tabs Section */}
      <section className="seller-tabs-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="all-store-tab">
                <div className="all-nav-flex-stuff">
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Home
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Reviews
                      </button>
                    </li>
                  </ul>
                  <div className="seller-search">
                    <input type="text" placeholder="Search" />
                    <div className="seller-search-icon">
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>

                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="seller-sort-flex">
                      <div className="sort-flex">
                        <div className="sort-all">
                          <p>Sort By :</p>
                          <span>
                            <div class="dropdown ">
                              <button
                                class="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton2"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Default Sorting
                              </button>
                              <ul
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuButton2"
                              >
                                <li>
                                  <a class="dropdown-item" href="#">
                                    Default Sorting
                                  </a>
                                </li>
                                <li>
                                  <a class="dropdown-item" href="#">
                                    Default Sorting
                                  </a>
                                </li>
                                <li>
                                  <a class="dropdown-item" href="#">
                                    Default Sorting
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </span>
                        </div>
                        <div className="sort-all">
                          <span>
                            <div class="dropdown ">
                              <button
                                class="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton2"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Show 15
                              </button>
                              <ul
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuButton2"
                              >
                                <li>
                                  <a class="dropdown-item" href="#">
                                    Show 15
                                  </a>
                                </li>
                                <li>
                                  <a class="dropdown-item" href="#">
                                    Show 15
                                  </a>
                                </li>
                                <li>
                                  <a class="dropdown-item" href="#">
                                    Show 15
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </span>
                          <span className="two-grid-icons">
                            <Link>
                              <i class="fa fa-th" aria-hidden="true"></i>
                            </Link>
                            <Link>
                              <i class="fa fa-th" aria-hidden="true"></i>
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="slide-dv organic-food fashion-product-all">
                          <figure>
                            <img
                              className="img-fluid"
                              alt="slid-1"
                              src={sellerstore1}
                            />
                          </figure>
                          <div className="slide-text">
                            <p>Lorem Ipsum is simply </p>
                            <div className="slide-for-flex">
                              <h5>$567.89</h5>
                              <div className="slide-star">
                                <ul className="slide-star-ul">
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="product-heading">
                            <div className="pro-head">
                              <h5>Fashion</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="slide-dv organic-food fashion-product-all">
                          <figure>
                            <img
                              className="img-fluid"
                              alt="slid-1"
                              src={sellerstore2}
                            />
                          </figure>
                          <div className="slide-text">
                            <p>Lorem Ipsum is simply </p>
                            <div className="slide-for-flex">
                              <h5>$567.89</h5>
                              <div className="slide-star">
                                <ul className="slide-star-ul">
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="product-heading">
                            <div className="pro-head">
                              <h5>Fashion</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="slide-dv organic-food fashion-product-all">
                          <figure>
                            <img
                              className="img-fluid"
                              alt="slid-1"
                              src={sellerstore3}
                            />
                          </figure>
                          <div className="slide-text">
                            <p>Lorem Ipsum is simply </p>
                            <div className="slide-for-flex">
                              <h5>$567.89</h5>
                              <div className="slide-star">
                                <ul className="slide-star-ul">
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="product-heading">
                            <div className="pro-head">
                              <h5>Fashion</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="slide-dv organic-food fashion-product-all">
                          <figure>
                            <img
                              className="img-fluid"
                              alt="slid-1"
                              src={sellerstore2}
                            />
                          </figure>
                          <div className="slide-text">
                            <p>Lorem Ipsum is simply </p>
                            <div className="slide-for-flex">
                              <h5>$567.89</h5>
                              <div className="slide-star">
                                <ul className="slide-star-ul">
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="product-heading">
                            <div className="pro-head">
                              <h5>Fashion</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="slide-dv organic-food fashion-product-all">
                          <figure>
                            <img
                              className="img-fluid"
                              alt="slid-1"
                              src={sellerstore4}
                            />
                          </figure>
                          <div className="slide-text">
                            <p>Lorem Ipsum is simply </p>
                            <div className="slide-for-flex">
                              <h5>$567.89</h5>
                              <div className="slide-star">
                                <ul className="slide-star-ul">
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="product-heading">
                            <div className="pro-head">
                              <h5>Fashion</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="slide-dv organic-food fashion-product-all">
                          <figure>
                            <img
                              className="img-fluid"
                              alt="slid-1"
                              src={sellerstore5}
                            />
                          </figure>
                          <div className="slide-text">
                            <p>Lorem Ipsum is simply </p>
                            <div className="slide-for-flex">
                              <h5>$567.89</h5>
                              <div className="slide-star">
                                <ul className="slide-star-ul">
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="product-heading">
                            <div className="pro-head">
                              <h5>Fashion</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="slide-dv organic-food fashion-product-all">
                          <figure>
                            <img
                              className="img-fluid"
                              alt="slid-1"
                              src={sellerstore6}
                            />
                          </figure>
                          <div className="slide-text">
                            <p>Lorem Ipsum is simply </p>
                            <div className="slide-for-flex">
                              <h5>$567.89</h5>
                              <div className="slide-star">
                                <ul className="slide-star-ul">
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="product-heading">
                            <div className="pro-head">
                              <h5>Fashion</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="slide-dv organic-food fashion-product-all">
                          <figure>
                            <img
                              className="img-fluid"
                              alt="slid-1"
                              src={sellerstore4}
                            />
                          </figure>
                          <div className="slide-text">
                            <p>Lorem Ipsum is simply </p>
                            <div className="slide-for-flex">
                              <h5>$567.89</h5>
                              <div className="slide-star">
                                <ul className="slide-star-ul">
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="product-heading">
                            <div className="pro-head">
                              <h5>Fashion</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="slide-dv organic-food fashion-product-all">
                          <figure>
                            <img
                              className="img-fluid"
                              alt="slid-1"
                              src={sellerstore7}
                            />
                          </figure>
                          <div className="slide-text">
                            <p>Lorem Ipsum is simply </p>
                            <div className="slide-for-flex">
                              <h5>$567.89</h5>
                              <div className="slide-star">
                                <ul className="slide-star-ul">
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="product-heading">
                            <div className="pro-head">
                              <h5>Fashion</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="slide-dv organic-food fashion-product-all">
                          <figure>
                            <img
                              className="img-fluid"
                              alt="slid-1"
                              src={sellerstore8}
                            />
                          </figure>
                          <div className="slide-text">
                            <p>Lorem Ipsum is simply </p>
                            <div className="slide-for-flex">
                              <h5>$567.89</h5>
                              <div className="slide-star">
                                <ul className="slide-star-ul">
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="product-heading">
                            <div className="pro-head">
                              <h5>Fashion</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="slide-dv organic-food fashion-product-all">
                          <figure>
                            <img
                              className="img-fluid"
                              alt="slid-1"
                              src={sellerstore9}
                            />
                          </figure>
                          <div className="slide-text">
                            <p>Lorem Ipsum is simply </p>
                            <div className="slide-for-flex">
                              <h5>$567.89</h5>
                              <div className="slide-star">
                                <ul className="slide-star-ul">
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="product-heading">
                            <div className="pro-head">
                              <h5>Fashion</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="slide-dv organic-food fashion-product-all">
                          <figure>
                            <img
                              className="img-fluid"
                              alt="slid-1"
                              src={sellerstore8}
                            />
                          </figure>
                          <div className="slide-text">
                            <p>Lorem Ipsum is simply </p>
                            <div className="slide-for-flex">
                              <h5>$567.89</h5>
                              <div className="slide-star">
                                <ul className="slide-star-ul">
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="product-heading">
                            <div className="pro-head">
                              <h5>Fashion</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="slide-dv organic-food fashion-product-all">
                          <figure>
                            <img
                              className="img-fluid"
                              alt="slid-1"
                              src={sellerstore10}
                            />
                          </figure>
                          <div className="slide-text">
                            <p>Lorem Ipsum is simply </p>
                            <div className="slide-for-flex">
                              <h5>$567.89</h5>
                              <div className="slide-star">
                                <ul className="slide-star-ul">
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="product-heading">
                            <div className="pro-head">
                              <h5>Fashion</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="slide-dv organic-food fashion-product-all">
                          <figure>
                            <img
                              className="img-fluid"
                              alt="slid-1"
                              src={sellerstore11}
                            />
                          </figure>
                          <div className="slide-text">
                            <p>Lorem Ipsum is simply </p>
                            <div className="slide-for-flex">
                              <h5>$567.89</h5>
                              <div className="slide-star">
                                <ul className="slide-star-ul">
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="product-heading">
                            <div className="pro-head">
                              <h5>Fashion</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="slide-dv organic-food fashion-product-all">
                          <figure>
                            <img
                              className="img-fluid"
                              alt="slid-1"
                              src={sellerstore12}
                            />
                          </figure>
                          <div className="slide-text">
                            <p>Lorem Ipsum is simply </p>
                            <div className="slide-for-flex">
                              <h5>$567.89</h5>
                              <div className="slide-star">
                                <ul className="slide-star-ul">
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="product-heading">
                            <div className="pro-head">
                              <h5>Fashion</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="slide-dv organic-food fashion-product-all">
                          <figure>
                            <img
                              className="img-fluid"
                              alt="slid-1"
                              src={sellerstore11}
                            />
                          </figure>
                          <div className="slide-text">
                            <p>Lorem Ipsum is simply </p>
                            <div className="slide-for-flex">
                              <h5>$567.89</h5>
                              <div className="slide-star">
                                <ul className="slide-star-ul">
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                  <li>
                                    {" "}
                                    <i class="fa fa-star" aria-hidden="true" />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="product-heading">
                            <div className="pro-head">
                              <h5>Fashion</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="reviews-seller-store">
                      {/* Reviews-Row   */}
                      <div className="row for-margin sellerstore-review">
                        <div className="col-md-1">
                          <div className="review-img seller-store-review-img">
                            <img className="img-fluid" src={man1} />
                          </div>
                        </div>
                        <div className="col-md-11">
                          <div className="review-tab-all">
                            <div className="tab-text">
                              <h5>
                                Joeby Ragpa{" "}
                                <span> - 12 April, 2014 at 16:50</span>
                              </h5>
                              <p>
                                We possess within us two minds. So far I have
                                written only of the conscious mind. I would now
                                like to introduce you to your second mind, the
                                hidden and mysterious subconscious. Our
                                subconscious mind contains such power and
                                complexity that it literally staggers the
                                imagination.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* =========================== */}
                      {/* Reviews-Row   */}
                      <div className="row for-margin sellerstore-review">
                        <div className="col-md-1">
                          <div className="review-img seller-store-review-img">
                            <img className="img-fluid" src={man2} />
                          </div>
                        </div>
                        <div className="col-md-11">
                          <div className="review-tab-all">
                            <div className="tab-text">
                              <h5>
                                Penta kentucky
                                <span> - 12 April, 2014 at 16:50</span>
                              </h5>
                              <p>
                                We possess within us two minds. So far I have
                                written only of the conscious mind. I would now
                                like to introduce you to your second mind, the
                                hidden and mysterious subconscious. Our
                                subconscious mind contains such power.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* =========================== */}
                      {/* Reviews-Row   */}
                      <div className="row for-margin sellerstore-review">
                        <div className="col-md-1">
                          <div className="review-img seller-store-review-img">
                            <img className="img-fluid" src={man1} />
                          </div>
                        </div>
                        <div className="col-md-11">
                          <div className="review-tab-all">
                            <div className="tab-text">
                              <h5>
                                Joeby Ragpa{" "}
                                <span> - 12 April, 2014 at 16:50</span>
                              </h5>
                              <p>
                                We possess within us two minds. So far I have
                                written only of the conscious mind. I would now
                                like to introduce you to your second mind, the
                                hidden and mysterious subconscious. Our
                                subconscious mind contains such power and
                                complexity that it literally staggers the
                                imagination.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* =========================== */}
                      {/* Reviews-Row   */}
                      <div className="row for-margin sellerstore-review">
                        <div className="col-md-1">
                          <div className="review-img seller-store-review-img">
                            <img className="img-fluid" src={man2} />
                          </div>
                        </div>
                        <div className="col-md-11">
                          <div className="review-tab-all">
                            <div className="tab-text">
                              <h5>
                                Penta kentucky
                                <span> - 12 April, 2014 at 16:50</span>
                              </h5>
                              <p>
                                We possess within us two minds. So far I have
                                written only of the conscious mind. I would now
                                like to introduce you to your second mind, the
                                hidden and mysterious subconscious. Our
                                subconscious mind contains such power.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ========================= */}
                      {/* Reviews-Row   */}
                      <div className="row for-margin sellerstore-review">
                        <div className="col-md-1">
                          <div className="review-img seller-store-review-img">
                            <img className="img-fluid" src={man1} />
                          </div>
                        </div>
                        <div className="col-md-11">
                          <div className="review-tab-all">
                            <div className="tab-text">
                              <h5>
                                Joeby Ragpa{" "}
                                <span> - 12 April, 2014 at 16:50</span>
                              </h5>
                              <p>
                                We possess within us two minds. So far I have
                                written only of the conscious mind. I would now
                                like to introduce you to your second mind, the
                                hidden and mysterious subconscious. Our
                                subconscious mind contains such power and
                                complexity that it literally staggers the
                                imagination.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* =========================== */}

                      {/* Reviews-Row   */}
                      <div className="row sellerstore-review">
                        <div className="col-md-1">
                          <div className="review-img seller-store-review-img">
                            <img className="img-fluid" src={man2} />
                          </div>
                        </div>
                        <div className="col-md-11">
                          <div className="review-tab-all">
                            <div className="tab-text">
                              <h5>
                                Joeby Ragpa{" "}
                                <span> - 12 April, 2014 at 16:50</span>
                              </h5>
                              <p>
                                We possess within us two minds. So far I have
                                written only of the conscious mind. I would now
                                like to introduce you to your second mind, the
                                hidden and mysterious subconscious. Our
                                subconscious mind contains such power and
                                complexity that it literally staggers the
                                imagination.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* =========================== */}
                    </div>
                    <div className="seller-review-form-sec">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="all-review-form">
                            <form>
                              <div className="review-head">
                                <h3>Review</h3>
                                <p>Add a review</p>
                                <p>
                                  Your email address will not be published.
                                  Required fields are marked *
                                </p>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="review-form-input">
                                    <input type="text" placeholder="Name" />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="review-form-input">
                                    <input type="text" placeholder="Email" />
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="review-form-input">
                                    <textarea
                                      class="form-control"
                                      id="exampleFormControlTextarea1"
                                      rows="3"
                                      placeholder="Comment"
                                    ></textarea>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="review-rating-star">
                                    <h4>Ratings</h4>
                                    <ul>
                                      <li>
                                        <i
                                          class="fa fa-star"
                                          aria-hidden="true"
                                        ></i>
                                      </li>
                                      <li>
                                        <i
                                          class="fa fa-star"
                                          aria-hidden="true"
                                        ></i>
                                      </li>
                                      <li>
                                        <i
                                          class="fa fa-star"
                                          aria-hidden="true"
                                        ></i>
                                      </li>
                                      <li>
                                        <i
                                          class="fa fa-star"
                                          aria-hidden="true"
                                        ></i>
                                      </li>
                                      <li>
                                        <i
                                          class="fa fa-star-o"
                                          aria-hidden="true"
                                        ></i>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="review-send">
                                    <button>send</button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =========================== */}

      {/* Seller Review Form */}
      <section className="seller-review-form-sec">
        <div className="container"></div>
      </section>
      <Footer />
    </>
  );
}

export default Sellerstore;
