import React from "react";
import Footer from "../../Components/Footer";
import SellerHeader from "../../Components/SellerHeader";
import SellerSidebar from "../../Components/SellerSidebar";
import { customerorder1, ordersmap, productinventoryimg, reviewimg3, vegiorder1, vegiorder2, vegiorder3 } from "../../Constant/Index";

function Customerorder() {
  return (
    <>
      <SellerHeader />
      {/* Seller Customer Order here */}
      <section className="seller-dashboard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 ps-0">
              <SellerSidebar />
            </div>
            <div className="col-lg-8">
              <div className="edit-profile-card edit-no-padding-right">
                <div className="edit-heading-wrapper">
                  <h2>Customers Orders</h2>
                </div>

                <div className="seller-prdoduct-inventory-table seller-prdouct-customer-table">
                  <table>
                    <thead>
                      <th className="for-border-all-raduis">Customers</th>
                      <th>Products </th>
                      <th>Order Date</th>
                      <th>Payment Status</th>
                      <th>Total</th>
                      <th className="for-border-raduis-all-things"></th>
                    </thead>

                    <tbody>
                      <tr>
                        <td>
                          <div className="customer-order-row">
                            <img
                              className="img-fluid for-extra-border-raduis"
                              alt="customer-img"
                              src={reviewimg3}
                            />
                            <p>Amma Jhon</p>
                          </div>
                        </td>
                        <td>
                          <div className="customer-order-row">
                            <img
                              className="img-fluid for-slight-border-raduis"
                              alt="customer-img"
                              src={customerorder1}
                            />
                            <p>Amma Jhon</p>
                          </div>
                        </td>
                        <td>
                          <p className="customer-para">04-12-22</p>
                        </td>
                        <td>
                          <h5 className="customer-order-paid">
                            Paid
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </h5>
                        </td>
                        <td>
                          <h4 className="customer-order-price">$590.00</h4>
                        </td>
                        <td>
                          <button data-bs-toggle="modal"
                          data-bs-target="#exampleModal">Order Details</button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="customer-order-row">
                            <img
                              className="img-fluid for-extra-border-raduis"
                              alt="customer-img"
                              src={reviewimg3}
                            />
                            <p>Amma Jhon</p>
                          </div>
                        </td>
                        <td>
                          <div className="customer-order-row">
                            <img
                              className="img-fluid for-slight-border-raduis"
                              alt="customer-img"
                              src={customerorder1}
                            />
                            <p>Amma Jhon</p>
                          </div>
                        </td>
                        <td>
                          <p className="customer-para">04-12-22</p>
                        </td>
                        <td>
                          <h5 className="customer-order-paid">
                            Paid
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </h5>
                        </td>
                        <td>
                          <h4 className="customer-order-price">$590.00</h4>
                        </td>
                        <td>
                          <button data-bs-toggle="modal"
                          data-bs-target="#exampleModal">Order Details</button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="customer-order-row">
                            <img
                              className="img-fluid for-extra-border-raduis"
                              alt="customer-img"
                              src={reviewimg3}
                            />
                            <p>Amma Jhon</p>
                          </div>
                        </td>
                        <td>
                          <div className="customer-order-row">
                            <img
                              className="img-fluid for-slight-border-raduis"
                              alt="customer-img"
                              src={customerorder1}
                            />
                            <p>Amma Jhon</p>
                          </div>
                        </td>
                        <td>
                          <p className="customer-para">04-12-22</p>
                        </td>
                        <td>
                          <h5 className="customer-order-paid">
                            Paid
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </h5>
                        </td>
                        <td>
                          <h4 className="customer-order-price">$590.00</h4>
                        </td>
                        <td>
                          <button data-bs-toggle="modal"
                          data-bs-target="#exampleModal">Order Details</button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="customer-order-row">
                            <img
                              className="img-fluid for-extra-border-raduis"
                              alt="customer-img"
                              src={reviewimg3}
                            />
                            <p>Amma Jhon</p>
                          </div>
                        </td>
                        <td>
                          <div className="customer-order-row">
                            <img
                              className="img-fluid for-slight-border-raduis"
                              alt="customer-img"
                              src={customerorder1}
                            />
                            <p>Amma Jhon</p>
                          </div>
                        </td>
                        <td>
                          <p className="customer-para">04-12-22</p>
                        </td>
                        <td>
                          <h5 className="customer-order-paid">
                            Paid
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </h5>
                        </td>
                        <td>
                          <h4 className="customer-order-price">$590.00</h4>
                        </td>
                        <td>
                          <button data-bs-toggle="modal"
                          data-bs-target="#exampleModal">Order Details</button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="customer-order-row">
                            <img
                              className="img-fluid for-extra-border-raduis"
                              alt="customer-img"
                              src={reviewimg3}
                            />
                            <p>Amma Jhon</p>
                          </div>
                        </td>
                        <td>
                          <div className="customer-order-row">
                            <img
                              className="img-fluid for-slight-border-raduis"
                              alt="customer-img"
                              src={customerorder1}
                            />
                            <p>Amma Jhon</p>
                          </div>
                        </td>
                        <td>
                          <p className="customer-para">04-12-22</p>
                        </td>
                        <td>
                          <h5 className="customer-order-paid">
                            Paid
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </h5>
                        </td>
                        <td>
                          <h4 className="customer-order-price">$590.00</h4>
                        </td>
                        <td>
                          <button data-bs-toggle="modal"
                          data-bs-target="#exampleModal">Order Details</button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="customer-order-row">
                            <img
                              className="img-fluid for-extra-border-raduis"
                              alt="customer-img"
                              src={reviewimg3}
                            />
                            <p>Amma Jhon</p>
                          </div>
                        </td>
                        <td>
                          <div className="customer-order-row">
                            <img
                              className="img-fluid for-slight-border-raduis"
                              alt="customer-img"
                              src={customerorder1}
                            />
                            <p>Amma Jhon</p>
                          </div>
                        </td>
                        <td>
                          <p className="customer-para">04-12-22</p>
                        </td>
                        <td>
                          <h5 className="customer-order-paid">
                            Paid
                            <span>
                              <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </h5>
                        </td>
                        <td>
                          <h4 className="customer-order-price">$590.00</h4>
                        </td>
                        <td>
                          <button data-bs-toggle="modal"
                          data-bs-target="#exampleModal">Order Details</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="pagination-all">
						<nav aria-label="Page navigation example">
							<ul class="pagination">
								<li class="page-item">
									<a class="page-link" href="#" aria-label="Previous">
										<span aria-hidden="true">&laquo;</span>
									</a>
								</li>
								<li class="page-item">
									<a class="page-link" href="#">
										1
									</a>
								</li>
								<li class="page-item">
									<a class="page-link" href="#">
										2
									</a>
								</li>
								<li class="page-item">
									<a class="page-link" href="#">
										3
									</a>
								</li>
								<li class="page-item">
									<a class="page-link" href="#" aria-label="Next">
										<span aria-hidden="true">&raquo;</span>
									</a>
								</li>
							</ul>
						</nav>
					</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Seller Product Inventory ends here */}

      {/* Customer Order Detail */}
      <div className="order-total-modal">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
            <div className="responsive-close-icon">
                <i class="fa fa-times" aria-hidden="true" data-bs-dismiss="modal"></i>
              </div>
              <div class="modal-body">
                <div className="all-orders-detail-modal">
                  <div className="order-head">
                    <h2>Order #s0tq-vrp6</h2>
                    <p>Delivered on 18 Jan, 20:54</p>
                  </div>
                  <div className="all-order-img">
                    <div className="all-img-order">
                      <figure>
                        <img
                          className="img-fluid"
                          alt="order-img"
                          src={ordersmap}
                        />
                      </figure>
                    </div>
                    <div className="all-img-order-text">
                      <div className="order-text">
                        <h4>Order From</h4>
                        <p>Eat & Bite</p>
                      </div>
                      <div className="order-text order-move-up-down">
                        <h4>Delivery Address</h4>
                        <p className="for-para-width">
                          Lorem Ipsum is simply dummy text of 123ssd2 the
                          printing{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="all-img-flex">
                    <div className="img-one">
                      <figure>
                        <img
                          className="img-fluid"
                          alt="img-one"
                          src={vegiorder1}
                        />
                      </figure>
                      <div className="img-text-all">
                        <h4>Cabbage Soup</h4>
                        <h5>Qty: 2</h5>
                      </div>
                    </div>

                    <div className="order-price">
                      <h5>$158.07 </h5>
                    </div>
                  </div>
                  <div className="all-img-flex">
                    <div className="img-one">
                      <figure>
                        <img
                          className="img-fluid"
                          alt="img-one"
                          src={vegiorder2}
                        />
                      </figure>
                      <div className="img-text-all">
                        <h4>Cabbage Soup</h4>
                        <h5>Qty: 2</h5>
                      </div>
                    </div>

                    <div className="order-price">
                      <h5>$158.07 </h5>
                    </div>
                  </div>
                  <div className="all-img-flex">
                    <div className="img-one">
                      <figure>
                        <img
                          className="img-fluid"
                          alt="img-one"
                          src={vegiorder3}
                        />
                      </figure>
                      <div className="img-text-all">
                        <h4>Cabbage Soup</h4>
                        <h5>Qty: 2</h5>
                      </div>
                    </div>

                    <div className="order-price">
                      <h5>$158.07 </h5>
                    </div>
                  </div>

                  <div className="item-total">
                    <div className="order-total">
                      <h5>Item Total :</h5>
                      <h5>$50</h5>
                    </div>
                    <div className="order-total">
                      <h5>Delivery Charges </h5>
                      <h5>$0</h5>
                    </div>
                    <div className="order-total">
                      <h5>Promo Code Applied :</h5>
                      <h5>-$5</h5>
                    </div>
                  </div>

                  <div className="order-sum">
                    <h3>Order Total</h3>
                    <h4>$45</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============== */}
      <Footer />
    </>
  );
}

export default Customerorder;
