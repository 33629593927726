import React, { useState } from "react";
import Header from "../../Components/Header";
import SellerHeader from "../../Components/SellerHeader";
import SellerSidebar from "../../Components/SellerSidebar";
import { WithContext as ReactTags } from "react-tag-input";

import Select from "react-select";
import {
  sketch,
  svg1,
  svg2,
  svg3,
  svg4,
  svg5,
  upperarrow,
} from "../../Constant/Index";
import Footer from "../../Components/Footer";

function Addingproduct() {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const options = [
    { value: "color", label: "color" },
    { value: "size", label: "size" },
  ];
  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const [tags, setTags] = React.useState([]);
  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };
  const handleTagClick = (index) => {};

  const checkboxhandleclick = (event) => {
    if (event.target.checked) {
      console.log("✅ Checkbox is checked");
    } else {
      console.log("⛔️ Checkbox is NOT checked");
    }
    setIsSubscribed((current) => !current);
  };
  return (
    <>
      <SellerHeader />
      {/* Seller Adding Product starts here */}
      <section className="seller-dashboard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 ps-0">
              <SellerSidebar />
            </div>
            <div className="col-lg-8">
              <div className="edit-profile-card edit-no-padding-right adding-main-product-scroll">
                <div className="edit-heading-wrapper">
                  <h2>Adding product</h2>
                </div>

                <div className="seller-edit-with-border-dv">
                  <div className="row">
                    <div className="col-md-10">
                      <div className="edit-">
                        <div className="seller-all-form">
                          <form>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="seller-edit-form-dropdown">
                                  <select
                                    class="form-select"
                                    aria-label="Default select example"
                                  >
                                    <option selected>Select Category </option>
                                    <option value="1">Select Category </option>
                                    <option value="2">Select Category </option>
                                    <option value="3">Select Category </option>
                                  </select>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="seller-edit-form-input">
                                  <input
                                    type="text"
                                    placeholder="Product Name"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="seller-edit-form-input">
                                  <input
                                    type="text"
                                    placeholder="Stock Quantity"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="seller-edit-form-input">
                                  <input type="text" placeholder="Unit Price" />
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="seller-edit-form-input">
                                  <textarea
                                    class="form-control"
                                    id="exampleFormControlTextarea1"
                                    rows="3"
                                    placeholder="Description"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </form>

                          <div className="row">
                            {/* <div className="mcq-dv-all">
                              <h5>
                                Set Price Based On MOQ (Minimum Order Quantity)
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <div className="mcq-dv-input mcq-before">
                                <label>Enter MOQ</label>
                                <input type="text" placeholder="00" max="2" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mcq-dv-input">
                                <label>Enter Price US $</label>
                                <input type="text" placeholder="00" max="2" />
                              </div>
                            </div> */}

                            <div className="col-md-12">
                              <div className="label-and-upload-product-btn">
                                <label>Select Product Images</label>
                                <div className="upload-btn">
                                  <button>
                                    <span>
                                      <img
                                        className="img-fluid"
                                        alt="upper-arrow"
                                        src={upperarrow}
                                      />{" "}
                                      Upload Picture
                                    </span>
                                    <input type="file" />
                                  </button>
                                </div>
                              </div>
                              <div className="label-and-upload-box-shadow">
                                <div className="all-img-with-shadow">
                                  <figure>
                                    <img className="img-fluid" src={sketch} />
                                  </figure>
                                </div>
                                <div className="all-img-with-shadow">
                                  <figure>
                                    <img className="img-fluid" src={sketch} />
                                  </figure>
                                </div>
                                <div className="all-img-with-shadow">
                                  <figure>
                                    <img className="img-fluid" src={sketch} />
                                  </figure>
                                </div>
                                <div className="all-img-with-shadow">
                                  <figure>
                                    <img className="img-fluid" src={sketch} />
                                  </figure>
                                </div>
                                <div className="all-img-with-shadow">
                                  <figure>
                                    <img className="img-fluid" src={sketch} />
                                  </figure>
                                </div>
                                <div className="all-img-with-shadow">
                                  <figure>
                                    <img className="img-fluid" src={sketch} />
                                  </figure>
                                </div>
                                <div className="all-img-with-shadow">
                                  <figure>
                                    <img className="img-fluid" src={sketch} />
                                  </figure>
                                </div>
                                <div className="all-img-with-shadow">
                                  <figure>
                                    <img className="img-fluid" src={sketch} />
                                  </figure>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="all-select-dv">
                                <div className="mcq-dv-all">
                                  <h5>Add Varaitions / Attribute</h5>
                                </div>
                                <Select
                                  defaultValue={options[0]}
                                  isMulti
                                  name="colors"
                                  options={options}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                />
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="row movement-of-react-tags">
                                <div className="col-md-4">
                                  <div className="all-color-size-head">
                                    <div className="color-heading">
                                      <h5>color</h5>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-8">
                                  <div className="all-color-size-input">
                                    <div>
                                      <ReactTags
                                        tags={tags}
                                        // suggestions={suggestions}
                                        delimiters={delimiters}
                                        handleDelete={handleDelete}
                                        handleAddition={handleAddition}
                                        handleDrag={handleDrag}
                                        handleTagClick={handleTagClick}
                                        inputFieldPosition="bottom"
                                        autocomplete
                                        editable
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div>
                                {/* <label
                                  className="set-all-price"
                                  htmlFor="Set Price Based On MOQ (Minimum Order Quantity)"
                                >
                                  <input
                                    type="checkbox"
                                    value={isSubscribed}
                                    onChange={checkboxhandleclick}
                                    id="subscribe"
                                    name="Set Price Based On MOQ (Minimum Order Quantity)"
                                  />
                                  Set Price Based On MOQ (Minimum Order
                                  Quantity)
                                </label> */}

                                {/* {isSubscribed && ( */}
                                <></>
                                {/* )} */}
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="mcq-dv-all">
                                <h5>Variant, Price, and Stock</h5>
                              </div>
                              <div className="all-select-product-table">
                                <table>
                                  <thead>
                                    <th>varaiant</th>
                                    <th>varaiant prcie</th>
                                    <th>discount price</th>
                                    <th>quantity</th>
                                    <th>SKU</th>
                             
                              
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="color-size-small">
                                        color-size
                                      </td>
                                      <td>
                                        <div className="table-input">
                                          <input type="text" />
                                        </div>
                                      </td>
                                      <td>
                                        <div className="table-input">
                                          <input type="text" />
                                        </div>
                                      </td>
                                      <td>
                                        <div className="table-input">
                                          <input type="text" />
                                        </div>
                                      </td>
                                      <td>
                                        <div className="table-input">
                                          <input type="text" />
                                        </div>
                                      </td>
                                    </tr>
                                    {/* <tr>
                                      <td>size</td>
                                      <td>
                                        <div className="table-input">
                                          <input type="text" />
                                        </div>
                                      </td>
                                      <td>
                                        <div className="table-input">
                                          <input type="text" />
                                        </div>
                                      </td>
                                      <td>
                                        <div className="table-input">
                                          <input type="text" />
                                        </div>
                                      </td>
                                    </tr> */}
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="or-text">
                                <h1>OR</h1>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="row checkbox-border">
                                <div className="show-all-dv">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="all-name-and-things">
                                        <h3>Variant: Small - Black</h3>
                                        {/* <h3>Color Family:Black</h3> */}
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="check-quantity label-in-adding-prdoct">
                                        <label>qty</label>
                                        <input type="text" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="check-quantity label-in-adding-prdoct">
                                        <label>SKU</label>
                                        <input type="text" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mcq-dv-input mcq-before for-upper-margin-in-mcq">
                                        <label>Enter MOQ</label>
                                        <input
                                          type="text"
                                          placeholder="00"
                                          max="2"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mcq-dv-input for-upper-margin-in-mcq">
                                        <label>Enter Price US $</label>
                                        <input
                                          type="text"
                                          placeholder="00"
                                          max="2"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="check-box-button">
                                      <button>Add More (Max 4)</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="seller-save-btn">
                        <button>Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Seller Adding Product ends here */}
      <Footer />
    </>
  );
}

export default Addingproduct;
