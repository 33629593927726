import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/professianalplan.css";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";

function Professionalplan() {
  return (
    <>
      <Header />
      <PageHeader pagename="professional plan" />
      {/* Professianol Section */}
      <section className="professianal-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="professional-plan-form">
                <div className="professianol-plan-dv">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,
                  </p>
                </div>
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="professional-input-form">
                        <input type="text" placeholder="First Name" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="professional-input-form">
                        <input type="text" placeholder="Last Name" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="professional-input-form">
                        <input type="text" placeholder="Email Address" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="professional-input-form">
                        <input type="text" placeholder="Contact Number" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="professional-input-form">
                        <input type="text" placeholder="Store Name" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="professional-input-form">
                        <input type="password" placeholder="Password" />
                        <div className="icon-for-show-password">
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="professional-input-form">
                        <input type="password" placeholder="Confirm Password" />
                        <div className="icon-for-show-password">
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="professional-plan-input-form-btn">
                        <Link to="/payment">
                          {" "}
                          <button>Pay Now</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======================== */}
      <Footer />
    </>
  );
}

export default Professionalplan;
