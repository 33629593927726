import React from "react";
import Slider from "react-slick";

function Colorsslider() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 9,
    slidesToScroll: 1,
    arrows: true,
  };
  return (
    <>
      <div className="fabricc-all color-slider-all">
        <div className="row">
          <div className="col-md-2">
            <div className="small-slider color-sliders">
              <h4>Colors</h4>
            </div>
          </div>
          <div className="col-md-10">
            <Slider {...settings}>
              <div className="slide-color-img">
                <div className="slide-color-btn">
                  <button className="black-button"></button>
                </div>
              </div>
              <div className="slide-color-img">
                <div className="slide-color-btn">
                  <button className="sky-button"></button>
                </div>
              </div>
              <div className="slide-color-img">
                <div className="slide-color-btn">
                  <button className="sky-green-button"></button>
                </div>
              </div>
              <div className="slide-color-img">
                <div className="slide-color-btn">
                  <button className="yellow-button"></button>
                </div>
              </div>
              <div className="slide-color-img">
                <div className="slide-color-btn">
                  <button className="orange-button"></button>
                </div>
              </div>
              <div className="slide-color-img">
                <div className="slide-color-btn">
                  <button className="red-button"></button>
                </div>
              </div>
              <div className="slide-color-img">
                <div className="slide-color-btn">
                  <button className="purple-button"></button>
                </div>
              </div>
              <div className="slide-color-img">
                <div className="slide-color-btn">
                  <button className="gray-button"></button>
                </div>
              </div>
              <div className="slide-color-img">
                <div className="slide-color-btn">
                  <button className="brown-button"></button>
                </div>
              </div>
              <div className="slide-small-images"></div>
              <div className="slide-small-images"></div>
              <div className="slide-small-images"></div>
              <div className="slide-small-images"></div>
              <div className="slide-small-images"></div>
              <div className="slide-small-images"></div>
              <div className="slide-small-images"></div>
            </Slider>
          </div>
        </div>
        {/* Fabirc Slider */}
      </div>
    </>
  );
}

export default Colorsslider;
