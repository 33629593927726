import React, { useState } from "react";
import Footer from "../../Components/Footer";
import SellerHeader from "../../Components/SellerHeader";
import SellerSidebar from "../../Components/SellerSidebar";
import "../../assets/css/Seller-myprofile.css";
import { business, des, street, zipcodeimg } from "../../Constant/Index";
import { Link } from "react-router-dom";

const SellerProfile = () => {
  const [showpass, setshowpass] = useState(false);
  const handleshow = () => {
    setshowpass(true);
  };
  const hideshow = () => {
    setshowpass(false);
  };
  return (
    <>
      {/* Seller header starts here */}
      <SellerHeader />
      {/* Seller header ends here  */}
      {/* Seller profile starts here */}
      <section className="seller-dashboard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 ps-0">
              <SellerSidebar />
            </div>
            <div className="col-lg-8">
              <div className="edit-profile-card seller-reviews-tabs">
                <div className="edit-heading-wrapper">
                  <h2>My Profile</h2>
                </div>
                <div className="edit-details-wrapper">
                  <div className="detail-heading-wrapper">
                    <h3>Personal Details</h3>
                    <Link to="/seller-edit-profile">
                      <button className="edit">Edit</button>
                    </Link>
                  </div>
                  <div className="detail-content-wrapper">
                    <ul>
                      <li>
                        <span className="detail-icon">
                          <i class="fa fa-user" aria-hidden="true"></i>
                        </span>
                        <span className="txt">John eloe</span>
                      </li>
                      <li>
                        <span className="detail-icon">
                          <i class="fa fa-envelope" aria-hidden="true"></i>
                        </span>
                        <span className="txt">John Doe@mail.com</span>
                      </li>
                      <li>
                        <span className="detail-icon">
                          <i class="fa fa-phone" aria-hidden="true"></i>
                        </span>
                        <span className="txt">+1 234 567 8910</span>
                      </li>
                      <li>
                        <span className="detail-icon">
                          <i
                            class="fa fa-calendar-minus-o"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span className="txt">06-12-1986</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="edit-details-wrapper Business-detail">
                  <div className="detail-heading-wrapper">
                    <h3>Business Details</h3>
                    <Link to="/seller-edit-business-profile">
                      <button className="edit">Edit</button>
                    </Link>
                  </div>
                  <div className="detail-content-wrapper">
                    <ul>
                      <li>
                        <span className="detail-icon">
                          {/* <i class="fa fa-university" aria-hidden="true"></i> */}
                          <img className="img-fluid" src={business}/>
                        </span>
                        <span className="txt">Business Name</span>
                      </li>
                      <li>
                        <span className="detail-icon">
                          <i class="fa fa-map-marker" aria-hidden="true"></i>
                        </span>
                        <span className="txt">Address 1</span>
                      </li>
                      <li>
                        <span className="detail-icon">
                          <i class="fa fa-map-marker" aria-hidden="true"></i>
                        </span>
                        <span className="txt">Address 2</span>
                      </li>
                    </ul>
                    <div className="form-group row">
                      <div className="col-lg-6 for-no-padding">
                        <ul>
                          <li>
                            <span className="detail-icon">
                              {/* <i class="fa fa-map-signs" aria-hidden="true"></i> */}
                              <img className="img-fluid" src={street}/>
                              
                            </span>
                            <span className="txt">Street Address</span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6 for-no-padding">
                        <ul>
                          <li>
                            <span className="txt">City</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-6 for-no-padding">
                        <ul>
                          <li>
                            <span className="detail-icon">
                              <img
                                src={zipcodeimg}
                                className="img-fluid"
                                alt=""
                              />
                            </span>
                            <span className="txt">Zip Code</span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6 for-no-padding">
                        <ul>
                          <li>
                            <span className="txt">State</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <ul>
                      {/* <li>
                        <span className="detail-icon">
                          <i
                            class="fa fa-calendar-minus-o"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span className="txt">06-12-1986</span>
                      </li> */}
                      <li>
                        <span className="detail-icon">
                          <i class="fa fa-phone" aria-hidden="true"></i>
                        </span>
                        <span className="txt">+1 234 567 8910</span>
                      </li>
                      <li>
                        <span className="detail-icon">
                          <i class="fa fa-envelope" aria-hidden="true"></i>
                        </span>
                        <span className="txt">John Doe@mail.com</span>
                      </li>
                      <li>
                        <span className="detail-icon">
                          <i class="fa fa-globe" aria-hidden="true"></i>
                        </span>
                        <span className="txt">Business Website</span>
                      </li>
                      <li>
                        <span className="detail-icon">
                          <i class="fa fa-medkit" aria-hidden="true"></i>
                          {/* <img className="img-fluid" src={des}/> */}
                        </span>
                        <span className="txt">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was 1960s with the release of{" "}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="edit-details-wrapper Password-wrap">
                  <div className="detail-heading-wrapper">
                    <h3>Password</h3>
              
                 <Link to="/seller-change-password">     <button className="edit">Edit</button></Link>
                  </div>
                  <div className="detail-content-wrapper">
                    <div className="form-groups row">
                      <div
                        className="col-lg-4"
                        style={{ position: "relative" }}
                      >
                        <input
                          type={showpass ? "text" : "password"}
                          className="form-control"
                          placeholder="************"
                        />
                        <div className="eye-wrapper">
                          <span onClick={handleshow}>
                            {!showpass ? <i className="fa fa-eye"></i> : ""}
                          </span>
                          <span onClick={hideshow}>
                            {showpass ? (
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Seller profile ends here */}
      {/* Footer starts here */}
  <Footer />
      {/* Footer ends here */}
    </>
  );
};

export default SellerProfile;
