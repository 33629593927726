import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import {
  wishlist1,
  wishlist2,
  wishlist3,
  wishlist4,
} from "../../Constant/Index";

function Cart() {
	const [counter, setCounter] = useState(0);
	const [counter1, setCounter1] = useState(1);
	const [counter2, setCounter2] = useState(1);
  const [counter3, setCounter3] = useState(1);
	const handleClick1 = () => {
		// Counter state is incremented
		setCounter(counter + 1);
	};
	const handleClick2 = () => {
		// Counter state is decremented
		setCounter(counter - 1);
	};

	const handleClick3 = () => {
		// Counter state is incremented
		setCounter1(counter1 + 1);
	};
	const handleClick4 = () => {
		// Counter state is decremented
		setCounter1(counter1 - 1);
	};

	const handleClick5 = () => {
		// Counter state is incremented
		setCounter2(counter2 + 1);
	};
	const handleClick6 = () => {
		// Counter state is decremented
		setCounter2(counter2 - 1);
	};

  const handleClick7 = () => {
		// Counter state is incremented
		setCounter3(counter3 + 1);
	};
	const handleClick8 = () => {
		// Counter state is decremented
		setCounter3(counter3 - 1);
	};
  return (
    <>
    <Header />
    <PageHeader pagename="Cart" />
      {/* Wishlist Section */}
      <section className="wish-list-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="wish-list-all">
                <table className="wish-list-table">
                  <thead>
                    <th>Items</th>
                    <th>Product Name</th>
                    <th>Unit Price</th>
                    <th>Quantity</th>
                    <th>Subtotal</th>
                    <th>Action</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="img-icon">
                          <figure>
                            <img
                              className="img-fluid"
                              alt="wish-list-img"
                              src={wishlist1}
                            />
                          </figure>
                        </div>
                      </td>
                      <td>
                        <p>Cabbage Soup</p>
                      </td>
                      <td>
                        <p>
                          <span>$20</span>
                        </p>
                      </td>
                      <td>
                        {" "}
                        <div className="quantity quantity-for-cart">
                          <div className="quantity-all-stu">
                            <input value={counter} min="0" />
                            <button
                              className="btn-qunatity-1"
                              onClick={handleClick2}
                            >
                              <i class="fa fa-angle-up" aria-hidden="true"></i>
                            </button>
                            <button
                              className="btn-qunatity-2"
                              onClick={handleClick1}
                            >
                              <i
                                class="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </button>
                         
                          </div>
                        </div>
                      </td>
                      <td>
                        <p>
                          <span>$158.07</span>
                        </p>
                      </td>
                      <td>
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="img-icon">
                          <figure>
                            <img
                              className="img-fluid"
                              alt="wish-list-img"
                              src={wishlist2}
                            />
                          </figure>
                        </div>
                      </td>
                      <td>
                        <p>Cabbage Soup</p>
                      </td>
                      <td>
                        <p>
                          <span>$20</span>
                        </p>
                      </td>
                      <td>
                        {" "}
                        <div className="quantity quantity-for-cart">
                          <div className="quantity-all-stu">
                            <input value={counter1} min="0" />
                            <button
                              className="btn-qunatity-1"
                              onClick={handleClick4}
                            >
                              <i class="fa fa-angle-up" aria-hidden="true"></i>
                            </button>
                            <button
                              className="btn-qunatity-2"
                              onClick={handleClick3}
                            >
                              <i
                                class="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p>
                          <span>$158.07</span>
                        </p>
                      </td>
                      <td>
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="img-icon">
                          <figure>
                            <img
                              className="img-fluid"
                              alt="wish-list-img"
                              src={wishlist3}
                            />
                          </figure>
                        </div>
                      </td>
                      <td>
                        <p>Cabbage Soup</p>
                      </td>
                      <td>
                        <p>
                          <span>$20</span>
                        </p>
                      </td>
                      <td>
                        {" "}
                        <div className="quantity quantity-for-cart">
                          <div className="quantity-all-stu">
                            <input value={counter2} min="0" />
                            <button
                              className="btn-qunatity-1"
                              onClick={handleClick6}
                            >
                              <i class="fa fa-angle-up" aria-hidden="true"></i>
                            </button>
                            <button
                              className="btn-qunatity-2"
                              onClick={handleClick5}
                            >
                              <i
                                class="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p>
                          <span>$158.07</span>
                        </p>
                      </td>
                      <td>
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="img-icon">
                          <figure>
                            <img
                              className="img-fluid"
                              alt="wish-list-img"
                              src={wishlist4}
                            />
                          </figure>
                        </div>
                      </td>
                      <td>
                        <p>Cabbage Soup</p>
                      </td>
                      <td>
                        <p>
                          <span>$20</span>
                        </p>
                      </td>
                      <td>
                        {" "}
                        <div className="quantity quantity-for-cart">
                          <div className="quantity-all-stu">
                            <input value={counter3} min="0" />
                            <button
                              className="btn-qunatity-1"
                              onClick={handleClick8}
                            >
                              <i class="fa fa-angle-up" aria-hidden="true"></i>
                            </button>
                            <button
                              className="btn-qunatity-2"
                              onClick={handleClick7}
                            >
                              <i
                                class="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p>
                          <span>$158.07</span>
                        </p>
                      </td>
                      <td>
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row for-upper-space">
            <div className="col-md-6">
              <div className="continue-or-check-out-btn">
                <Link>Continue Shopping</Link>
                <Link to="/check-out">Checkout</Link>
              </div>
            </div>

            <div className="col-md-6">
              <div className="for-back-all">
                <div className="row">
                  <div className="col-md-6">
                    <div className="cart-text">
                      <p>Subtotal</p>
                      <p className="for-responsive-cart3">Estimated Shipping Cost</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="cart-text cart-text-two">
                      <p className="for-responsive-movment-in-cart">$ 700.00</p>
                      <p>Free Shipping</p>
                    </div>
                  </div>
                </div>

                <div className="row for-borders-row">
                  <div className="col-md-6">
                    <div className="total-all-cost">
                      <h5>TOTAL COST </h5>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="total-all-cost for-total-text">
                      <h5>$ 700.15</h5>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="continue-check-out">
                      <Link to="/check-out">Continue To Checkout</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ==================== */}
      <Footer />
    </>
  );
}

export default Cart;
