import React from "react";
import Footer from "../../Components/Footer";
import SellerHeader from "../../Components/SellerHeader";
import SellerSidebar from "../../Components/SellerSidebar";
import { des, svg1, svg2, svg3, svg4, svg5, svg6 } from "../../Constant/Index";

function Sellerbuisnesseditprofile() {
  return (
    <>
      <SellerHeader />
      {/* Seller Edit Business starts here */}
      <section className="seller-dashboard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 ps-0">
              <SellerSidebar />
            </div>
            <div className="col-lg-8">
              <div className="edit-profile-card edit-no-padding-right adding-seller-business-profile">
                <div className="edit-heading-wrapper">
                  <h2>Edit Business Profile</h2>
                </div>
                <div className="seller-edit-with-border-dv">
                <div className="row">
                  <div className="col-md-10">
                    <div className="edit-details-form">
                      <div className="seller-all-form">
                        <form>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="seller-edit-form-input">
                                <input
                                  type="text"
                                  placeholder="Business Name"
                                />
                                <div className="seller-dashboard-icon">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="icon-svg"
                                      src={svg1}
                                    />
                                  </figure>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="seller-edit-form-input">
                                <input type="text" placeholder="Address 1" />
                                <div className="seller-dashboard-icon">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="icon-svg"
                                      src={svg2}
                                    />
                                  </figure>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="seller-edit-form-input">
                                <input type="text" placeholder="Address 2" />
                                <div className="seller-dashboard-icon">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="icon-svg"
                                      src={svg2}
                                    />
                                  </figure>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="seller-edit-form-input">
                                <input
                                  type="text"
                                  placeholder="Street Address"
                                />
                                <div className="seller-dashboard-icon">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="icon-svg"
                                      src={svg3}
                                    />
                                  </figure>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="seller-edit-form-input">
                                <input type="text" placeholder="City" />
                                <div className="seller-dashboard-icon">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="icon-svg"
                                      src={svg3}
                                    />
                                  </figure>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="seller-edit-form-input">
                                <input type="text" placeholder="Zipcode" />
                                <div className="seller-dashboard-icon">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="icon-svg"
                                      src={svg4}
                                    />
                                  </figure>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="seller-edit-form-input">
                                <input type="text" placeholder="State" />
                                <div className="seller-dashboard-icon">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="icon-svg"
                                      src={svg4}
                                    />
                                  </figure>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="seller-edit-form-input">
                                <input type="text" placeholder="Phone Number" />
                                <div className="seller-dashboard-icon">
                                  <i class="fa fa-phone" aria-hidden="true"></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="seller-edit-form-input">
                                <input type="text" placeholder="Website" />
                                <div className="seller-dashboard-icon">
                                  <i
                                    class="fa fa-envelope"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="seller-edit-form-input">
                                <input
                                  type="text"
                                  placeholder="Business Email"
                                />
                                <div className="seller-dashboard-icon">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="icon-svg"
                                      src={svg5}
                                    />
                                  </figure>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="check-seller">
                                <div class="form-group">
                                  <input type="checkbox" id="bottom-check" />
                                  <label for="bottom-check">
                                    Send emails to me at lorem
                                    ipsum@business.com instead of the email
                                    address above.
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="seller-edit-form-input">
                                <textarea
                                  class="form-control"
                                  id="exampleFormControlTextarea1"
                                  rows="3"
                                  placeholder="Description"
                                ></textarea>
                                <div className="seller-dashboard-icon">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="icon-svg"
                                      src={des}
                                    />
                                  </figure>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="seller-save-btn">
                      <button>Save</button>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      {/* Seller Edit Business starts here  */}
    </>
  );
}

export default Sellerbuisnesseditprofile;
