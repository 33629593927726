import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

function Membershipslider() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="memeber-ship=slider-sec">
        <div className="container">
          <Slider {...settings}>
            <div>
              <div className="seller-center-all-membership-plan">
                <div className="seller-professional-plan">
                  <div className="seller-inner-profile-head">
                    <h3>Professional Plan</h3>
                    <div className="seller-head-profile">
                      <h5>limited sales</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod dolore magna aliqua.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="seller-who">
                    <h5>What you will get</h5>
                    <div className="seller-who-ul">
                      <ul>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat Nulla
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="seller-buy-now">
                    <Link to="/professianal-plan">
                      <button>Buy Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="seller-center-all-membership-plan">
                <div className="seller-professional-plan">
                  <div className="seller-inner-profile-head seller-different-color">
                    <h3>Basic Plan</h3>
                    <div className="seller-head-profile">
                      <h5>limited sales</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod dolore magna aliqua.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="seller-who">
                    <h5>What you will get</h5>
                    <div className="seller-who-ul">
                      <ul>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat Nulla
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="seller-buy-now seller-differt-color-btn">
                    <Link to="/professianal-plan">
                      <button>Buy Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="seller-center-all-membership-plan">
                <div className="seller-professional-plan">
                  <div className="seller-inner-profile-head">
                    <h3>upcoming Plan</h3>
                    <div className="seller-head-profile">
                      <h5>limited sales</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod dolore magna aliqua.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="seller-who">
                    <h5>What you will get</h5>
                    <div className="seller-who-ul">
                      <ul>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat Nulla
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="seller-buy-now">
                    <Link to="/professianal-plan">
                      <button>Buy Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="seller-center-all-membership-plan">
                <div className="seller-professional-plan">
                  <div className="seller-inner-profile-head seller-different-color">
                    <h3>upcoming Plan</h3>
                    <div className="seller-head-profile">
                      <h5>limited sales</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod dolore magna aliqua.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="seller-who">
                    <h5>What you will get</h5>
                    <div className="seller-who-ul">
                      <ul>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat Nulla
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="seller-buy-now seller-differt-color-btn">
                    <Link to="/professianal-plan">
                      <button>Buy Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="seller-center-all-membership-plan">
                <div className="seller-professional-plan">
                  <div className="seller-inner-profile-head">
                    <h3>Professional Plan</h3>
                    <div className="seller-head-profile">
                      <h5>limited sales</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod dolore magna aliqua.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="seller-who">
                    <h5>What you will get</h5>
                    <div className="seller-who-ul">
                      <ul>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat Nulla
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="seller-buy-now">
                    <Link to="/professianal-plan">
                      <button>Buy Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="seller-center-all-membership-plan">
                <div className="seller-professional-plan">
                  <div className="seller-inner-profile-head seller-different-color">
                    <h3>Basic Plan</h3>
                    <div className="seller-head-profile">
                      <h5>limited sales</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod dolore magna aliqua.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="seller-who">
                    <h5>What you will get</h5>
                    <div className="seller-who-ul">
                      <ul>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat Nulla
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="seller-buy-now seller-differt-color-btn">
                    <Link to="/professianal-plan">
                      <button>Buy Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="seller-center-all-membership-plan">
                <div className="seller-professional-plan">
                  <div className="seller-inner-profile-head">
                    <h3>upcoming Plan</h3>
                    <div className="seller-head-profile">
                      <h5>limited sales</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod dolore magna aliqua.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="seller-who">
                    <h5>What you will get</h5>
                    <div className="seller-who-ul">
                      <ul>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat Nulla
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="seller-buy-now">
                    <Link to="/professianal-plan">
                      <button>Buy Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="seller-center-all-membership-plan">
                <div className="seller-professional-plan">
                  <div className="seller-inner-profile-head seller-different-color">
                    <h3>upcoming Plan</h3>
                    <div className="seller-head-profile">
                      <h5>limited sales</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod dolore magna aliqua.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="seller-who">
                    <h5>What you will get</h5>
                    <div className="seller-who-ul">
                      <ul>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat Nulla
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="seller-buy-now seller-differt-color-btn">
                    <Link to="/professianal-plan">
                      <button>Buy Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
    </>
  );
}

export default Membershipslider;
