import React from "react";
import { Link } from "react-router-dom";
import { secondlogo } from "../../Constant/Index";

function Createnewpassword() {
	return (
		<>
			{/* Enter Email Section */}
			<section className="login-sec sign-in-sec create-new-password-sec">
				<div className="container">
					<div className="row">
					<div className="login-page-logo">
                  <Link to="/">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="login-page-logo"
                        src={secondlogo}
                      />
                    </figure>
                  </Link>
                </div>
						<div className="col-md-6">
						<div className="div-for-all-movement">
              
                <div className="enter-email-movement create-newpass-res">
                  <div className="welcome-back-sec">
                    <h3>Welcome Back!</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce laoreet mauris sit amet venenatis cursus. Fusce quis
                      libero orci. Donec posuere lacus id felis hendrerit
                      dapibus. Mauris
                    </p>
                    <div className="login-signin-btn">
                      <Link to="/sign-in">Sign in</Link>
                    </div>
                  </div>
                </div>
              </div>
						</div>
						<div className="col-md-6">
							<div className="login-form">
								<form>
									<div className="row">
										<div className="form-head">
											<h3>Create New Password</h3>
										</div>
										<div className="col-md-12">
											<div className="form-login-input">
												<input type="password" placeholder="Password" />
												<div className="password-icon">
													<i class="fa fa-eye-slash" aria-hidden="true"></i>
												</div>
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-login-input">
												<input type="password" placeholder="Confirm Password" />
												<div className="password-icon">
													<i class="fa fa-eye-slash" aria-hidden="true"></i>
												</div>
											</div>
										</div>

										<div className="login-signup-btn">
											<Link to="/sign-in">Submit</Link>
										</div>
									</div>
								</form>
							</div>
						</div>
					
					</div>
				</div>
			</section>
			{/* ================ */}
		</>
	);
}

export default Createnewpassword;
