import React from "react";
import {
  sellerheaderlogo,
  sellerlogo,
  sellerprofilelogo,
} from "../Constant/Index";
import "../assets/css/seller-profile.css";

const SellerHeader = () => {
  return (
    <>
      {/* seller header starts here */}
      <section className="seller-header-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3">
              <div className="seller-logo">
                <a href="#">
                  <figure>
                    <img src={sellerlogo} className="img-fluid" alt="" />
                  </figure>
                </a>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="seller-search-wrapper">
                <form action="">
                  <div className="form-group row">
                    <input type="text" className="form-control" />
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="seller-profile-logo-wrapper">
                <a href="#">
                  <figure>
                    <img src={sellerheaderlogo} className="img-fluid" alt="" />
                  </figure>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="seller-profile-name-wrapper">
                <div className="seller-img-wrapper">
                  <figure>
                    <img src={sellerprofilelogo} className="img-fluid" alt="" />
                  </figure>
                </div>
                <div className="seller-content-wrapper">
                    <h4>Adek Kember</h4>
                    <h6>Premium Seller</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3"></div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </section>
      {/* seller header ends here */}
    </>
  );
};

export default SellerHeader;
