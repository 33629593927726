import React, { useState } from 'react'
import Slider from 'react-slick';
import { customizeproduct1, customizeproduct2, customizeproduct3, customizeproduct5, customizeproduct6, customizeproductmain } from '../Constant/Index';

function Slide2() {
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
  return (
    <>
         <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
        <div className="all-borders">
          <img className="img-fluid" src={customizeproduct1} />
        </div>
        <div className="all-borders">
          <img className="img-fluid" src={customizeproduct2} />
        </div>
        <div className="all-borders">
          <img className="img-fluid" src={customizeproduct3} />
        </div>
        <div className="all-borders">
          <img className="img-fluid" src={customizeproduct5} />
        </div>
        <div className="all-borders">
          <img className="img-fluid" src={customizeproduct6} />
        </div>
        <div className="all-borders">
          <img className="img-fluid" src={customizeproductmain} />
        </div>
      </Slider>

      <Slider
        asNavFor={nav1}
        ref={(slider2) => setNav2(slider2)}
        slidesToShow={5}
        swipeToSlide={true}
        focusOnSelect={true}
        arrows={false}
      >
        <div className="slide-small-images">
          <img className="img-fluid on-small-border"  src={customizeproduct1} />
        </div>
        <div >
          <img className="img-fluid on-small-border" src={customizeproduct2} />
        </div>
        <div > 
          <img className="img-fluid on-small-border" src={customizeproduct3} />
        </div>
        <div>
          <img className="img-fluid on-small-border" src={customizeproduct5} />
        </div>
        <div > 
          <img className="img-fluid on-small-border" src={customizeproduct6} />
        </div>
        <div >
          <img className="img-fluid on-small-border" src={customizeproductmain} />
        </div>
      </Slider>
    </>
  )
}

export default Slide2
