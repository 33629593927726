import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";

function Sellercreatepassword() {
  return (
    <>
    <Header />
      {/* Seller Forget Sec */}
      <section className="seller-forget-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="seller-forget-form">
                <div className="seller-forget-head">
                  <h3>Create New Password</h3>
                  
                </div>
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="seller-forget-input">
                        <input type="password" />
                        <div className="password-icon sell-pass">
													<i class="fa fa-eye-slash" aria-hidden="true"></i>
												</div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="seller-forget-input">
                        <input type="password" />
                        <div className="password-icon sell-pass">
													<i class="fa fa-eye-slash" aria-hidden="true"></i>
												</div>
                      </div>
                    </div>

                    <div className="seller-forget-btn">
                      <Link>send</Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ================== */}
      <Footer />
    </>
  );
}

export default Sellercreatepassword;
