import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import { check } from "../../Constant/Index";

function Payment() {
  return (
    <>
      <Header />
      <PageHeader pagename="payment details" />
      <div className="payment-top-para">
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p>
      </div>

      {/* Payment Seciton */}
      <section className="payment-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="payment-all">
                <div className="payment-head-all">
                  <h3>add credit/debit card</h3>
                </div>

                <div className="payment-all-input-things">
                  <form>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="payment-all-input">
                          <input type="text" placeholder="Card Number" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="payment-all-input">
                          <input type="text" placeholder="Expiry" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="payment-all-input">
                          <input type="text" placeholder="CVC" />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="payment-default-all-thnks">
                <div className="payment-head-all">
                  <h3>Order Summary</h3>
                </div>
                <div className="payemnt-all-stuff">
                  <div className="payment-all-first-heading">
                    <h4>Payment Details</h4>
                  </div>
                  <div className="payment-professinal-plan">
                    <div className="payment-all-the-text">
                      <h4>
                        Professional <br /> Plan
                      </h4>
                      <div className="pay-para">
                        <p>Professional Plan</p>
                        <p>
                          <span>Lorem ipsum</span>
                        </p>
                      </div>
                    </div>
                    <div className="payment-tag">
                      <h5>$500</h5>
                    </div>
                  </div>

                  <div className="payment-total-dv">
                    <div className="payment-total-head">
                      <h3>Total Payable</h3>
                    </div>
                    <div className="payment-total-tag">
                      <h5>$500</h5>
                    </div>
                  </div>
                </div>
                <div className="payment-total-btn">
                  <button data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======================= */}
      <Footer />

    {/* Payment Thankyou Modal  */}
	<div className="payment-thankyou">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog for-modal-in-middle">
            <div class="modal-content">
              <div class="modal-body">
                <div className="thank-you-modal">
                  <h3>Seller Successfully Registered</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                  <div className="payment-thank-you-icon">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="payment-icon"
                        src={check}
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
	  {/* ============================ */}

    </>
  );
}

export default Payment;
