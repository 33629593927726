import React, { useState } from 'react'
import Slider from 'react-slick';
import { detailslider, detailslider2, detailslider3, detailslider4 } from '../Constant/Index';

function Slide() {
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
  return (
    <div>
      <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
        <div className="all-borders">
          <img className="img-fluid" src={detailslider} />
        </div>
        <div className="all-borders">
          <img className="img-fluid" src={detailslider2} />
        </div>
        <div className="all-borders">
          <img className="img-fluid" src={detailslider3} />
        </div>
        <div className="all-borders">
          <img className="img-fluid" src={detailslider} />
        </div>
        <div className="all-borders">
          <img className="img-fluid" src={detailslider2} />
        </div>
        <div className="all-borders">
          <img className="img-fluid" src={detailslider4} />
        </div>
      </Slider>

      <Slider
        asNavFor={nav1}
        ref={(slider2) => setNav2(slider2)}
        slidesToShow={5}
        swipeToSlide={true}
        focusOnSelect={true}
        arrows={false}
      >
        <div className="slide-small-images">
          <img className="img-fluid on-small-border"  src={detailslider} />
        </div>
        <div >
          <img className="img-fluid on-small-border" src={detailslider2} />
        </div>
        <div > 
          <img className="img-fluid on-small-border" src={detailslider3} />
        </div>
        <div>
          <img className="img-fluid on-small-border" src={detailslider} />
        </div>
        <div > 
          <img className="img-fluid on-small-border" src={detailslider4} />
        </div>
        <div >
          <img className="img-fluid on-small-border" src={detailslider3} />
        </div>
      </Slider>
    </div>
  )
}

export default Slide
