import React from "react";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import { blogdetailimg } from "../../Constant/Index";
import "../../assets/css/blogdetail.css";
import Footer from "../../Components/Footer";

function Blogdetail() {
  return (
    <>
      <Header />
      <PageHeader pagename="Blogs" />
      {/* Blog Detail Section */}
      <section className="blog-detail-sec">
        <div className="container">
          <div className="blog-detail-img">
            <h4>Lorem ipsum dolor sit </h4>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="blog-detail-all">
                <div className="blog-detail-img">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="blog-detail-img"
                      src={blogdetailimg}
                    />
                  </figure>
                </div>
                <div className="blog-detail-text">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Fusce laoreet mauris sit amet venenatis cursus. Fusce quis
                    libero orci. Donec posuere lacus id felis hendrerit dapibus.
                    Mauris luctus lcus scelerisque nunc mollis viverra.
                    Pellentesque ultricies sed tellus vitae mollis. Donec quis
                    volutpat leo, nec imperdiet erat. Aliquam sollicitudin
                    congue vulputate. Class aptent taciti sociosqu ad litora
                    torquent per conubia nostra, per inceptos himenaeos. Donec
                    auctor ligula at eros luctus tempor. Vivamus eu ligula vitae
                    mauris ornare sodales. Etiam ut sodales sapien. Donec vitae
                    lectus sed dolor finibus blandit. Suspendisse potenti. Nunc
                    vulputate viverra mi sit amet viverra.
                  </p>

                  <p>
                    Suspendisse pretium ante sit amet turpis iaculis imperdiet.
                    Nullam at ullamcorper massa. Integer ac orci vitae lectus
                    porttitor lacinia. Donec quis lacinia justo. Pellentesque a
                    pellentesque dui. Mauris suscipit varius nibh quis pretium.
                    Morbi cursus, nisl a tincidunt gravida, diam urna hendrerit
                    nibh, laoreet feugiat mauris est eu tellus. Sed rhoncus
                    porttitor mauris ac gravida. Aenean at purus non sapien
                    tristique posuere id accumsan magna. Fusce imperdiet quam eu
                    enim sodales, in imperdiet odio vehicula. Sed egestas, ipsum
                    sit amet dapibus gravida, nulla eros dapibus lacus, id
                    feugiat mauris sapien ut nibh. Pellentesque porta, sem et
                    fringilla mollis, neque nibh tincidunt ipsum, ut congue erat
                    est et arcu. Proin et viverra metus. Donec porta, sem eget
                    vestibulum venenatis, velit tellus fermentum tellus, at
                    gravida neque nunc vitae sapien. Fusce ut orci eros. Proin
                    ullamcorper velit a enim mollis, quis auctor leo ultricies.
                    Nunc non scelerisque ante. Etiam euismod tortor eget
                    malesuada fringilla. Nullam ut semper magna, dapibus rutrum
                    elit. Donec finibus velit ac molestie vulputate. Vestibulum
                    sollicitudin magna massa, a rutrum metus egestas non.
                    Suspendisse molestie enim et commodo luctus. Nam elementum,
                    nulla ut hendrerit consectetur, diam tortor mollis elit, ac
                    interdum felis felis non mauris. Sed id tempor purus, sit
                    amet consectetur dui. Morbi et nisi massa. Morbi interdum
                    nunc eget erat porta lacinia. Mauris ornare luctus interdum.
                    Nullam viverra blandit nisi, quis mollis mi venenatis sit
                    amet. Vivamus enim turpis, hendrerit ut augue eu, tincidunt
                    bibendum neque. Aenean bibendum aliquet risus sed sagittis.
                    Praesent bibendum tristique nibh, vitae hendrerit tortor.
                    Maecenas ornare ex sed neque placerat, laoreet elementum
                    ante sodales. Donec facilisis, mauris vel efficitur aliquet,
                    neque purus cursus erat, non viverra felis felis at neque.
                    Duis sem augue, congue a viverra vitae, mattis in metus.
                    Mauris eu eleifend tellus. Vestibulum fermentum tincidunt
                    odio, egestas vulputate diam dapibus quis. Curabitur et
                    varius enim. Praesent lectus velit, rutrum at pellentesque
                    ac, convallis nec nulla. In vehicula ante nulla, in placerat
                    elit accumsan vel. Suspendisse at orci nec ligula vehicula
                    tincidunt vel sit amet arcu. Cras ac nisl odio. Proin quis
                    mauris cursus, congue felis quis, luctus ligula.
                  </p>

                  <p>
                    Suspendisse pretium ante sit amet turpis iaculis imperdiet.
                    Nullam at ullamcorper massa. Integer ac orci vitae lectus
                    porttitor lacinia. Donec quis lacinia justo. Pellentesque a
                    pellentesque dui. Mauris suscipit varius nibh quis pretium.
                    Morbi cursus, nisl a tincidunt gravida, diam urna hendrerit
                    nibh, laoreet feugiat mauris est eu tellus. Sed rhoncus
                    porttitor mauris ac gravida. Aenean at purus non sapien
                    tristique posuere id accumsan magna. Fusce imperdiet quam eu
                    enim sodales, in imperdiet odio vehicula. Sed egestas, ipsum
                    sit amet dapibus gravida, nulla eros dapibus lacus, id
                    feugiat mauris sapien ut nibh. Pellentesque porta, sem et
                    fringilla mollis, neque nibh tincidunt ipsum, ut congue erat
                    est et arcu. Proin et viverra metus. Donec porta, sem eget
                    vestibulum venenatis, velit tellus fermentum tellus, at
                    gravida neque nunc vitae sapien. Fusce ut orci eros. Proin
                    ullamcorper velit a enim mollis, quis auctor leo ultricies.
                    Nunc non scelerisque ante. Etiam euismod tortor eget
                    malesuada fringilla. Nullam ut semper magna, dapibus rutrum
                    elit. Donec finibus velit ac molestie vulputate. Vestibulum
                    sollicitudin magna massa, a rutrum metus egestas non.
                    Suspendisse molestie enim et commodo luctus. Nam elementum,
                    nulla ut hendrerit consectetur, diam tortor mollis elit, ac
                    interdum felis felis non mauris. Sed id tempor purus, sit
                    amet consectetur dui. Morbi et nisi massa. Morbi interdum
                    nunc eget erat porta lacinia. Mauris ornare luctus interdum.
                    Nullam viverra blandit nisi, quis mollis mi venenatis sit
                    amet. Vivamus enim turpis, hendrerit ut augue eu, tincidunt
                    bibendum neque. Aenean bibendum aliquet risus sed sagittis.
                    Praesent bibendum tristique nibh, vitae hendrerit tortor.
                    Maecenas ornare ex sed neque placerat, laoreet elementum
                    ante sodales. Donec facilisis, mauris vel efficitur aliquet,
                    neque purus cursus erat, non viverra felis felis at neque.
                    Duis sem augue, congue a viverra vitae, mattis in metus.
                    Mauris eu eleifend tellus. Vestibulum fermentum tincidunt
                    odio, egestas vulputate diam dapibus quis. Curabitur et
                    varius enim. Praesent lectus velit, rutrum at pellentesque
                    ac, convallis nec nulla. In vehicula ante nulla, in placerat
                    elit accumsan vel. Suspendisse at orci nec ligula vehicula
                    tincidunt vel sit amet arcu. Cras ac nisl odio. Proin quis
                    mauris cursus, congue felis quis, luctus ligula.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ===================== */}
      <Footer />
    </>
  );
}

export default Blogdetail;
