import React from "react";
import { Link } from "react-router-dom";
import { secondlogo } from "../../Constant/Index";

function Signin() {
  return (
    <>
      {/* Sign-in Section */}
      <section className="login-sec sign-in-sec">
        <div className="container">
          <div className="row">
          <div className="login-page-logo">
                <Link to="/">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="login-page-logo"
                      src={secondlogo}
                    />
                  </figure>
                </Link>
              </div>
            <div className="col-md-6">
           
              <div className="welcome-back-movement welcome-back-signin-movement">
                <div className="welcome-back-sec welcome-back-sec-signin">
                  <h3>Hello, Friend!</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Fusce laoreet mauris sit amet venenatis cursus. Fusce quis
                    libero orci. Donec posuere lacus id felis hendrerit dapibus.
                    Mauris
                  </p>
                  <div className="login-signin-btn">
                    <Link to="/sign-up">Sign Up</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="login-form">
                <form>
                  <div className="row">
                    <div className="form-head">
                      <h3>Sign In</h3>
                    </div>
                    <div className="col-md-12">
                      <div className="form-login-input">
                        <input type="email" placeholder="Email" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-login-input">
                        <input type="password" placeholder="Password" />
                        <div className="password-icon">
                          <i class="fa fa-eye-slash" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="remeber-forget">
                        <div className="remeber-check-box">
                          <input type="checkbox" value="yes" />
                          <label for="vehicle1">Remember Me</label>
                        </div>
                        <div className="forgot-password-link">
                          <Link to="/enter-email">Forgot Password?</Link>
                        </div>
                      </div>
                    </div>
                    <div className="login-signup-btn">
                      <Link>Sign In</Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-12"></div>
          </div>
        </div>
      </section>
      {/* ================ */}
    </>
  );
}

export default Signin;
