import React from "react";
import { Link } from "react-router-dom";
import { secondlogo } from "../../Constant/Index";

function Enteremail() {
  return (
    <>
      {/* Enter Email Section */}
      <section className="login-sec sign-in-sec enter-email-sec">
        <div className="container">
          <div className="row">
            <div className="login-page-logo">
              <Link to="/">
                <figure>
                  <img
                    className="img-fluid"
                    alt="login-page-logo"
                    src={secondlogo}
                  />
                </figure>
              </Link>
            </div>
            <div className="col-md-6">
              <div className="div-for-all-movement">
                <div className="enter-email-movement">
                  <div className="welcome-back-sec">
                    <h3>Welcome Back!</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce laoreet mauris sit amet venenatis cursus. Fusce quis
                      libero orci. Donec posuere lacus id felis hendrerit
                      dapibus. Mauris
                    </p>
                    <div className="login-signin-btn">
                      <Link to="/sign-in">Sign in</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="login-form">
                <form>
                  <div className="row">
                    <div className="form-head">
                      <h3>Enter Your Email</h3>
                      <p>Please Enter Your Email Below</p>
                    </div>
                    <div className="col-md-12">
                      <div className="form-login-input">
                        <input type="email" placeholder="Email" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="enter-email-para">
                        <p>
                          An Email Has Been Sent To Reset The Password Haven't
                          Received The Email Yet? Try Again
                        </p>
                      </div>
                    </div>

                    <div className="login-signup-btn">
                      <Link to="/enter-otp">Send</Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ================ */}
    </>
  );
}

export default Enteremail;
