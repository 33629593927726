import React from "react";
import "../../assets/css/contact.css";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";

function Contact() {
  return (
    <>
      <Header />
      <PageHeader pagename="Contact Us" />
      {/* Contact Seciton */}
      <section className="contact-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="contact-form">
                <form>
                  <div className="row">
                    <div className="contact-form-para">
                      <p>Let Us Answer All Your Questions </p>
                    </div>
                    <div className="col-md-6">
                      <div className="contact-form-input">
                        <input type="text" placeholder="First Name" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="contact-form-input">
                        <input type="text" placeholder="Last Name" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="contact-form-input">
                        <input type="email" placeholder="Email Address" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="contact-form-input">
                        <input type="text" placeholder="Contact Number" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="contact-form-input">
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          placeholder="Message"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="contact-btn">
                        <button>Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="contact-icon-text-all">
            <div className="row">
              <div className="col-md-4">
                <div className="contact-icon-text contact-for-right">
                  <div className="contact-icon">
                    <i class="fa fa-phone" aria-hidden="true"></i>
                  </div>
                  <div className="contact-text">
                    <a href="tel:1-234-5678-901">1-234-5678-901</a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contact-icon-text contact-for-left">
                  <div className="contact-icon">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>

                  </div>
                  <div className="contact-text">
                    <p>
                      7468 Mrakus St <br /> Gotham, MI 54654
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contact-icon-text">
                  <div className="contact-icon">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                  </div>
                  <div className="contact-text">
                    <a href="mailto:Info@sharaeagle.com">Info@sharaeagle.com</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ====================== */}
      <Footer />
    </>
  );
}

export default Contact;
