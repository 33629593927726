import React from "react";
import Slider from "react-slick";
import { fabricslider17, fabricslider18 } from "../Constant/Index";

function Embroidery() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <>
      <div className="fabricc-all Embroidery-all">
        <div className="row">
          <div className="col-md-2">
            <div className="small-slider small-embroidery-slider">
              <h4>Embroidery</h4>
            </div>
          </div>
          <div className="col-md-10">
            <Slider {...settings}>
              <div className="slide-small-images">
                <img className="img-fluid" src={fabricslider17} />
              </div>
              <div className="slide-small-images">
                <img className="img-fluid" src={fabricslider18} />
              </div>
              <div className="slide-small-images"></div>
              <div className="slide-small-images"></div>
              <div className="slide-small-images"></div>
              <div className="slide-small-images"></div>
              <div className="slide-small-images"></div>
              <div className="slide-small-images"></div>
              <div className="slide-small-images"></div>
              <div className="slide-small-images"></div>
            </Slider>
          </div>
        </div>
        {/* Fabirc Slider */}
      </div>
    </>
  );
}

export default Embroidery;
