import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";

function Sellerotp() {
  return (
    <>
      <Header />
      {/* Seller Forget Sec */}
      <section className="seller-forget-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="seller-forget-form">
                <div className="seller-forget-head">
                  <h3>enter your OTP</h3>
                  <p>
                  Please Enter The Confirmation Code
                  </p>
                </div>
                <form>
                  <div className="row">
                    <div className="col-md-2">
                      <div className="seller-forget-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="seller-forget-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="seller-forget-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="seller-forget-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="seller-forget-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="seller-forget-input">
                        <input type="text" />
                      </div>
                    </div>
                    <div className="seller-forget-btn">
                      <Link to="/change-password-for-seller">send</Link>
                    </div>
                    <div className="seller-again">
                        <Link>send again</Link>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ================== */}
      <Footer />
    </>
  );
}

export default Sellerotp;
