import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import {
  chestpocket,
  chestpocket2,
  customize1,
  customizenew1,
  customizesleves1,
  customizesleves2,
  mancustomize,
} from "../../Constant/Index";

function Customizedesign() {
  return (
    <>
      <Header />
      {/* Customize Design Section */}
      <section className="customize-design-sec">
        <div className="container">
          <div className="row">
            <PageHeader pagename="customization" />
            <div className="custom-design-tabs">
              <div class="accordion" id="accordionPanelsStayOpenExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseOne"
                      aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseOne"
                    >
                      Suit
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-headingOne"
                  >
                    <div class="accordion-body">
                      <div class="accordion-item">
                        <h2
                          class="accordion-header"
                          id="panelsStayOpen-headingTwo"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseTwo"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapseTwo"
                          >
                            Collar and Cuff Construction
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingTwo"
                        >
                          <div class="accordion-body">
                            <div className="customer-tab-all-stuff">
                              <p>
                                Standard collars and cuffs are fused to maintain
                                their rigidity and work well for business or
                                dress attire. Soft collars and cuffs contain no
                                fusing for a more casual look and feel.
                              </p>

                              <div className="customize-all-imgs">
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={customizenew1}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>Fused (Standard)</p>
                                  </div>
                                </div>
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={customizenew1}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>Fused (Standard)</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2
                          class="accordion-header"
                          id="panelsStayOpen-headingTwo"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapsesleeves"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapsesleeves"
                          >
                            Sleeves Length
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapsesleeves"
                          class="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingsleeves"
                        >
                          <div class="accordion-body">
                            <div className="customer-tab-all-stuff">
                              <p>
                                Standard collars and cuffs are fused to maintain
                                their rigidity and work well for business or
                                dress attire. Soft collars and cuffs contain no
                                fusing for a more casual look and feel.
                              </p>

                              <div className="customize-all-imgs">
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={customizesleves1}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>Short Sleeves</p>
                                  </div>
                                </div>
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={customizesleves2}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>Long Sleeves</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2
                          class="accordion-header"
                          id="panelsStayOpen-headingpocket"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapsepocket"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapsepocket"
                          >
                            Chest Pocket
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapsepocket"
                          class="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingpocket"
                        >
                          <div class="accordion-body">
                            <div className="customer-tab-all-stuff">
                              <p>
                                Add a chest pocket of your choice to your shirt
                              </p>

                              <div className="customize-all-imgs">
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={chestpocket}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>Patch Pocket</p>
                                  </div>
                                </div>
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={chestpocket2}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>No Pocket</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseThree"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseThree"
                    >
                      Jacket
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingThree"
                  >
                    <div class="accordion-body">
                    <div class="accordion-item">
                        <h2
                          class="accordion-header"
                          id="panelsStayOpen-headingTwo"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapsefour"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapsefour"
                          >
                            Collar and Cuff Construction
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapsefour"
                          class="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingTwo"
                        >
                          <div class="accordion-body">
                            <div className="customer-tab-all-stuff">
                              <p>
                                Standard collars and cuffs are fused to maintain
                                their rigidity and work well for business or
                                dress attire. Soft collars and cuffs contain no
                                fusing for a more casual look and feel.
                              </p>

                              <div className="customize-all-imgs">
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={customizenew1}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>Fused (Standard)</p>
                                  </div>
                                </div>
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={customizenew1}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>Fused (Standard)</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2
                          class="accordion-header"
                          id="panelsStayOpen-headingTwo"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapsefive"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapsefive"
                          >
                            Sleeves Length
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapsefive"
                          class="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingsleeves"
                        >
                          <div class="accordion-body">
                            <div className="customer-tab-all-stuff">
                              <p>
                                Standard collars and cuffs are fused to maintain
                                their rigidity and work well for business or
                                dress attire. Soft collars and cuffs contain no
                                fusing for a more casual look and feel.
                              </p>

                              <div className="customize-all-imgs">
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={customizesleves1}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>Short Sleeves</p>
                                  </div>
                                </div>
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={customizesleves2}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>Long Sleeves</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2
                          class="accordion-header"
                          id="panelsStayOpen-headingpocket"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapsesix"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapsesix"
                          >
                            Chest Pocket
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapsesix"
                          class="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingpocket"
                        >
                          <div class="accordion-body">
                            <div className="customer-tab-all-stuff">
                              <p>
                                Add a chest pocket of your choice to your shirt
                              </p>

                              <div className="customize-all-imgs">
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={chestpocket}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>Patch Pocket</p>
                                  </div>
                                </div>
                                <div className="img-customize-dv">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="customize-img"
                                      src={chestpocket2}
                                    />
                                  </figure>
                                  <div className="customization-dv-text">
                                    <p>No Pocket</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="get-quote-head">
                    <h5>Provide measurements</h5>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mcq-dv-input get-quote-mcq-dv-input">
                    <label>Length</label>
                    <input type="text" placeholder="00" max="2" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mcq-dv-input get-quote-mcq-dv-input">
                    <label>Waist</label>
                    <input type="text" placeholder="00" max="2" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mcq-dv-input get-quote-mcq-dv-input">
                    <label>Hip</label>
                    <input type="text" placeholder="00" max="2" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mcq-dv-input get-quote-mcq-dv-input">
                    <label>Shoulder</label>
                    <input type="text" placeholder="00" max="2" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mcq-dv-input get-quote-mcq-dv-input">
                    <label>Sleeves</label>
                    <input type="text" placeholder="00" max="2" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mcq-dv-input get-quote-mcq-dv-input">
                    <label>Armhole</label>
                    <input type="text" placeholder="00" max="2" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mcq-dv-input get-quote-mcq-dv-input">
                    <label>Collar Size</label>
                    <input type="text" placeholder="00" max="2" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mcq-dv-input get-quote-mcq-dv-input">
                    <label>Cuff Size </label>
                    <input type="text" placeholder="00" max="2" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="customize-text-area">
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder="Additional Information "
                ></textarea>
              </div>
            </div>
            <div className="col-md-12">
              <div className="customize-btn">
                <button data-bs-toggle="modal" data-bs-target="#exampleModal">
                  Save Customiazations
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      {/* Customize Modal Css */}
      <div className="product-customize-all-things">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl for-modal-in-middle">
            <div class="modal-content">
              <div class="modal-body">
              <div className="responsive-close-icon">
                <i class="fa fa-times" aria-hidden="true" data-bs-dismiss="modal"></i>
              </div>
                <div className="custom-modal-heading">
                  <h3>customization</h3>
                </div>
                <div className="customization-all-things">
                  <div className="customization-dv">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="customzation-image">
                          <figure>
                            <img
                              className="img-fluid"
                              alt="customization-img"
                              src={mancustomize}
                            />
                          </figure>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="traditional-dress">
                          <h4>African Traditional Dress</h4>
                          <h5>USD $549</h5>
                          <p>
                            Color: <span>Blue</span>
                          </p>
                          <p>
                            Pattern <span>Solid</span>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="you-shop-allow">
                          <h4>
                            Your Shop <span> (3 Items)</span>
                          </h4>
                          <div className="sub-total-all">
                            <span>
                              {" "}
                              <h5>Sub-total</h5>
                            </span>
                            <span>
                              <h5>$1,197.00</h5>
                            </span>
                          </div>
                          <div className="sub-total-all">
                            <span>
                              {" "}
                              <h5>Shipping</h5>
                            </span>
                            <span>
                              <h5>Free</h5>
                            </span>
                          </div>
                          <div className="sub-total-all">
                            <span>
                              {" "}
                              <h5>
                                Measurement Appointment <br />
                                *Sales tax applied at checkout
                              </h5>
                            </span>
                            <span>
                              <h5>Free</h5>
                            </span>
                          </div>
                          <div className="estimated-total">
                            <span>
                              {" "}
                              <h5>Estimated Total</h5>
                            </span>
                            <span>
                              <h5>$1,197.00</h5>
                            </span>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="traditional-button">
                            <button>add to cart</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ======================== */}
    </>
  );
}

export default Customizedesign;
