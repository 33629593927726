import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import SellerHeader from "../../Components/SellerHeader";
import SellerSidebar from "../../Components/SellerSidebar";
import { zipcodeimg } from "../../Constant/Index";

function Sellereditprofie() {
  return (
    <>
      <SellerHeader />
      {/* Seller profile starts here */}
      <section className="seller-dashboard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 ps-0">
              <SellerSidebar />
            </div>
            <div className="col-lg-8">
              <div className="edit-profile-card edit-no-padding-right">
                <div className="edit-heading-wrapper">
                  <h2>Edit Personal Details</h2>
                </div>

                <div className="seller-edit-with-border-dv">
                  <div className="row">
                    <div className="col-md-10">
                      <div className="edit-details-form">
                        <div className="seller-all-form">
                          <form>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="seller-edit-form-input">
                                  <input type="text" placeholder="First Name" />
                                  <div className="seller-dashboard-icon">
                                    <i
                                      class="fa fa-user"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="seller-edit-form-input">
                                  <input type="text" placeholder="Last Name" />
                                  <div className="seller-dashboard-icon">
                                    <i
                                      class="fa fa-user"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="seller-edit-form-input">
                                  <input type="text" placeholder="Email" />
                                  <div className="seller-dashboard-icon">
                                    <i
                                      class="fa fa-envelope"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="seller-edit-form-input">
                                  <input type="text" placeholder="Phone" />
                                  <div className="seller-dashboard-icon">
                                    <i
                                      class="fa fa-phone"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="seller-edit-form-input">
                                  <input type="text" placeholder="DOB" />
                                  <div className="seller-dashboard-icon">
                                    <i
                                      class="fa fa-calendar-minus-o"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="seller-save-btn">
                        <button>Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Seller profile ends here */}
      <Footer />
    </>
  );
}

export default Sellereditprofie;
