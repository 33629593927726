import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import {
	wishlist1,
	wishlist2,
	wishlist3,
	wishlist4,
} from "../../Constant/Index";
import "../../assets/css/wishlist.css";
import PageHeader from "../../Components/Pageheader";
import { Link } from "react-router-dom";

function Wishlist() {
	return (
		<>
			<Header />
			<PageHeader pagename="Wishlist" />
			{/* Wishlist Section */}
			<section className="wish-list-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="wish-list-all">
								<div className="table-responsive">
									<table className="wish-list-table table">
										<thead>
											<th>
												<span>Items </span>
											</th>
											<th>
												<span>Product Name </span>
											</th>
											<th>
												<span>Unit Price </span>
											</th>
											<th>
												<span>Stock Status </span>
											</th>
											<th>
												<span>Action </span>
											</th>
										</thead>
										<tbody>
											<tr className="no-border-in-bottom">
												<td>
													<div className="img-icon">
														<i class="fa fa-times" aria-hidden="true"></i>

														<figure>
															<img
																className="img-fluid"
																alt="wish-list-img"
																src={wishlist1}
															/>
														</figure>
													</div>
												</td>
												<td>
													<p>Cabbage Soup</p>
												</td>
												<td>
													<p>
														<span>$20</span>
													</p>
												</td>
												<td>
													<p>In-Stock</p>
												</td>
												<td>
													<Link to="/cart">Add To Cart</Link>
												</td>
											</tr>
											<tr className="no-border-in-bottom">
												<td>
													<div className="img-icon">
														<i class="fa fa-times" aria-hidden="true"></i>

														<figure>
															<img
																className="img-fluid"
																alt="wish-list-img"
																src={wishlist2}
															/>
														</figure>
													</div>
												</td>
												<td>
													<p>Cabbage Soup</p>
												</td>
												<td>
													<p>
														<span>$20</span>
													</p>
												</td>
												<td>
													<p>In-Stock</p>
												</td>
												<td>
													<Link to="/cart">Add To Cart</Link>
												</td>
											</tr>
											<tr className="no-border-in-bottom">
												<td>
													<div className="img-icon">
														<i class="fa fa-times" aria-hidden="true"></i>

														<figure>
															<img
																className="img-fluid"
																alt="wish-list-img"
																src={wishlist3}
															/>
														</figure>
													</div>
												</td>
												<td>
													<p>Cabbage Soup</p>
												</td>
												<td>
													<p>
														<span>$20</span>
													</p>
												</td>
												<td>
													<p>In-Stock</p>
												</td>
												<td>
													<Link to="/cart">Add To Cart</Link>
												</td>
											</tr>
											<tr  >
												<td>
													<div className="img-icon">
														<i class="fa fa-times" aria-hidden="true"></i>

														<figure>
															<img
																className="img-fluid"
																alt="wish-list-img"
																src={wishlist4}
															/>
														</figure>
													</div>
												</td>
												<td>
													<p>Cabbage Soup</p>
												</td>
												<td>
													<p>
														<span>$20</span>
													</p>
												</td>
												<td>
													<p>In-Stock</p>
												</td>
												<td>
													<Link to="/cart">Add To Cart</Link>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="pagination-all">
					<nav aria-label="Page navigation example">
						<ul class="pagination">
							<li class="page-item">
								<a class="page-link" href="#" aria-label="Previous">
									<span aria-hidden="true">&laquo;</span>
								</a>
							</li>
							<li class="page-item">
								<a class="page-link" href="#">
									1
								</a>
							</li>
							<li class="page-item">
								<a class="page-link" href="#">
									2
								</a>
							</li>
							<li class="page-item">
								<a class="page-link" href="#">
									3
								</a>
							</li>
							<li class="page-item">
								<a class="page-link" href="#" aria-label="Next">
									<span aria-hidden="true">&raquo;</span>
								</a>
							</li>
						</ul>
					</nav>
				</div>
			</section>
			{/* ==================== */}
			<Footer />
		</>
	);
}

export default Wishlist;
