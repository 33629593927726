import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "../About/About";
import Blog from "../Blog/Blog";
import Blogdetail from "../Blog/Blogdetail";
import Career from "../Career/Career";
import Careerdetail from "../Career/Careerdetail";
import Contact from "../Contact/Contact";
import Faq from "../Faq/Faq";
import Home from "../Home/Home";
import Createnewpassword from "../Login/Createnewpassword";
import Enteremail from "../Login/Enteremail";
import Enterotp from "../Login/Enterotp";
import Login from "../Login/Login";
import Signin from "../Login/Signin";
import Cart from "../Product/Cart";
import Checkout from "../Product/Checkout";
import Customizeproduct from "../Product/Customizeproduct";
import Fashionproduct from "../Product/Fashionproduct";
import Productdetail from "../Product/Productdetail";
import Wishlist from "../Product/Wishlist";
import Changepassword from "../Profile/Changepassword";
import Editprofile from "../Profile/Editprofile";
import Myorders from "../Profile/Myorders";
import Newpassword from "../Profile/Newpassword";
import Profile from "../Profile/Profile";
import Recipedetail from "../Recipes/Recipedetail";
import Recipes from "../Recipes/Recipes";
import Sellerstore from "../Sellerstore/Sellerstore";
import Deliveryinformation from "../Terms/Deliveryinformation";
import Privacy from "../Terms/Privacy";
import Termscondition from "../Terms/Termscondition";
import SellerDashboard from "../Seller-Profile/SellerDashboard";
import SellerProfile from "../Seller-Profile/SellerProfile";
import Productdetail2 from "../Product/Productdetail2";
import Productdesgin from "../Product/Productdesgin";
import Sellereditprofie from "../Seller-Profile/Sellereditprofie";
import Sellerbuisnesseditprofile from "../Seller-Profile/Sellerbuisnesseditprofile";
import Sellerchangepassword from "../Seller-Profile/Sellerchangepassword";
import Sellerproductinventory from "../Seller-Profile/Sellerproductinventory";
import Sellerreview from "../Seller-Profile/Sellerreview";
import Paymentdetail from "../Seller-Profile/Paymentdetail";
import Membershipplan from "../Seller-Profile/Membershipplan";
import Sellercenter from "../Sellercenter/Sellercenter";
import Professionalplan from "../Sellercenter/Professionalplan";
import Payment from "../Sellercenter/Payment";
import Addingproduct from "../Seller-Profile/Addingproduct";
import Productcustomizedetail from "../Product/Productcustomizedetail";
import Getquote from "../Product/Getquote";
import Newfashioncollection from "../Product/Newfashioncollection";
import Customerorder from "../Seller-Profile/Customerorder";
import Artcraftproduct from "../Product/Artcraftproduct";
import Customizedesign from "../Product/Customizedesign";
import ScrollToTop from "../../Components/Scrolltotop";
import Sellerforget from "../Sellercenter/Sellerforget";
import Sellerotp from "../Sellercenter/Sellerotp";
import Sellercreatepassword from "../Sellercenter/Sellercreatepassword";

function Publicroutes() {
  return (
    <>
      <BrowserRouter>
      <ScrollToTop />
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/recipes" element={<Recipes />} />
          <Route path="/recipes-detail" element={<Recipedetail />} />
          <Route path="/career" element={<Career />} />
          <Route path="/career-detail" element={<Careerdetail />} />
          <Route path="/product-fashion" element={<Fashionproduct />} />
          <Route path="/art-craft-product" element={<Artcraftproduct />} />
          <Route path="/new-fashion-collection" element={<Newfashioncollection />} />
          <Route path="/customize-product" element={<Customizeproduct/>} />
          <Route path="/product-detail" element={<Productdetail />} />
          <Route path="/product-customize-detail" element={<Productcustomizedetail />} />
          <Route path="/product-detail-two" element={<Productdetail2 />} />
          <Route path="/design-product" element={<Productdesgin />} />
          <Route path="/seller-store" element={<Sellerstore />} />
          <Route path="/wish-list" element={<Wishlist />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/check-out" element={<Checkout />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/blog-detail" element={<Blogdetail />} />
          <Route path="/faq" element={<Faq/>} />
          <Route path="/terms-condition" element={<Termscondition/>} />
          <Route path="/privacy-policy" element={<Privacy/>} />
          <Route path="/delivery-information" element={<Deliveryinformation/>} />
          <Route path="/seller-center" element={<Sellercenter/>} />
          <Route path="/professianal-plan" element={<Professionalplan/>} />
          <Route path="/payment" element={<Payment/>} />
          <Route path="/get-quote" element={<Getquote/>} />
          <Route path="/customize-design" element={<Customizedesign/>} />
      


          {/* Login Routes */}
          <Route path="/sign-up" element={<Login />} />
          <Route path="/sign-in" element={<Signin />} />
          <Route path="/enter-email" element={<Enteremail />} />
          <Route path="/enter-otp" element={<Enterotp />} />
          <Route path="/create-new-password" element={<Createnewpassword />} />
          {/* =================== */}

          {/* Profile Routes */}
          <Route path="/my-profile" element={<Profile />} />
          <Route path="/edit-profile" element={<Editprofile />} />
          <Route path="/change-password" element={<Changepassword />} />
          <Route path="/new-password" element={<Newpassword />} />
          <Route path="/my-orders" element={<Myorders />} />
          {/* ==================== */}

          {/* Seller Profile starts here */}
          <Route path="/seller-dashboard" element={<SellerDashboard />} />
          <Route path="/seller-profile" element={<SellerProfile />} />
          <Route path="/seller-edit-profile" element={<Sellereditprofie />} />
          <Route path="/seller-edit-business-profile" element={<Sellerbuisnesseditprofile />} />
          <Route path="/seller-adding-product" element={<Addingproduct />} />
          <Route path="/seller-change-password" element={<Sellerchangepassword />} />
          <Route path="/seller-product-inventory" element={<Sellerproductinventory />} />
          <Route path="/seller-review" element={<Sellerreview />} />
          <Route path="/seller-payment-detail" element={<Paymentdetail />} />
          <Route path="/seller-membership-plan" element={<Membershipplan />} />
          <Route path="/seller-customer-order" element={<Customerorder />} />
          <Route path="/seller-forget-password" element={<Sellerforget />} />
          <Route path="/seller-otp" element={<Sellerotp />} />
          <Route path="/change-password-for-seller" element={<Sellercreatepassword/>} />
          {/* Seller Profile ends here */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Publicroutes;
