import React from "react";
import Footer from "../../Components/Footer";
import SellerHeader from "../../Components/SellerHeader";
import SellerSidebar from "../../Components/SellerSidebar";
import { key } from "../../Constant/Index";

function Sellerchangepassword() {
  return (
    <>
      <SellerHeader />
      {/* Seller profile starts here */}
      <section className="seller-dashboard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 ps-0">
              <SellerSidebar />
            </div>
            <div className="col-lg-8">
              <div className="edit-profile-card edit-no-padding-right">
                <div className="edit-heading-wrapper">
                  <h2>Change Password</h2>
                </div>
              

               <div className="seller-edit-with-border-dv">
               <div className="row">
                  <div className="col-md-10">
                    <div className="edit-details-form">
                      <div className="seller-all-form">
                        <form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="seller-edit-form-input">
                                <input
                                  type="password"
                                  placeholder="Enter Your Current Password"
                                />
                                <div className="seller-dashboard-icon">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="seller-key-img"
                                      src={key}
                                    />
                                  </figure>
                                </div>
                                <div className="eyes-icon">
                                  <i
                                    class="fa fa-eye-slash"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="seller-edit-form-input">
                                <input
                                  type="password"
                                  placeholder="Enter Your New Password"
                                />
                                <div className="seller-dashboard-icon">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="seller-key-img"
                                      src={key}
                                    />
                                  </figure>
                                </div>
                                <div className="eyes-icon">
                                  <i
                                    class="fa fa-eye-slash"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="seller-edit-form-input">
                                <input
                                  type="password"
                                  placeholder="Confirm New Password"
                                />
                                <div className="seller-dashboard-icon">
                                  <figure>
                                    <img
                                      className="img-fluid"
                                      alt="seller-key-img"
                                      src={key}
                                    />
                                  </figure>
                                </div>
                                <div className="eyes-icon">
                                  <i
                                    class="fa fa-eye-slash"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="seller-save-btn">
                      <button>Save</button>
                    </div>
                  </div>
                </div>
               </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Seller profile ends here */}
      <Footer />
    </>
  );
}

export default Sellerchangepassword;
