import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { attach } from "../../Constant/Index";
import "../../assets/css/quote.css";
import PageHeader from "../../Components/Pageheader";

function Getquote() {
	return (
		<>
			<Header />
            <PageHeader pagename="get a quote" />
			{/* Get A quuote Section */}
			<section className="get-quote-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="get-quaote-form">
								<form>
									<div className="row">
										<div className="col-md-12">
											<div className="get-quote-head">
												<h5>Personal Information</h5>
											</div>
										</div>
										<div className="col-md-6">
											<div className="get-quote-input">
												<input type="text" placeholder="First Name" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="get-quote-input">
												<input type="text" placeholder="Last Name" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="get-quote-input">
												<input type="text" placeholder="Email" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="get-quote-input">
												<input type="text" placeholder="Phone" />
											</div>
										</div>
										<div className="col-md-12">
											<div className="get-quote-head">
												<h5>Upload Images for Customization</h5>
											</div>
										</div>
										<div className="col-md-6">
											<div className="application-button-input get-quote-upload">
												<button>
													<span>
														Upload an image of the design.
														<img
															className="img-fluid"
															alt="attach-img"
															src={attach}
														/>
													</span>
													<input type="file" className="for-hide-quote-input" />
												</button>
											</div>
										</div>
										<div className="col-md-6">
											<div className="application-button-input get-quote-upload">
												<button>
													<span>
														Upload an image of the embroidery.
														<img
															className="img-fluid"
															alt="attach-img"
															src={attach}
														/>
													</span>
													<input type="file" className="for-hide-quote-input" />
												</button>
											</div>
										</div>
										<div className="col-md-12">
											<div className="get-quote-head">
												<h5>Provide measurements</h5>
											</div>
										</div>

										<div className="col-md-4">
											<div className="mcq-dv-input get-quote-mcq-dv-input">
												<label>Length</label>
												<input type="text" placeholder="00" max="2" />
											</div>
										</div>
                                        <div className="col-md-4">
											<div className="mcq-dv-input get-quote-mcq-dv-input">
												<label>Waist</label>
												<input type="text" placeholder="00" max="2" />
											</div>
										</div>
                                        <div className="col-md-4">
											<div className="mcq-dv-input get-quote-mcq-dv-input">
												<label>Hip</label>
												<input type="text" placeholder="00" max="2" />
											</div>
										</div>
                                        <div className="col-md-4">
											<div className="mcq-dv-input get-quote-mcq-dv-input">
												<label>Shoulder</label>
												<input type="text" placeholder="00" max="2" />
											</div>
										</div>
                                        <div className="col-md-4">
											<div className="mcq-dv-input get-quote-mcq-dv-input">
												<label>Sleeves</label>
												<input type="text" placeholder="00" max="2" />
											</div>
										</div>
                                        <div className="col-md-4">
											<div className="mcq-dv-input get-quote-mcq-dv-input">
												<label>Armhole</label>
												<input type="text" placeholder="00" max="2" />
											</div>
										</div>
                                        <div className="col-md-4">
											<div className="mcq-dv-input get-quote-mcq-dv-input">
												<label>Collar Size</label>
												<input type="text" placeholder="00" max="2" />
											</div>
										</div>
                                        <div className="col-md-4">
											<div className="mcq-dv-input get-quote-mcq-dv-input">
												<label>Cuff Size </label>
												<input type="text" placeholder="00" max="2" />
											</div>
										</div>

                                        <div className="col-md-12">
                                            <div className="get-quaote-button">
                                                <button>Submit</button>

                                            </div>

                                        </div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* ========================= */}
			<Footer />
		</>
	);
}

export default Getquote;
