import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import { zipicon } from "../../Constant/Index";
import "../../assets/css/profile.css";
import Siderbar from "./Siderbar";

function Profile() {
  return (
    <>
      <Header />
      <PageHeader pagename="my profile" />
      {/* My Profile Section */}
      <section className="my-profile-all">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <Siderbar />
            </div>
            <div className="col-md-10">
              <div className="show-profile">
                <div className="row">
                  <div className="col-md-10">
                    <div className="show-my-profile">
                      <span className="for-align">
                        <i class="fa fa-user" aria-hidden="true"></i>
                      </span>
                      <span>
                        {" "}
                        <p>John eloe</p>
                      </span>
                    </div>
                    <div className="show-my-profile">
                      <span className="for-align">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                      </span>
                      <span>
                        {" "}
                        <p>John Doe@mail.com</p>
                      </span>
                    </div>
                    <div className="show-my-profile">
                      <span className="for-align">
                        <i class="fa fa-phone" aria-hidden="true"></i>
                      </span>
                      <span>
                        <p>+1 234 567 8910</p>
                      </span>
                    </div>
                    <div className="show-my-profile">
                      <span className="for-align">
                        <i
                          class="fa fa-calendar-minus-o"
                          aria-hidden="true"
                        ></i>
                      </span>
                      <p>06-12-1986</p>
                    </div>
                    <div className="show-my-profile no-border-for-profile">
                      <span className="for-align">
                        <img className="img" alt="icon-img" src={zipicon} />
                      </span>
                      <span>
                        {" "}
                        <p>12245</p>
                      </span>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="profile-edit-btn">
                      <Link to="/edit-profile">Edit</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========================== */}
      <Footer />
    </>
  );
}

export default Profile;
