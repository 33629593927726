import React from 'react'
import Slider from 'react-slick';
import { fabric10, fabric11, fabric12, fabric13, fabric14, fabric15, fabric16 } from '../Constant/Index';

function Designslider() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 9,
        slidesToScroll: 1,
        arrows: true,
      };
  return (
    <>
       <div className="fabricc-all design-all ">
        <div className="row">
          <div className="col-md-2">
            <div className="small-slider">
              <h4>Design</h4>
            </div>
          </div>
          <div className="col-md-10">
            <Slider {...settings}>
              <div className="slide-small-images">
                <img className="img-fluid" src={fabric10} />
              </div>
              <div className="slide-small-images">
                <img className="img-fluid" src={fabric11} />
              </div>
              <div className="slide-small-images">
                <img className="img-fluid " src={fabric12} />
              </div>
              <div className="slide-small-images">
                <img className="img-fluid" src={fabric13} />
              </div>
              <div className="slide-small-images">
                <img className="img-fluid " src={fabric14} />
              </div>
              <div className="slide-small-images">
                <img className="img-fluid" src={fabric15} />
              </div>
              <div className="slide-small-images">
                <img className="img-fluid" src={fabric16} />
              </div>
              <div className="slide-small-images">
                <img className="img-fluid" src={fabric10} />
              </div>
              <div className="slide-small-images">
                <img className="img-fluid" src={fabric11} />
              </div>
              <div className="slide-small-images">
                <img className="img-fluid" src={fabric12} />
              </div>
              <div className="slide-small-images">
                <img className="img-fluid " src={fabric13} />
              </div>
              <div className="slide-small-images">
                <img className="img-fluid" src={fabric14} />
              </div>
              <div className="slide-small-images">
                <img className="img-fluid " src={fabric15} />
              </div>
              <div className="slide-small-images">
                <img className="img-fluid" src={fabric16} />
              </div>
              <div className="slide-small-images">
                <img className="img-fluid" src={fabric10} />
              </div>
              <div className="slide-small-images">
                <img className="img-fluid" src={fabric11} />
              </div>
            </Slider>
          </div>
        </div>
        {/* Fabirc Slider */}
      </div>
    </>
  )
}

export default Designslider
