import React from "react";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import { recipes, recipes2, recipes3, recipes4, recipes5 } from "../../Constant/Index";
import "../../assets/css/recipes.css";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";

function Recipes() {
  return (
    <>
      <Header />
      <PageHeader pagename="Recipes" />
      {/* Recipe Section */}
      <section className="recipe-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Link to="/recipes-detail">
                <div className="recipe-all">
                  <div className="recipe-img">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="recipe-img-all"
                        src={recipes}
                      />
                    </figure>
                  </div>
                  <div className="recipe-para">
                    <h4>Lorem Ipsum is simply dummy text of the</h4>
                    <div className="recipe-flex">
                      <p>Diallo Faraji</p>
                      <div className="for-views">
                        <h5>3.9M Views </h5>
                        <h5>1 Year ago </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-3">
              <Link to="/recipes-detail">
                <div className="recipe-all">
                  <div className="recipe-img">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="recipe-img-all"
                        src={recipes2}
                      />
                    </figure>
                  </div>
                  <div className="recipe-para">
                    <h4>Lorem Ipsum is simply dummy text of the</h4>
                    <div className="recipe-flex">
                      <p>Diallo Faraji</p>
                      <div className="for-views">
                        <h5>3.9M Views </h5>
                        <h5>1 Year ago </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-3">
              <Link to="/recipes-detail">
                <div className="recipe-all">
                  <div className="recipe-img">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="recipe-img-all"
                        src={recipes3}
                      />
                    </figure>
                  </div>
                  <div className="recipe-para">
                    <h4>Lorem Ipsum is simply dummy text of the</h4>
                    <div className="recipe-flex">
                      <p>Diallo Faraji</p>
                      <div className="for-views">
                        <h5>3.9M Views </h5>
                        <h5>1 Year ago </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-3">
              <Link to="/recipes-detail">
                <div className="recipe-all">
                  <div className="recipe-img">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="recipe-img-all"
                        src={recipes4}
                      />
                    </figure>
                  </div>
                  <div className="recipe-para">
                    <h4>Lorem Ipsum is simply dummy text of the</h4>
                    <div className="recipe-flex">
                      <p>Diallo Faraji</p>
                      <div className="for-views">
                        <h5>3.9M Views </h5>
                        <h5>1 Year ago </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-3">
              <Link to="/recipes-detail">
                <div className="recipe-all">
                  <div className="recipe-img">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="recipe-img-all"
                        src={recipes2}
                      />
                    </figure>
                  </div>
                  <div className="recipe-para">
                    <h4>Lorem Ipsum is simply dummy text of the</h4>
                    <div className="recipe-flex">
                      <p>Diallo Faraji</p>
                      <div className="for-views">
                        <h5>3.9M Views </h5>
                        <h5>1 Year ago </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-3">
              <Link to="/recipes-detail">
                <div className="recipe-all">
                  <div className="recipe-img">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="recipe-img-all"
                        src={recipes4}
                      />
                    </figure>
                  </div>
                  <div className="recipe-para">
                    <h4>Lorem Ipsum is simply dummy text of the</h4>
                    <div className="recipe-flex">
                      <p>Diallo Faraji</p>
                      <div className="for-views">
                        <h5>3.9M Views </h5>
                        <h5>1 Year ago </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-3">
              <Link to="/recipes-detail">
                <div className="recipe-all">
                  <div className="recipe-img">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="recipe-img-all"
                        src={recipes}
                      />
                    </figure>
                  </div>
                  <div className="recipe-para">
                    <h4>Lorem Ipsum is simply dummy text of the</h4>
                    <div className="recipe-flex">
                      <p>Diallo Faraji</p>
                      <div className="for-views">
                        <h5>3.9M Views </h5>
                        <h5>1 Year ago </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-3">
              <Link to="/recipes-detail">
                <div className="recipe-all">
                  <div className="recipe-img">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="recipe-img-all"
                        src={recipes2}
                      />
                    </figure>
                  </div>
                  <div className="recipe-para">
                    <h4>Lorem Ipsum is simply dummy text of the</h4>
                    <div className="recipe-flex">
                      <p>Diallo Faraji</p>
                      <div className="for-views">
                        <h5>3.9M Views </h5>
                        <h5>1 Year ago </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-3">
              <Link to="/recipes-detail">
                <div className="recipe-all">
                  <div className="recipe-img">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="recipe-img-all"
                        src={recipes5}
                      />
                    </figure>
                  </div>
                  <div className="recipe-para">
                    <h4>Lorem Ipsum is simply dummy text of the</h4>
                    <div className="recipe-flex">
                      <p>Diallo Faraji</p>
                      <div className="for-views">
                        <h5>3.9M Views </h5>
                        <h5>1 Year ago </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-3">
              <Link to="/recipes-detail">
                <div className="recipe-all">
                  <div className="recipe-img">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="recipe-img-all"
                        src={recipes2}
                      />
                    </figure>
                  </div>
                  <div className="recipe-para">
                    <h4>Lorem Ipsum is simply dummy text of the</h4>
                    <div className="recipe-flex">
                      <p>Diallo Faraji</p>
                      <div className="for-views">
                        <h5>3.9M Views </h5>
                        <h5>1 Year ago </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-3">
              <Link to="/recipes-detail">
                <div className="recipe-all">
                  <div className="recipe-img">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="recipe-img-all"
                        src={recipes4}
                      />
                    </figure>
                  </div>
                  <div className="recipe-para">
                    <h4>Lorem Ipsum is simply dummy text of the</h4>
                    <div className="recipe-flex">
                      <p>Diallo Faraji</p>
                      <div className="for-views">
                        <h5>3.9M Views </h5>
                        <h5>1 Year ago </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-3">
              <Link to="/recipes-detail">
                <div className="recipe-all">
                  <div className="recipe-img">
                    <figure>
                      <img
                        className="img-fluid"
                        alt="recipe-img-all"
                        src={recipes}
                      />
                    </figure>
                  </div>
                  <div className="recipe-para">
                    <h4>Lorem Ipsum is simply dummy text of the</h4>
                    <div className="recipe-flex">
                      <p>Diallo Faraji</p>
                      <div className="for-views">
                        <h5>3.9M Views </h5>
                        <h5>1 Year ago </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="pagination-all">
						<nav aria-label="Page navigation example">
							<ul class="pagination">
								<li class="page-item">
									<a class="page-link" href="#" aria-label="Previous">
										<span aria-hidden="true">&laquo;</span>
									</a>
								</li>
								<li class="page-item">
									<a class="page-link" href="#">
										1
									</a>
								</li>
								<li class="page-item">
									<a class="page-link" href="#">
										2
									</a>
								</li>
								<li class="page-item">
									<a class="page-link" href="#">
										3
									</a>
								</li>
								<li class="page-item">
									<a class="page-link" href="#" aria-label="Next">
										<span aria-hidden="true">&raquo;</span>
									</a>
								</li>
							</ul>
						</nav>
					</div>
      </section>
      {/* ============================= */}
      <Footer />
    </>
  );
}

export default Recipes;
