import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import { about1, sellercenterimg } from "../../Constant/Index";
import Membershipslider from "../../Components/Membershipslider";

function Sellercenter() {
  return (
    <>
      <Header />
      {/* About Section */}
      <PageHeader pagename="seller center" />
      <section className="about-sec seller-center-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="about-all">
                <h3>About Seller Center</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book
                </p>
                <p>
                  {" "}
                  It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.
                  It was popularised in the 1960s with the release of Letraset
                  sheets containing Lorem Ipsum passages,and more recently with
                  desktop publishing software like Aldus PageMaker including
                  versions of Lorem Ipsum.
                </p>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-img seller-img-postion-relative">
                <div className="about-btn">
                  <Link data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Seller Login
                  </Link>
                </div>
                <figure>
                  <img
                    className="img-fluid"
                    alt="about-img"
                    src={sellercenterimg}
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ====================== */}

      {/* Best Product Section */}
      <section className="best-product-sec">
        <div className="container">
          <div className="best-product-all">
            <div className="row">
              <div className="col-md-12">
                <div className="best-pro-all">
                  <h4>we help you find best Product</h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,
                  </p>
                  <p className="best-pro-all">
                    when an unknown printer took a galley of type and scrambled
                    it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic
                    typesetting, remaining essentially unchanged.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ====================================== */}

      {/* Member Ship Plan Section */}
      <section className="membership-plan-sec">
        <div className="container">
          <div className="professional-plan-heading">
            <h3>Membership Plan</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>
          <div className="row">
            {/* <div className="col-md-3">
              <div className="seller-center-all-membership-plan">
                <div className="seller-professional-plan">
                  <div className="seller-inner-profile-head">
                    <h3>Professional Plan</h3>
                    <div className="seller-head-profile">
                      <h5>limited sales</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod dolore magna aliqua.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="seller-who">
                    <h5>What you will get</h5>
                    <div className="seller-who-ul">
                      <ul>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat Nulla
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="seller-buy-now">
                    <Link to="/professianal-plan">
                      <button>Buy Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="seller-center-all-membership-plan">
                <div className="seller-professional-plan">
                  <div className="seller-inner-profile-head seller-different-color">
                    <h3>Basic Plan</h3>
                    <div className="seller-head-profile">
                      <h5>limited sales</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod dolore magna aliqua.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="seller-who">
                    <h5>What you will get</h5>
                    <div className="seller-who-ul">
                      <ul>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat Nulla
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="seller-buy-now seller-differt-color-btn">
                  <Link to="/professianal-plan">
                      <button>Buy Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="seller-center-all-membership-plan">
                <div className="seller-professional-plan">
                  <div className="seller-inner-profile-head">
                    <h3>upcoming Plan</h3>
                    <div className="seller-head-profile">
                      <h5>limited sales</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod dolore magna aliqua.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="seller-who">
                    <h5>What you will get</h5>
                    <div className="seller-who-ul">
                      <ul>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat Nulla
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="seller-buy-now">
                  <Link to="/professianal-plan">
                      <button>Buy Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="seller-center-all-membership-plan">
                <div className="seller-professional-plan">
                  <div className="seller-inner-profile-head seller-different-color">
                    <h3>upcoming Plan</h3>
                    <div className="seller-head-profile">
                      <h5>limited sales</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod dolore magna aliqua.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="seller-who">
                    <h5>What you will get</h5>
                    <div className="seller-who-ul">
                      <ul>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat Nulla
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Oluptate Velit Esse Cillum Dolore Eu Fugiat
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Reprehenderit In Voluptate Velit Esse Cillum Dolore
                          </span>
                        </li>
                        <li>
                          <span className="seller-who-icon seller-who-icon-two">
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <span>
                            {" "}
                            Duis Aute Irure Dolor In Reprehenderit In Voluptate
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="seller-buy-now seller-differt-color-btn">
                  <Link to="/professianal-plan">
                      <button>Buy Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}
            <Membershipslider />
          </div>
        </div>
      </section>
      <Footer />

      {/* Seller Sign in Modal Section */}

      <div className="seller-center-modal-for-signin">
        <div
          class="modal fade "
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog for-modal-in-middle">
            <div class="modal-content">
              <div class="modal-body ">
                <div className="seller-center-signin">
                  <div className="seller-signin-btn">
                    <h3>Seller Sign In</h3>
                  </div>
                  <div className="seller-sigbin-form-all">
                    <form action="/seller-dashboard">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="seller-signin-input">
                            <input type="text" placeholder="Email " />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="seller-signin-input">
                            <input type="password" placeholder="Password" />
                            <div className="password-icon seller-center-password-icon">
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="check-and-forget">
                            <div class="form-group">
                              <input type="checkbox" id="check-remeber" />
                              <label for="check-remeber">Remember Me</label>
                            </div>
                            <div className="modal-forget-link">
                              <Link to="/seller-forget-password">Forgot Password?</Link>
                            </div>
                          </div>
                          <div className="check-and-forget-btn">
                            
                              <button type="submit">Sign In</button>
                            
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ========================== */}
    </>
  );
}

export default Sellercenter;
