import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import PageHeader from "../../Components/Pageheader";
import Siderbar from "./Siderbar";

function Newpassword() {
  return (
    <>
    <Header />
    <PageHeader pagename="change password" />
      {/* New Password Section */}
      <section className="edit-profile-sec my-profile-all">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <Siderbar />
            </div>
            <div className="col-md-10">
              <div className="all-edit-screen">
                <div className="row">
                  <div className="col-md-10">
                    <form>
                      <div className="row">
                       
                        <div className="col-md-6">
                          <div className="edit-profile-input">
                            <input type="password" placeholder="Enter Your Current Password" />
                            <div className="icon-of-edit-profile">
                              <i class="fa fa-key" aria-hidden="true"></i>
                            </div>
                            <div className="icon-eye">
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 hide-on-responsive"></div>
                        <div className="col-md-6">
                          <div className="edit-profile-input">
                            <input type="password" placeholder="Enter Your New Password" />
                            <div className="icon-of-edit-profile">
                              <i class="fa fa-key" aria-hidden="true"></i>
                            </div>
                            <div className="icon-eye">
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 hide-on-responsive"></div>
                        <div className="col-md-6">
                          <div className="edit-profile-input">
                            <input type="password" placeholder="Confirm New Password" />
                            <div className="icon-of-edit-profile">
                              <i class="fa fa-key" aria-hidden="true"></i>
                            </div>
                            <div className="icon-eye">
                              <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-2">
                    <div className="profile-edit-btn">
                      <Link>Save</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======================== */}
      <Footer />
    </>
  );
}

export default Newpassword;
