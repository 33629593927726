import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Productslider from "../../Components/Productslider";
import Slide from "../../Components/Slide";
import { man1, man2 } from "../../Constant/Index";

function Productdetail() {
  const [counter, setCounter] = useState(0);
  const handleClick1 = () => {
    // Counter state is incremented
    setCounter(counter + 1);
  };
  const handleClick2 = () => {
    // Counter state is decremented
    setCounter(counter - 1);
  };
  return (
    <>
      <Header />
      {/* Product Detail Section */}
      <section className="product-detail-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6 only-for-this-product">
              <Slide />
            </div>
            <div className="col-md-6">
              <div className="custom-fashion-text">
                <h3>Custom fashion woolen double-breasted pea coat men</h3>
                <div className="name-stars">
                  <h5>
                    Seller Name: <span>African Fashion</span>
                  </h5>
                  <ul className="stars">
                    <li>
                      <Link>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </Link>
                    </li>
                  </ul>
                  <p>(5052 Customer Review)</p>
                  <div className="seller-anchor">
                    <Link to="/seller-store">Seller Store</Link>
                  </div>
                </div>
                <div className="seller">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley.
                  </p>
                  <div className="seller-price">
                    <h2>$180.00</h2>
                  </div>
                </div>
              </div>
              <div className="quantity">
                <div className="quantity-head">
                  <h5>Quantity</h5>
                </div>
                <div className="quantity-all-stuff">
                  <input value={counter} min="0" />
                  <button className="btn-qunatity-1" onClick={handleClick2}>
                    <i class="fa fa-angle-up" aria-hidden="true"></i>
                  </button>
                  <button className="btn-qunatity-2" onClick={handleClick1}>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="all-radio-btn">
                <div className="radio-name quantity-head">
                  <h5>Size</h5>
                </div>
                <div className="radio-btn">
                  <button>
                    <h5>s</h5>
                    <input type="radio" id="small" />
                  </button>
                </div>
                <div className="radio-btn">
                  <button>
                    <h5>m</h5>
                    <input type="radio" id="meduim" />
                  </button>
                </div>
                <div className="radio-btn">
                  <button>
                    <h5>l</h5>
                    <input type="radio" id="large" />
                  </button>
                </div>
                <div className="radio-btn">
                  <button>
                    <h5>xl</h5>
                    <input type="radio" id="x-large" />
                  </button>
                </div>
                <div className="radio-btn">
                  <button>
                    <h5>2xl</h5>
                    <input type="radio" id="double-large" />
                  </button>
                </div>
                <div className="radio-btn">
                  <button>
                    <h5>3xl</h5>
                    <input type="radio" id="three-large" />
                  </button>
                </div>
                <div className="size-chart-anchor">
                  <Link>Size Chart</Link>
                </div>
              </div>
              <div className="whishlist-and-cart-button">
                <div className="whishlist-cart">
                  <Link to="/wish-list">add to wishlist</Link>
                </div>
                <div className="whishlist-cart">
                  <Link to="/cart">add to cart</Link>
                </div>
              </div>

              <div className="category-text">
                <h5>
                  CATEGORIES: <span>Lorem Ipsum</span>
                </h5>
              </div>

              <div className="product-ul">
                <div className="product-head">
                  <h5>SHARE THIS PRODUCT:</h5>
                </div>
                <div className="product-ul">
                  <ul className="product-li">
                    <li>
                      <Link>
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i class="fa fa-youtube-play" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================= */}

      {/* Tabs section */}
      <section className="reviews-tab-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="tabs-alls">
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      DESCRIPTION
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      REVIEWS <span className="for-number">(4)</span>
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="description-para">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum.It is
                        a long established fact that a reader will be distracted
                        by the readable content of a page when looking at its
                        layout. The point of using Lorem Ipsum is that it has a
                        more-or-less normal distribution of letters, as opposed
                        to using 'Content here, content here', making it look
                        like readable English. Many desktop publishing packages
                        and
                      </p>
                      <p>
                        web page editors now use Lorem Ipsum as their default
                        model text, and a search for 'lorem ipsum' will uncover
                        many web sites still in their infancy. Various versions
                        have evolved over the years, sometimes by accident,
                        sometimes on purpose (injected humour and the like).It
                        is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has a more-or-less normal distribution of
                        letters, as opposed to using 'Content here, content
                        here', making it look like readable English. Many
                        desktop publishing packages and web page editors now use
                        Lorem Ipsum as their default model text, and a search
                        for 'lorem ipsum' will uncover many web sites still in
                        their infancy. Various versions have evolved over the
                        years, sometimes by accident, sometimes on purpose
                        (injected humour and the like).
                      </p>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="reviews-all">
                      {/* Reviews-Row   */}
                      <div className="row for-margin">
                        <div className="col-md-1">
                          <div className="review-img">
                            <img className="img-fluid" src={man1} />
                          </div>
                        </div>
                        <div className="col-md-11">
                          <div className="review-tab-all">
                            <div className="tab-text">
                              <h5>
                                Joeby Ragpa{" "}
                                <span> - 12 April, 2014 at 16:50</span>
                              </h5>
                              <p>
                                We possess within us two minds. So far I have
                                written only of the conscious mind. I would now
                                like to introduce you to your second mind, the
                                hidden and mysterious subconscious. Our
                                subconscious mind contains such power and
                                complexity that it literally staggers the
                                imagination.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* =========================== */}
                      {/* Reviews-Row   */}
                      <div className="row for-margin">
                        <div className="col-md-1">
                          <div className="review-img">
                            <img className="img-fluid" src={man2} />
                          </div>
                        </div>
                        <div className="col-md-11">
                          <div className="review-tab-all">
                            <div className="tab-text">
                              <h5>
                                Penta kentucky
                                <span> - 12 April, 2014 at 16:50</span>
                              </h5>
                              <p>
                                We possess within us two minds. So far I have
                                written only of the conscious mind. I would now
                                like to introduce you to your second mind, the
                                hidden and mysterious subconscious. Our
                                subconscious mind contains such power.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* =========================== */}
                      {/* Reviews-Row   */}
                      <div className="row for-margin">
                        <div className="col-md-1">
                          <div className="review-img">
                            <img className="img-fluid" src={man1} />
                          </div>
                        </div>
                        <div className="col-md-11">
                          <div className="review-tab-all">
                            <div className="tab-text">
                              <h5>
                                Joeby Ragpa{" "}
                                <span> - 12 April, 2014 at 16:50</span>
                              </h5>
                              <p>
                                We possess within us two minds. So far I have
                                written only of the conscious mind. I would now
                                like to introduce you to your second mind, the
                                hidden and mysterious subconscious. Our
                                subconscious mind contains such power and
                                complexity that it literally staggers the
                                imagination.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* =========================== */}
                      {/* Reviews-Row   */}
                      <div className="row for-margin">
                        <div className="col-md-1">
                          <div className="review-img">
                            <img className="img-fluid" src={man2} />
                          </div>
                        </div>
                        <div className="col-md-11">
                          <div className="review-tab-all">
                            <div className="tab-text">
                              <h5>
                                Penta kentucky
                                <span> - 12 April, 2014 at 16:50</span>
                              </h5>
                              <p>
                                We possess within us two minds. So far I have
                                written only of the conscious mind. I would now
                                like to introduce you to your second mind, the
                                hidden and mysterious subconscious. Our
                                subconscious mind contains such power.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ========================= */}
                      {/* Reviews-Row   */}
                      <div className="row">
                        <div className="col-md-1">
                          <div className="review-img">
                            <img className="img-fluid" src={man1} />
                          </div>
                        </div>
                        <div className="col-md-11">
                          <div className="review-tab-all">
                            <div className="tab-text">
                              <h5>
                                Joeby Ragpa{" "}
                                <span> - 12 April, 2014 at 16:50</span>
                              </h5>
                              <p>
                                We possess within us two minds. So far I have
                                written only of the conscious mind. I would now
                                like to introduce you to your second mind, the
                                hidden and mysterious subconscious. Our
                                subconscious mind contains such power and
                                complexity that it literally staggers the
                                imagination.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* =========================== */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =========================================================== */}

	  {/* Product Slider */}
	  <Productslider />
	  {/* ============ */}
      <Footer />
    </>
  );
}

export default Productdetail;
