import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { artcarftpic, newfashionbanner, product1 } from "../Constant/Index";

function Sliderforproduct() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <>
      <Slider {...settings}>
        <div className="slider-for-pro">
          <div className="all-banenr-back">
            <div className="row">
              <div className="col-md-5">
                <div className="banenr-text">
                  <h5>Organic & Fresh Food Collection</h5>
                  <h2>Smart People by smartly</h2>
                  <div className="discover-btn">
                    <Link>Discover Now</Link>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="banner-image">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="banner-img"
                      src={product1}
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slider-for-pro">
          <div className="all-banenr-back">
          <div className="row">
							<div className="col-md-5">
								<div className="banenr-text">
									<h5>New Fashion Collection</h5>
									<h2>Smart People by smartly</h2>
									<div className="discover-btn">
										<Link>Discover Now</Link>
									</div>
								</div>
							</div>
							<div className="col-md-7">
								<div className="banner-image">
									<figure>
										<img
											className="img-fluid"
											alt="banner-img"
											src={newfashionbanner}
										/>
									</figure>
								</div>
							</div>
						</div>
          </div>
        </div>
        <div className="slider-for-pro">
          <div className="all-banenr-back">
          <div className="row">
              <div className="col-md-5">
                <div className="banenr-text">
                  <h5>Arts & Crafts Collection</h5>
                  <h2>Smart People by smartly</h2>
                  <div className="discover-btn">
                    <Link>Discover Now</Link>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="banner-image art-craft-img">
                  <figure>
                    <img
                      className="img-fluid"
                      alt="banner-img"
                      src={artcarftpic}
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </Slider>
    </>
  );
}

export default Sliderforproduct;
